import styled from 'styled-components';

export const ProfilePicContainer=styled.div`
    border-radius:100%;
    border:2px solid #bf2100;
    width: 10em;
    height: 10em;
    padding: 1em 1.5em;
    background-color: #fff;
    cursor: pointer;
    /* margin:2em; */
    /* justify-content: center;
    align-items: center; */
`

export const ProfilePicImage=styled.img`
    border-radius:100%;
    border:2px solid #bfffff;
    width: 10em;
    height: 10em;
`