import React, { Component } from "react";
import { connect } from 'react-redux'
// import styled from 'styled-components'
// import "../index.css";
import Home from "./Home";
import PostAd from "./PostAd"
import { BrowserRouter as Router, Route, Routes, Link, Redirect } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar"
import NavLogo from './svgComponents/FlamilyLogo.jsx'
import {setLoggedInUser, updateUserData, userLogout} from '../actions'
// import Profile from "./Profile";
import { Layout } from "antd";
import BottomNavbar from "./BottomNavbar";
import AboutUs from "./AboutUs";
import PrivacyPolicy from './PrivacyPolicy'
import TermsAndCondition from './TermsAndCondition'
import ViewPost from "./ViewPost";
import Login from "./Login";
import SearchResult from "./SearchResult";
import Profile from "./Profile";
import PostAdForSelf from "./PostAdForSelf";
import '../sass/_global.scss'
import '../sass/_navbar.scss'
import ViewPostForFlatRequirement from "./ViewPostForFlatRequirement";
import ShowMore from "./ShowMore";
import NotFoundPage from "./NotFoundPage";
import { StyledNavLink } from "./styledComponents/homeStyledComponents";

const LazyPostAd = React.lazy(() => import('./PostAd'))
class NavBar extends Component {

  componentDidMount(){
    const {storeUserOnRelaod, getAds} = this.props
    storeUserOnRelaod(JSON.parse(sessionStorage.getItem("user")))
  }
  onClick = (e) => {
    const fieldId = e.target.id
    const { updateData } = this.props;
    switch (fieldId) {
      case 'loginListItem':
        updateData('loginForm_visible',true)  
        break;
      case 'registerListItem':
        updateData('registerForm_visible',true)
        break;

      default:
        break;
    }
  };

  logout = () => {
    var { userLogout }=this.props
    userLogout()
    sessionStorage.clear();
    window.location.href('/')
    // window.location.reload();
  };

  render() {
    // if(this.state.logged_out){
    //   return <Router><Redirect to="/login"></Redirect></Router>
    // }

    var { logged_user, logged_fullName } = this.props
    return (
      <div className='mainWrapper'>
      {/* <Layout> */}
        <Router>
        <Navbar collapseOnSelect sticky="top" expand="lg" bg="light" >

          <Navbar.Brand>
            <Link className="nav-link" to="/" style={{ width:'150px',height: '40px', padding:'0px'}}>  
            <NavLogo></NavLogo>            
            {/* <b>Flamily</b> */}
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            <ul className="navbar-nav ml-auto">
              {logged_user ? (
                <li id="WelcomeMsgListItem" className="nav-item">
                  <Link className="nav-link" >
                    <StyledNavLink to="/user-profile">
                    {logged_fullName}
                    </StyledNavLink>
                  </Link>
                </li>
              ) : null}

              {/* <li className="nav-item">
                  <Link className="nav-link" to="/allDocs">
                    All Docs Test{" "}
                  </Link>
                </li> */}

              {logged_user ?
                <li id="PostAdListItem" className="nav-item">
                  <Link className="nav-link" >
                    <StyledNavLink to="/postAd">
                      Post Your Ad
                      </StyledNavLink>
                  </Link>
                </li> : null}

              {!logged_user ? (
                <li id="loginListItem" className="nav-item">
                  <Link id="loginListItem" >
                  <StyledNavLink className="nav-link" to="/" id="loginListItem" onClick={this.onClick}>
                    {" "}
                      Login
                  </StyledNavLink>
                    </Link>
                </li>
              ) : null}

              {!logged_user ? (
                <li id="registerListItem" className="nav-item">
                  <Link id="registerListItem" >
                    <StyledNavLink className="nav-link" to="/" id="registerListItem" onClick={this.onClick}>
                    {" "}
                      Register
                    </StyledNavLink>
                    </Link>
                </li>
              ) : null}

              {logged_user ? (
                <li className="nav-item">
                  <Link className="nav-link" >
                    <StyledNavLink onClick={this.logout} to="/">
                    {" "}
                      Logout
                    </StyledNavLink>
                    </Link>
                </li>
              ) : null}
            </ul>
          </Navbar.Collapse>
        </Navbar>

        <React.Suspense fallback = "Loading" >
          <Routes>
            <Route exact path="/" element={ <Home/> }></Route>
            {/* <Route path="/search-result/:citySearch/:addressSearch" element={<SearchResult/>}></Route> */}
            <Route path="/search-result" element={<SearchResult/>}></Route>
            {/* <Route path="/postAd" element={<PostAd/>}></Route> */}
            <Route path="/postAd" element={<LazyPostAd/>}></Route>
            <Route path="/postad-for-self" element={<PostAdForSelf/>}></Route>
            <Route path="/viewPost/:flatId" element={<ViewPost/>}></Route>
            <Route path="/view-post-for-flat-requirement/:adId" element={<ViewPostForFlatRequirement/>}></Route>
            <Route path="/view-more/:adsFor" element={<ShowMore/>}></Route>
            <Route path="/user-profile" element={<Profile/>}></Route>
            <Route path="/about-us" element={<AboutUs/>}></Route>
            <Route path="/terms-and-condition" element={<TermsAndCondition/>}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
            <Route path='/*' element={<NotFoundPage/>}></Route>
          </Routes>
        </React.Suspense>
        
        <BottomNavbar />
        </Router>
      {/* </Layout> */}
     </div> 
    );
  }
}

function mapStateToProps(state) {
  const {userAuth}=state
  return userAuth
}

const mapDispatchToProps = dispatch => {
  return {
    updateData: (field,value) => dispatch(updateUserData(field,value)),
    storeUserOnRelaod: (storeDate) => dispatch(setLoggedInUser(storeDate)),
    userLogout: _ => dispatch(userLogout())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(NavBar);