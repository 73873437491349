import { Result } from "antd";
import React, { Component } from "react";
import { useState } from "react";
import { connect } from 'react-redux'
import { createPostForSelf } from "../actions";
import Profile from "./Profile";
import { StyledLink, StyledPrimaryActionButton } from "./styledComponents/homeStyledComponents";
import ErrorIcon from "./svgComponents/ErrorIcon";
import SuccessIcon from "./svgComponents/SuccessIcon";

function PostAdForSelf({createAdForSelf, userAuth, response}){
    const {logged_user} = userAuth
    const {status, data}= response ||{}

    const {profile} = logged_user

    const {id} = profile || {}

    const initialFormErrorMessages = {
        preferredAreaErrorMessages: "",
        preferredCityErrorMessages: "",
        rentBudgetErrorMessages: "",
        roomFurnishingErrorMessages: "",
        roomTypeErrorMessages: "",
        numberOfFlatmatesErrorMessages: "",
        internetConnectionErrorMessages: "",
        parkingErrorMessages: "",
        moveInDateErrorMessages: "",
        lengthOfStayErrorMessages: "",
    }

    const initialFormValid = {
        preferredAreaValid: false,
        preferredCityValid: false,
        rentBudgetValid: false,
        roomFurnishingValid: false,
        roomTypeValid: false,
        numberOfFlatmatesValid: false,
        internetConnectionValid: false,
        parkingValid: false,
        moveInDateValid: false,
        lengthOfStayValid: false,
    }

    const [formValid, setFormValid] = useState(initialFormValid)
    const [formErrorMessages, setFormErrorMessages] = useState(initialFormErrorMessages)
    const [adPosted, setAdPosted] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [preferredArea, setPreferredArea] = useState()
    const [preferredCity, setPreferredCity] = useState()
    const [rentBudget, setRentBudget] = useState()
    const [roomFurnishing, setRoomFurnishing] = useState()
    const [roomType, setRoomType] = useState()
    const [numberOfFlatmates, setNumberOfFlatmates] = useState()
    const [internetConnection, setInternetConnection] = useState()
    const [parking, setParking] = useState()
    const [moveInDate, setMoveInDate] = useState()
    const [lengthOfStay, setLengthOfStay] = useState()

    const handleChange = (event) => {
        const fieldName = event.target.name;
        const value = event.target.value;
        const errors = formErrorMessages;
        const valid = formValid;

        switch(fieldName){
            case 'preferredArea':
                setPreferredArea(value) 
                errors.preferredAreaErrorMessages = value === "" ? "This field is Required" : ""
                valid.preferredAreaValid = value === "" ? false : true
                break
            case 'preferredCity':
                setPreferredCity(value)
                errors.preferredCityErrorMessages = value === "" ? "This field is Required" : ""
                valid.preferredCityValid = value === "" ? false : true
                break
            case 'rentBudget':
                setRentBudget(value)
                errors.rentBudgetErrorMessages = value === "" ? "This field is Required" : ""
                valid.rentBudgetValid = value === "" ? false : true
                break
            case 'roomFurnishing':
                setRoomFurnishing(value)
                errors.roomFurnishingErrorMessages = value === "" ? "This field is Required" : ""
                valid.roomFurnishingValid = value === "" ? false : true
                break
            case 'roomType':
                setRoomType(value)
                errors.roomTypeErrorMessages = value === "" ? "This field is Required" : ""
                valid.roomTypeValid = value === "" ? false : true
                break
            case 'numberOfFlatmates':
                setNumberOfFlatmates(value)
                errors.numberOfFlatmatesErrorMessages = value === "" ? "This field is Required" : ""
                valid.numberOfFlatmatesValid = value === "" ? false : true
                break
            case 'internetConnection':
                setInternetConnection(value)
                errors.internetConnectionErrorMessages = value === "" ? "This field is Required" : ""
                valid.internetConnectionValid = value === "" ? false : true
                break
            case 'parking':
                setParking(value)
                errors.parkingErrorMessages = value === "" ? "This field is Required" : ""
                valid.parkingValid = value === "" ? false : true
                break
            case 'moveInDate':
                setMoveInDate(value)
                errors.moveInDateErrorMessages = value === "" ? "This field is Required" : ""
                valid.moveInDateValid = value === "" ? false : true
                break
            case 'lengthOfStay':
                setLengthOfStay(value)
                errors.lengthOfStayErrorMessages = value === "" ? "This field is Required" : ""
                valid.lengthOfStayValid = value === "" ? false : true
                break
            default:
                break
        }
        setFormValid(valid)
        setFormErrorMessages(errors)
        updateBtnState()
    }

    const updateBtnState = () => {
        const valid = formValid;
        const disabled = !( valid.preferredAreaValid &&
        valid.preferredCityValid &&
        valid.rentBudgetValid &&
        valid.roomFurnishingValid &&
        valid.roomTypeValid &&
        valid.numberOfFlatmatesValid &&
        valid.internetConnectionValid &&
        valid.parkingValid &&
        valid.moveInDateValid &&
        valid.lengthOfStayValid );

        setBtnDisabled(disabled)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const [min_rent, max_rent] = rentBudget.split('-')
        const dataToBePosted = {
            preferred_area: preferredArea,
            preferred_city: preferredCity,
            rentBudget: rentBudget,
            min_rent: parseInt(min_rent),
            max_rent: parseInt(max_rent),
            furnishing: roomFurnishing,
            roomType: roomType,
            maxNumberOfFlatmates: numberOfFlatmates,
            internet: internetConnection,
            parking: parking,
            moveInDate: moveInDate,
            preferredLengthOfStay: lengthOfStay,
            adPostedBy: id
        }
        createAdForSelf(dataToBePosted)
        setAdPosted(true)
    }

    if(adPosted && status===400){
        return (
            <>
            <div className="container" style={{background:'white'}}>
                <div className="col-12 col-md-12  tab-content" >
                    <Result
                        icon={<ErrorIcon/>}
                        title="Sorry error Occured for post creation!"
                        subTitle="Please Try in some time"
                        extra={[
                            <StyledLink type="primary" to="/">Go to Home Page</StyledLink>
                        ]}
                    />
                </div>
            </div>
            </>
        )
    } else if(adPosted && status===201){
        return (
            <>
            <div className="container" style={{background:'white'}}>
                <div className="col-12 col-md-12  tab-content" >
                    <Result
                        icon={<SuccessIcon/>}
                        title="Post created Successfully for your Flat!"
                        subTitle="You will get your Flamily member soon..."
                        extra={[
                        <StyledLink 
                            type="primary" 
                            key="console"
                            to="/"
                        >
                            Go to Home Page
                        </StyledLink>,
                        <StyledLink 
                            key="buy"
                            to={`/view-post-for-flat-requirement/${data.id}`}
                        >
                            View Your Post
                        </StyledLink>,
                        ]}
                    />
                </div>
            </div>
            </>
        )
    }
    return(
        <>
        <div className="container">
            <div className="row">
                <Profile fromPostAds/>
            </div>
            <div className="row">
                <div className="col-12 ">
                    <div className="jumbotron">
                        <div className="row mb-5 ml-0">
                            <h4>Property Preference:</h4>
                        </div>
                    <form className="form align" name="rentalDetails" >
                        <div className="row">
                            <div className="form-group col-sm-10 col-md-5">
                                <label>Preferred area:</label>
                                <input type="text" name="preferredArea" id="preferredArea" className="form-control"
                                placeholder="Enter area"
                                // value={numberToUpdate}
                                onChange={handleChange}
                                // onBlur={this.updateProgress}
                                >
                                </input>
                            </div>
                            <div className="form-group col-sm-10 col-md-5">
                                <label>Preferred City:</label>   
                                <input type="text" name="preferredCity" id="preferredCity" className="form-control"
                                placeholder="Enter city"
                                // value={numberToUpdate}
                                onChange={handleChange}
                                // onBlur={this.updateProgress}
                                >
                                </input>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-sm-10 col-md-5">
                                <label>Rent budget:</label>
                                <select className="form-control" name="rentBudget"
                                    // value={genderToUpdate} 
                                    onChange={handleChange}
                                    // onBlur={this.updateProgress}
                                    >
                                    <option value="">-Select Budget for Rent-</option>
                                    <option value="0-4999">Below 5000</option>
                                    <option value="5000-9999">5000-10000</option>
                                    <option value="10000-14999">10000-15000</option>
                                    <option value="15000-19000">15000-20000</option>
                                    <option value="20000-24999">20000-25000</option>
                                    <option value="25000-29999">25000-30000</option>
                                    <option value="30000-50000">More than 30000</option>
                                </select>
                            </div>
                            <div className="form-group col-sm-10 col-md-5">
                                <label>Room Furnishing:</label>
                                <select className="form-control" name="roomFurnishing"
                                    // value={genderToUpdate} 
                                    onChange={handleChange}
                                    // onBlur={this.updateProgress}
                                    >
                                    <option value="">-Select Furnishing-</option>
                                    <option value="required">Required</option>
                                    <option value="notRequired">Not Required</option>
                                    <option value="flexible">Flexible</option>
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-sm-10 col-md-5">
                                <label>Room type:</label>
                                <select className="form-control" name="roomType"
                                    // value={genderToUpdate} 
                                    onChange={handleChange}
                                    // onBlur={this.updateProgress}
                                    >
                                    <option value="">-Select room type-</option>
                                    <option value="separate">Separate</option>
                                    <option value="common">Common</option>
                                    <option value="flexible">Flexible</option>
                                </select>
                            </div>
                            <div className="form-group col-sm-10 col-md-5">
                                <label>Number of flatmates:</label>
                                <select className="form-control" name="numberOfFlatmates"
                                    // value={genderToUpdate} 
                                    onChange={handleChange}
                                    // onBlur={this.updateProgress}
                                    >
                                    <option value="">-Select max Number flatmates-</option>
                                    <option value="maxOneOther">Max 1 Other</option>
                                    <option value="maxTwoOther">Max 2 Other</option>
                                    <option value="maxThreeOther">Max 3 Other</option>
                                    <option value="flexible">Flexible</option>
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-sm-10 col-md-5">
                                <label>Internet Connection:</label>
                                <select className="form-control" name="internetConnection"
                                    // value={genderToUpdate} 
                                    onChange={handleChange}
                                    // onBlur={this.updateProgress}
                                    >
                                    <option value="">-Select Internet Connection-</option>
                                    <option value="required">Required</option>
                                    <option value="notRequired">Not Required</option>
                                    <option value="flexible">Flexible</option>
                                </select>
                            </div>
                            <div className="form-group col-sm-10 col-md-5">
                                <label>Parking:</label>
                                <select className="form-control" name="parking"
                                    // value={genderToUpdate} 
                                    onChange={handleChange}
                                    // onBlur={this.updateProgress}
                                    >
                                    <option value="">-Select Parking-</option>
                                    <option value="requiredForCar">Required for Car</option>
                                    <option value="requiredForBike">Required for Bike</option>
                                    <option value="requiredForBoth">Required for Both</option>
                                    <option value="notRequired">Not Required</option>
                                    <option value="flexible">Flexible</option>
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-sm-10 col-md-5">
                                <label>Move in Date:</label>
                                <input 
                                type="date" 
                                className="form-control" 
                                name="moveInDate"
                                onChange={handleChange} />
                                {/* </input> */}
                            </div>
                            <div className="form-group col-sm-10 col-md-5">
                                <label>Preferred length of stay:</label>
                                <select className="form-control" name="lengthOfStay"
                                    // value={genderToUpdate} 
                                    onChange={handleChange}
                                    // onBlur={this.updateProgress}
                                    >
                                    <option value="">-Select Furnishing-</option>
                                    <option value="-3">less than 3 months</option>
                                    <option value="3-6">3 to 6 months</option>
                                    <option value="6-12">6 to 12 month</option>
                                    <option value="12+">More than 12 months</option>
                                </select>
                            </div>
                        </div>

                        <div className="row my-5">
                        <div className="form-group col-sm-10 col-md-5">
                            <StyledPrimaryActionButton primary name="register" type="submit"
                            disabled={btnDisabled}
                            onClick = {handleSubmit}
                            >
                                Create Post
                            </StyledPrimaryActionButton>
                        </div>
                    </div>

                    </form>
                    </div>
                    
                </div>
            </div>
        </div>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        createAdForSelf:_=>dispatch(createPostForSelf(_))
    }
}

const mapStateToProps=state=>{
    const {userAuth, createPost:{response}}=state
    return {userAuth, response}
} 

export default connect(mapStateToProps, mapDispatchToProps)(PostAdForSelf);