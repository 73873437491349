import {takeLatest} from 'redux-saga/effects'
import { GET_ALL_ADS, GET_CITIES, CREATE_POST, GET_AD_BY_ID, LOG_IN, REGISTER, SEARCH_FLAT, VERIFY_OTP, UPDATE_PROFILE, CREATE_POST_FOR_SELF, GET_AD_FOR_FLAT_REQUIREMENT_BY_ID, GET_ALL_ADS_FOR_FLAT_REQUIREMENT, SOCIAL_LOGIN, SEARCH_FLATMATE, DELETE_FLAT_POST, GET_USER_PROFILE, DELETE_FLATMATE_POST } from '../actions'
import { handleGetAllAds, handleGetCities, handleCreatePost, handleGetAdById, handleLogin, handleRegister, handleFlatSearch, handleCheckOtp, handleUserProfileUpdate, handleCreatePostForSelf, handleGetAdForFlatRequirementById, handleGetAllFlatRequirementsAds, handleSocialLogin, handleFlatmateSearch, handleDeleteFlatPost, handleGetUserProfile, handleDeleteFlatmatePost } from '../apiCalls/apiCalls'

export function* watcherSaga(){
    yield takeLatest(GET_CITIES,handleGetCities)

    yield takeLatest(GET_ALL_ADS,handleGetAllAds)

    yield takeLatest(GET_AD_BY_ID,handleGetAdById)

    yield takeLatest(GET_AD_FOR_FLAT_REQUIREMENT_BY_ID, handleGetAdForFlatRequirementById)

    yield takeLatest(GET_ALL_ADS_FOR_FLAT_REQUIREMENT, handleGetAllFlatRequirementsAds)

    yield takeLatest(CREATE_POST, handleCreatePost)

    yield takeLatest(CREATE_POST_FOR_SELF, handleCreatePostForSelf)

    yield takeLatest(LOG_IN, handleLogin)

    yield takeLatest(SOCIAL_LOGIN, handleSocialLogin)

    yield takeLatest(REGISTER, handleRegister)

    yield takeLatest(VERIFY_OTP, handleCheckOtp)

    yield takeLatest(SEARCH_FLAT, handleFlatSearch)

    yield takeLatest(SEARCH_FLATMATE, handleFlatmateSearch)

    yield takeLatest(UPDATE_PROFILE, handleUserProfileUpdate)

    yield takeLatest(DELETE_FLAT_POST, handleDeleteFlatPost)

    yield takeLatest(DELETE_FLATMATE_POST, handleDeleteFlatmatePost)

    yield takeLatest(GET_USER_PROFILE, handleGetUserProfile)
}