import userAuth from './userAuthReducer'
import flatSearch from './flatSearchReducer'
import createPost from './adsReducer'
import paginationReducer from './paginationReducer'
import loader from './loader'
import {combineReducers} from 'redux'

export default combineReducers({
  userAuth,
  flatSearch,
  createPost,
  paginationReducer,
  loader
})




