const filterByData = [
    {
        id: 'rent',
        name: 'Rent Budget',
        isCustom: true,
        filterValue: 20000,
        isDefault: true,
        values: [],
        min: 0,
        max: 20000,
        options: {
            0: '₹ 0/mo',
            5000: '₹ 5000/mo',
            10000: '₹ 10000/mo',
            15000: '₹ 15000/mo',
            20000: '₹ 20000/mo',
        },
        filterFor: 'flats',
        isActive: false,
    },
    {
        id: 'numberOfPeople',
        name: 'Number of Flatmates',
        values: [
            {
                value: '1',
                displayName: 'Max 1 Other',
                isChecked: false
            },
            {
                value: '2',
                displayName: 'Max 2 Other',
                isChecked: false
            },
            {
                value: '3',
                displayName: 'Max 3 Other',
                isChecked: false
            },
            {
                value: '4',
                displayName: 'Max 4 Other',
                isChecked: false
            }
        ],
        isActive: false,
    },
    {
        id: 'gender',
        name: 'Gender',
        values: [
            {
                value: 'male',
                displayName: 'Male',
                isChecked: false
            },
            {
                value: 'female',
                displayName: 'Female',
                isChecked: false
            }
        ],
        isActive: false,
    },
    {
        id: 'roomType',
        name: 'Room Type',
        values: [
            {
                value: 'single',
                displayName: 'Single',
                isChecked: false
            },
            {
                value: 'shared',
                displayName: 'Shared',
                isChecked: false
            }
        ],
        isActive: false,
    },
    {
        id: 'furnishing',
        name: 'Furnishing',
        values: [
            {
                value: 'fullFurnished',
                displayName: 'Full Furnished',
                isChecked: false
            },
            {
                value: 'semiFurnished',
                displayName: 'Semi Furnished',
                isChecked: false
            },
            {
                value: 'UnFurnished',
                displayName: 'UnFurnished',
                isChecked: false
            }
        ],
        isActive: false,
    },
    {
        id: 'parking',
        name: 'Parking',
        values: [
            {
                value: 'bike',
                displayName: 'Required for Bike',
                isChecked: false
            },
            {
                value: 'car',
                displayName: 'Required for Car',
                isChecked: false
            },
            {
                value: 'both',
                displayName: 'Required for both',
                isChecked: false
            }
        ],
        isActive: false,
    },
    {
        id: 'amenities',
        name: 'Amenities',
        values: [
            {
                value: 'lift',
                displayName: 'Lift',
                isChecked: false
            },
            {
                value: 'ac',
                displayName: 'AC',
                isChecked: false
            },
            {
                value: 'wifi',
                displayName: 'Wi-Fi',
                isChecked: false
            },
            {
                value: 'intercom',
                displayName: 'Intercom',
                isChecked: false
            },
            {
                value: 'geyser',
                displayName: 'Geyser',
                isChecked: false
            },
            {
                value: 'maid',
                displayName: 'Maid',
                isChecked: false
            },
            {
                value: 'cook',
                displayName: 'Cook',
                isChecked: false
            }
        ],
        isActive: false,
    },
];

export default filterByData;