import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { domain } from "../BackendUrl";
import '../styles/viewPost.css'
import { useParams } from 'react-router-dom';
import { getAdForFlatRequirementById } from '../actions';
import '../sass/_viewPost.scss'
import { StyledImage } from './styledComponents/viewPostForFlatRequirementStyledComponent';
import {  
  valuesForRentBudget, 
  valuesForFoodPreference, 
  valuesForNumberOfFlatmates, 
  commonValues, 
  valuesForParking, 
  valuesForContractPeriod, 
  formatMoveInDate 
  } from './SelfAdsConstant'
import ViewPostSkeleton from './ViewPostSkeleton';
import NotFoundPage from './NotFoundPage';

function ViewPostForFlatRequirement(){

  // this.props.match.params.id
    const {adId} = useParams()
    // const postData = {}
    const dispatch = useDispatch()  
    useEffect(() => {
      dispatch(getAdForFlatRequirementById(adId))
    }, [])

    const {postData = {}, response = {}} = useSelector(state => {
      return state.createPost
    })

    const {isLoading = false} = useSelector(state => {
      return state.loader
    })
  
    const {
        adPostedBy = {},
        furnishing,
        id: selfAdId,
        internet,
        maxNumberOfFlatmates,
        moveInDate,
        parking,
        preferredLengthOfStay,
        preferred_area,
        preferred_city,
        rentBudget,
        roomType,
    } = postData

    const {
        aboutMe,
        age,
        behaviouralRating,
        drinks,
        foodType,
        gender,
        havePets,
        id: adPostedById,
        image,
        mobileNumber,
        nativePlace,
        nativeState,
        nativeStateIsoCode,
        smokes,
        totalRating,
        user ={},
        userReview,
        worksAt,
    } = adPostedBy

    const {
        email,
        first_name,
        last_name,
        profile,
        username,
    } = user

    if(isLoading) {
      return <ViewPostSkeleton />
    }

    if(response && response.status === 'Error') {
      return <NotFoundPage/>
    }

    return (
        <>
          <div className="container-fluid" style={{ background: 'white', padding:'10px'}}>
            <div className='row'>
              <div className='col-12 d-flex align-items-center'>
                <StyledImage src={`${domain}${image}`}/>
              </div>
              {/* <Divider/>               */}
              {/* <Divider orientation="left" orientationMargin="0"><h5></h5></Divider> */}
            </div>
            <div className='my-4'>
              <div className='row m-2'>
                  <div className='col-12'>
                    <h2>{`${first_name} ${last_name}`}</h2>
                    <h4>{`${username}`}</h4>
                  </div>
              </div> 
              <hr/>
              
              <div className='row m-2'>
                <div className='p-2 m-3 border rounded float-left'>
                  <div className='d-flex flex-row justify-content-center'>
                    Rent Budget
                      {/* {maintainance>0?maintainance:'Maintenance Incld.'} */}
                  </div>
                  <div className='d-flex flex-row justify-content-center f18'>
                    <span className='b'>
                      {`${valuesForRentBudget[rentBudget]}`}
                    </span>
                  </div>
                </div>
              </div>
              <hr/>

              <div className='container m-2 p-2'>
                <div className='row p-2 f20 b'>
                  Personal Details:
                </div>
                <div className='container f16 mb-4'>
                  <div className='row'>
                    General Info:
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      <div className='row d-flex align-items-center'>
                          <div className='col-5'>Age</div>
                          <div className='col-1'>:</div>
                          <div className='col-5'>{age}</div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='row d-flex align-items-center'>
                          <div className='col-5'>Gender</div>
                          <div className='col-1'>:</div>
                          <div className='col-5'>{gender==='M' ? 'Male' : 'Female'}</div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      <div className='row d-flex align-items-center'>
                          <div className='col-5'>Contact Number</div>
                          <div className='col-1'>:</div>
                          <div className='col-5'>{mobileNumber}</div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      <div className='row d-flex align-items-center'>
                          <div className='col-5'>Native Place</div>
                          <div className='col-1'>:</div>
                          <div className='col-5'>{nativePlace}</div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='row d-flex align-items-center'>
                          <div className='col-5'>Native State</div>
                          <div className='col-1'>:</div>
                          <div className='col-5'>{nativeState}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className='container f16'>
                  <div className='row'>
                    My Features:
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      <div className='row'>
                          <div className='col-5'>Food preference</div>
                          <div className='col-1'>:</div>
                          <div className='col-5'>{valuesForFoodPreference[foodType]}</div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>I am</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{worksAt}</div>
                        </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Drinks</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{drinks ? 'Yes' : 'No'}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Smokes</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{smokes ? 'Yes' : 'No'}</div>
                        </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Pets</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{havePets ? 'Yes' : 'No'}</div>
                        </div>
                    </div>
                  </div>
                  
                </div>
                <hr/>
                <div className='container f16'>
                  <div className='row'>
                    About me:
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      {aboutMe}
                    </div>
                  </div>                 
                </div>
              </div>
              <hr />

              <div className='container m-2 p-2'>
                <div className='row p-2 f20 b'>
                  Property preference:
                </div>

                <div className='container f16'>
                  <div className='row'>
                    Prefered Location:
                  </div>
                  
                  <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Prefered Area</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{preferred_area}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Prefered City</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{preferred_city}</div>
                        </div>
                    </div>
                  </div>
                </div>
                <hr/>

                <div className='container f16'>
                  <div className='row'>
                    Budget:
                  </div>
                  
                  <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Budget for Rent</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{valuesForRentBudget[rentBudget]}</div>
                        </div>
                    </div>
                  </div>
                </div>
                <hr/>

                <div className='container f16'>
                  <div className='row'>
                    Flat:
                  </div>
                  
                  <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Prefered Room</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{roomType}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Max Number of Flatmates</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{valuesForNumberOfFlatmates[maxNumberOfFlatmates]}</div>
                        </div>
                    </div>
                  </div>
                </div>
                <hr/>

                <div className='container f16'>
                  <div className='row'>
                    Amenities:
                  </div>

                  <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Furnishing</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{commonValues[furnishing]}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Internet Connection</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{commonValues[internet]}</div>
                        </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Parking</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{valuesForParking[parking]}</div>
                        </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Move in Date</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{formatMoveInDate(moveInDate)}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='row'>
                            <div className='col-5'>Prefered length of stay</div>
                            <div className='col-1'>:</div>
                            <div className='col-5'>{valuesForContractPeriod[preferredLengthOfStay]}</div>
                        </div>
                    </div>
                  </div>

                </div>
              
              </div>

            </div>
            
          </div>
        </>
    )
}

// const mapStateToProps = state => {
//     const {createPost}=state
//     return createPost
//   }
  
// const mapDispatchToProps = dispatch => {
//   return {
//       getAds:_=>dispatch(getAdById()),
//       setAdToView:(postData)=>dispatch(setPost(postData))
//   }
// }

export default ViewPostForFlatRequirement;