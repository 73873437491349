import { Skeleton } from "antd"

const ViewPostSkeleton = () => {
    return (
        <div className="container-fluid" style={{ background: 'white', padding:'10px'}}>
            <br/>
            <div className="container-fluid" style={{ padding:'10px'}}>
                <br/>
                <Skeleton.Image active={true} className="adsImg w-100"/>
            </div>
            <div className='container-fluid my-4'>
                <Skeleton active />
                <hr/>
                <br/>
                <Skeleton 
                title={false}
                    paragraph={{
                        rows: 6,
                        }}
                    active />
            </div>
        </div>
    )
}

export default ViewPostSkeleton;