const FurnitureIcon = ({height, width, fill}) => {
    return (
        <svg 
        id="Layer_1" 
        dataName="Layer 1" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 122.88 108.34"
        height={height ? height : '1em'}
        width={width ? width : '1em'}
        fill={fill ? fill : "#bf2100"}
        >
            <path d="M67.94,103.65v4.69H65v-4.69H17.7v4.68H14.81v-4.68H13.14a5.92,5.92,0,0,1-4-1.19,5.35,5.35,0,0,1-1.68-4.18V77.17a12.82,12.82,0,0,1-3.43-1.48A7.6,7.6,0,0,1,1.1,72.42c-2.06-4.55-1-8.37,1.42-11a10.85,10.85,0,0,1,4.07-2.64,11.43,11.43,0,0,1,4.8-.7,9.92,9.92,0,0,1,3.5.92V44.5c0-6,.9-9.12,3.2-10.86s5.52-1.78,10.44-1.78H50V3.64A3.65,3.65,0,0,1,53.63,0h65.61a3.65,3.65,0,0,1,3.64,3.64v94h-2.77v4.95H96.46V97.65H75.27v.58h0v0a5.32,5.32,0,0,1-1.68,4.18,6,6,0,0,1-4,1.19H67.94ZM96.43,46.87a1.6,1.6,0,1,1,3.19,0v8.37a1.6,1.6,0,0,1-3.19,0V46.87ZM88,3.19V94.46h31.66V3.64a.44.44,0,0,0-.45-.45ZM84.84,94.46V3.19H53.63a.44.44,0,0,0-.31.13.42.42,0,0,0-.14.32V31.86H54.3c5.35,0,8.61.21,10.75,2.12s2.77,5.2,2.77,11.28V59.6a10.78,10.78,0,0,1,4.7-1,11.77,11.77,0,0,1,4.53.94,10.21,10.21,0,0,1,3.71,2.66c2.2,2.51,3,6.11.86,10.29a8.26,8.26,0,0,1-2.92,3.17,13.18,13.18,0,0,1-3.43,1.52V94.46ZM17.79,61.23a10.21,10.21,0,0,1,2.08,3.85,11.76,11.76,0,0,1,.47,2.43,15.42,15.42,0,0,1,0,2.72v3.86H62.4V70.17a17,17,0,0,1,0-2.65A8.4,8.4,0,0,1,62.92,65a10.43,10.43,0,0,1,2-3.23V45.26c0-5.19-.41-7.89-1.8-9.13S59,34.75,54.3,34.75H28.53c-4.34,0-7.18,0-8.7,1.2s-2,3.58-2,8.55V61.23ZM20.34,77v4.86H62.4V77Zm-4.79-13.9a1.44,1.44,0,0,1-.42-.43,6.67,6.67,0,0,0-4-1.65,8.41,8.41,0,0,0-3.58.52,7.92,7.92,0,0,0-3,1.93c-1.69,1.78-2.39,4.48-.89,7.76a4.76,4.76,0,0,0,1.87,2,11.39,11.39,0,0,0,3.64,1.39h0a1.45,1.45,0,0,1,1.12,1.41V98.21a2.63,2.63,0,0,0,.66,2.05,3.3,3.3,0,0,0,2.12.51h4.31V70a11.4,11.4,0,0,0,0-2.26,9.07,9.07,0,0,0-.35-1.85,7.35,7.35,0,0,0-1.57-2.83Zm49.75,12a1.48,1.48,0,0,1,0,.9v7a1.66,1.66,0,0,1,0,.32,1.84,1.84,0,0,1,0,.33v17.13h4.3a3.41,3.41,0,0,0,2.13-.52,2.7,2.7,0,0,0,.66-2h0V76.05a1.44,1.44,0,0,1,1.24-1.43,11,11,0,0,0,3.5-1.39A5.31,5.31,0,0,0,79,71.17c1.51-3,1-5.41-.45-7.09a7.44,7.44,0,0,0-2.68-1.89,8.87,8.87,0,0,0-3.41-.72A7.13,7.13,0,0,0,65.59,66a5.87,5.87,0,0,0-.35,1.68A13.93,13.93,0,0,0,65.29,70v.13h0v4.93Zm-45,25.67H62.4v-16H20.34v16Z"/>
        </svg>
    )
}

export default FurnitureIcon;