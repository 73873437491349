const filtersForFlatmate = [
    {
        id: 'rent',
        name: 'Rent',
        isCustom: true,
        filterValue: 30000,
        isDefault: true,
        values: [],
        min: 0,
        max: 30000,
        options: {
            0: '₹ 0/mo',
            5000: '₹ 5000/mo',
            10000: '₹ 10000/mo',
            15000: '₹ 15000/mo',
            20000: '₹ 20000/mo',
            25000: '₹ 25000/mo',
            30000: '₹ 30000/mo'
        },
        filterFor: 'flats',
        isActive: false,
    },
    {
        id: 'gender',
        name: 'Gender',
        values: [
            {
                value: 'M',
                displayName: 'Male',
                isChecked: false
            },
            {
                value: 'F',
                displayName: 'Female',
                isChecked: false
            }
        ],
        isActive: false,
    },
    {
        id: 'roomType',
        name: 'Room Available',
        values: [
            {
                value: 'separate',
                displayName: 'Single',
                isChecked: false
            },
            {
                value: 'common',
                displayName: 'Shared',
                isChecked: false
            }
        ],
        isActive: false,
    },
    {
        id: 'foodType',
        name: 'Food Preference',
        values: [
            {
                value: 'vegetarian',
                displayName: 'Vegetarian',
                isChecked: false
            },
            {
                value: 'nonVegetarian',
                displayName: 'Non Vegetarian',
                isChecked: false
            }
        ],
        isActive: false,
    }
];

export default filtersForFlatmate;