import { SET_CITIES, GET_CITIES, GET_ALL_ADS, SET_ALL_ADS, SET_FLAT_SEARCH_RESULT, SET_ALL_ADS_FOR_FLAT_REQUIREMENT, SET_FLATMATE_SEARCH_RESULT} from "../actions";

const flatData = {
    cities:[],
    ads:[],
    flatSearchResult:[],
    adsForFlatRequirement: [],
    flatmateSearchResult: []
  };

export default function flatSearch(state = flatData, action) {
    var {type}=action
    
    switch (type) {
        case GET_CITIES:
            return {...state}

        case SET_CITIES:
            const {cities}=action
            return {...state, cities}

        case GET_ALL_ADS:
            return {...state}

        case SET_ALL_ADS:
            const {ads} = action
            return {...state,ads}

        case SET_ALL_ADS_FOR_FLAT_REQUIREMENT:
            const {adsForFlatRequirement} = action
            return {...state, adsForFlatRequirement}

        case SET_FLAT_SEARCH_RESULT:
            const {flatSearchResult}=action
            return {...state, flatSearchResult}
        
            case SET_FLATMATE_SEARCH_RESULT:
            const {flatmateSearchResult}=action
            return {...state, flatmateSearchResult}
        
        default:
            return {...state,}
    }
}