import { StyledButton, StyledImageContainer, StyledRatingContainer, StyledVerifiedContainer } from "./styledComponents/homeStyledComponents";
import VerifiedIcon from '../components/svgComponents/VerifiedIcon';
import { Rate, Skeleton } from 'antd';
import { domain } from "../BackendUrl";
import Slider from "react-slick";
import ArrowPrevious from "./svgComponents/ArrowPrevious";
import ArrowNext from "./svgComponents/ArrowNext";
import noImage from '../assets/noImage.jpg'
import BathroomIcon from "./svgComponents/BathroomIcon";
import BedroomIcon from "./svgComponents/BedroomIcon";
import FlatmateIcon from "./svgComponents/FlatmateIcon";
import TrashIcon from "./svgComponents/TrashIcon";

const sliderSettings = {
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true
};
const loadImageCarousel = (card,index) => {
    const images=[]
    const cardId=index;
    for(let i=1; i<=10;i++){
        if(card[`flatImage${i}`]){
            images.push(card[`flatImage${i}`])
        }
        
    }
    const rating=card.flatRating
    if(images.length>0){
        return (
            <StyledImageContainer  className="card-img-top" id={index}>
            <Slider {...sliderSettings}>
                    {
                        images.map((item, index) => {
                            const imgUrl = `${domain}${item}`
                            return (
                              // style={{margin:'1.5em', width:'500px',  height: '400px', padding:'1px'}}
                                <div key={`${imgUrl}img${index}`} >
                                    <img id={cardId} className="img-fluid d-block w-100 adsImg" src={imgUrl} alt="..."/>
                                </div>  
                            )
                        })
                    }
            </Slider>
            <StyledVerifiedContainer>
                <VerifiedIcon />
            </StyledVerifiedContainer>
            <StyledRatingContainer>
                <Rate disabled allowHalf value={rating} style={{fontSize:"1em", color:'#ffe817'}}></Rate>
            </StyledRatingContainer> 
            </StyledImageContainer>
        )
    }
    return (
            <img id={cardId} src={noImage} className="card-img-top adsImg w-100" alt="..."/> 
    );
    
}

const loadTitle = (card)=>{
    return (
        <>
            <strong>{`₹${card.rent}/ month`}</strong>
        </>
        )
}

const loadAvailableFromDate = (fromDate) => {
    const months = ["January", "February", "March", "April", "May", "June",
     "July", "August", "September", "October", "November", "December"];
    const currentDate = new Date();
    const availableFrom = new Date(fromDate)
    if(currentDate >= availableFrom){
        return `Availble now`
    }
    const month = months[availableFrom.getMonth()]
    const date= availableFrom.getDate()
    const year = availableFrom.getFullYear()
    return `Available from ${date} ${month} ${year}`
}

const loadDiscription=(card)=>{
    return(
        <>
            <div className="m-1">
                {card.address_area ? card.address_area : "Area Not available"}, { } 
                <strong>{card.address_city ? card.address_city : "City not available"}</strong>
            </div>
            <div className="m-1 d-flex flex-row">
                <div className="w-50 d-flex flex-row flex-wrap">
                    <div>
                    <BedroomIcon height='1.5em' width='1.5em'/> {card.bhk} &nbsp;
                    </div>
                    <div>
                    <BathroomIcon height='1.5em' width='1.5em'/> {card.noOfBathrooms} &nbsp;
                    </div>
                    <div >
                    <FlatmateIcon height='1.5em' width='1.5em'/> {card.numberOfPeople}
                    </div>
                </div>
                <div className="w-50">&#9642;{` ${card.noOfPeopleRequired} flatmate required`}</div>
            </div>
            <div className="m-1 mt-3 font-weight-light text-secondary">{loadAvailableFromDate(card.availableFrom)}</div>
        </>
    )
}

export const loadAllAdsCard = (card, index, wrapperClass, profileId, deleteFlatById) => {        
    return (
        <div className={wrapperClass} key={`card-${index}`} id={card.id}>
            <div className="shadow card mb-5 mx-0 adsCard w-100">
                <a href={`/viewPost/${card.id}`}
                    className="cardLink clickable"
                    key={index} 
                    id={card.id} >
                    {loadImageCarousel(card,card.id)}
                    <div className="card-body" key={`card-body${index}`} id={card.id}>
                        <h5 className="card-title" key={`card-title${index}`} id={card.id}>
                            {loadTitle(card)}
                        </h5>
                        <p className="card-text" key={`card-text${index}`} id={card.id}>
                            {loadDiscription(card)}
                        </p>
                    </div>
                </a>
                {deleteFlatById &&
                <div class="row mx-3 mb-3 justify-content-end">
                    <div className="clickable" onClick={(e) => deleteFlatById(card.id, profileId, 'flatAds')}>
                        <TrashIcon height="1.5em" width="1.5em"/>
                    </div>
                </div>}
            </div>
        </div>
    )
}
