import React from 'react'

function AboutUs(){
    return(
        <div className="site-layout-background" style={{ padding: 30, minHeight: 360 }}>
        <div name="About Us">
            <h2 style={{textAlign:'center'}}>Welcome to Flamily</h2>
            <div>
                <p style={{ fontSize: '1.15rem' }}>
                &nbsp; &nbsp;Our home-solutions firm Flamily was founded in Pune in Dec 2020. 
                    We had experienced a common problem - finding roommates in the big city. The youth 
                    has to face different types of discrimination. Young people faced difficulty in 
                    finding flatmates. Most of the time bachelors can't find flatmates once one of them
                     has left. Migrants from other places are viewed with suspicion as they appear as 
                     ‘foreigners’ in a new city. Furthermore, since most newcomers do not have access to 
                     local insights into the new city, there is no knowledge of how to get around.
                     </p>
                <p style={{ fontSize: '1.15rem' }}>
                &nbsp; &nbsp;We conclude that if one of them left or moved to another place then now they need only
                 one flatmate. Those looking for a flat needed a place they could call home, a community
                  where they would be accepted and a platform which allows them to connect with other people.
                </p>
                    <p style={{ fontSize: '1.15rem' }}>
                    &nbsp; &nbsp;Flamily.co.in is India’s No.1 share accommodation website. Based in Pune,
                     India, Flamily.co.in allows people to list their spare rooms,
                      find accommodation or team up with others to start a share house.</p>
            </div>
        </div>
        </div>
    )
}

export default AboutUs;