import React from 'react'

function ArrowNext(){
    return (
        // <svg 
        // version="1.1" 
        // id="Layer_1" 
        // xmlns="http://www.w3.org/2000/svg" 
        // xmlnsXlink="http://www.w3.org/1999/xlink" 
        // x="0px" y="0px" 
        // viewBox="0 0 122.88 122.88"
        // xmlSpace="preserve"
        // height='50px'
        // width="50px"
        // fill="#bf2100">
        //     <g>
        //         <path d="M37.95,4.66C45.19,1.66,53.13,0,61.44,0c16.96,0,32.33,6.88,43.44,18c5.66,5.66,10.22,12.43,13.34,19.95 c3,7.24,4.66,15.18,4.66,23.49c0,16.96-6.88,32.33-18,43.44c-5.66,5.66-12.43,10.22-19.95,13.34c-7.24,3-15.18,4.66-23.49,4.66 c-8.31,0-16.25-1.66-23.49-4.66c-7.53-3.12-14.29-7.68-19.95-13.34C12.34,99.22,7.77,92.46,4.66,84.93C1.66,77.69,0,69.75,0,61.44 c0-8.31,1.66-16.25,4.66-23.49C7.77,30.42,12.34,23.66,18,18C23.65,12.34,30.42,7.77,37.95,4.66L37.95,4.66z M43.11,67.76 c-3.54-0.03-6.38-2.92-6.35-6.46c0.03-3.54,2.92-6.38,6.46-6.35l21.63,0.13l-7.82-7.95c-2.48-2.52-2.45-6.58,0.07-9.05 c2.52-2.48,6.57-2.45,9.05,0.08l18.67,18.97c2.45,2.5,2.45,6.49,0,8.98L66.52,84.72c-2.48,2.52-6.53,2.55-9.05,0.08 c-2.52-2.48-2.55-6.53-0.08-9.05l7.73-7.85L43.11,67.76L43.11,67.76z M42.86,16.55c-5.93,2.46-11.28,6.07-15.76,10.55 c-4.48,4.48-8.09,9.83-10.55,15.76c-2.37,5.71-3.67,11.99-3.67,18.58c0,6.59,1.31,12.86,3.67,18.58 c2.46,5.93,6.07,11.28,10.55,15.76c4.48,4.48,9.83,8.09,15.76,10.55c5.72,2.37,11.99,3.67,18.58,3.67c6.59,0,12.86-1.31,18.58-3.67 c5.93-2.46,11.28-6.07,15.76-10.55c4.48-4.48,8.09-9.82,10.55-15.76c2.37-5.71,3.67-11.99,3.67-18.58c0-6.59-1.31-12.86-3.67-18.58 c-2.46-5.93-6.07-11.28-10.55-15.76c-4.48-4.48-9.83-8.09-15.76-10.55c-5.71-2.37-11.99-3.67-18.58-3.67S48.58,14.19,42.86,16.55 L42.86,16.55z"/>
        //     </g>
        // </svg>
        // <svg 
        // id="Layer_1" 
        // dataname="Layer 1" 
        // xmlns="http://www.w3.org/2000/svg" 
        // viewBox="0 0 122.88 108.06"
        // height='25px'
        // width="25px"
        // fill="#bf2100">
        //     <title>previous-arrow</title>
        //     <path d="M58.94,24.28a14.27,14.27,0,0,1,20.35-20l39.49,40.16a14.28,14.28,0,0,1,0,20L80.09,103.79a14.27,14.27,0,1,1-20.35-20L74.82,68.41l-60.67-.29a14.27,14.27,0,0,1,.24-28.54l59.85.28L58.94,24.28Z"/>
        // </svg>


        <svg 
            width="30" 
            height="12" 
            viewBox="0 0 30 12" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M0.000162125 4.5 H23.16 V0 L29.33 6 L23.16 12 V7.5 H0.000162125Z"
            // d="M29.3335 6 L23.16 0 V4.5 H0.162125 V7.5 H23.16 V12 L29.3335 6Z"
            fill="#bf2100"/>
        </svg>

    )
}

export default ArrowNext;