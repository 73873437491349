import { Layout } from "antd";
import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import remove from 'lodash/remove';
import { useParams, useSearchParams } from "react-router-dom";
import { searchFlat, searchFlatmate, setFlatmateSearchResult, setFlatSearchResult, setLimit, setOffset, setSelfAdLimit, setSelfAdOffset } from "../actions";
import AllAds from "./AllAds";
import SelfAds from './SelfAds';
import FilterBy from "./FilterBy";
import filterByData from "./FilterByData";
import filtersForFlatmate from "./FiltersForFlatmate";
import SearchBar from "./SearchBar";
import EmptyResultResponse from "./EmptyResultResponse";
import InfiniteScroll from "react-infinite-scroll-component";
import CardsSkeleton from "./CardsSkeleton";

const SearchResult = ({
    flatSearch, 
    loadSearchedFlats, 
    loadSearchedFlatmates,
    setInitialLimit,
    setInitialOffset,
    paginationReducer,
    resetSearchedFlats,
    resetSearchedFlatmates,
    setInitialSelfAdLimit,
    setInitialSelfAdOffset
}) =>{
    const dispatch = useDispatch()
    const {countForFlatAd} = paginationReducer
    let [searchParams, setSearchParams] = useSearchParams()
    const searchUrl = searchParams.get("search")
    const citySearch = searchParams.get("city")
    const addressSearch = searchParams.get("area")
    const rent = searchParams.get("rent")
    const numberOfPeople = searchParams.get("numberOfPeople")
    const gender = searchParams.get("gender")
    const roomType = searchParams.get("roomType")
    const furnishing = searchParams.get("furnishing")
    const parking = searchParams.get("parking")
    const amenities = searchParams.get("amenities")

    // const rentBudget = searchParams.get("rentBudget")
    const foodType = searchParams.get("foodType")
    
    const [filterData, setFilterData] = useState([]);
    const [filtersToDisplay, setFiltersToDisplay] = useState([]);
    const [pageToView, setPageToView] = useState(searchUrl);

    const [tabChanged, setTabChanged] = useState(false);
    const [openedFilter, setOpenedFilter] = useState('');
    // const [initialLoad, setInitialLoad] = useState(true)
    const [adsToDisplay, setAdsToDisplay] = useState([])

    const resetAdsToDisplay = () => {
        setAdsToDisplay([])
        switch (pageToView) {
            case 'flats':
                // Initializing adsToDisplay to [], limit and offset to 0 and 12 resp
                resetSearchedFlats()
                setInitialLimit()
                setInitialOffset()
                break;
            case 'flatmates':
                resetSearchedFlatmates()
                // setInitialSelfAdLimit()
                // setInitialSelfAdOffset()
                setInitialLimit()
                setInitialOffset()
                break;
            default:
                break;
        }
        // if (initialLoad) {
        //     setInitialLoad(false)
        // } else {
        //     setAdsToDisplay([])
        // }
    }

    useEffect(() => {
        if (pageToView==='flats') {
            const flatData={
                citySearch,
                addressSearch,
                rent,
                numberOfPeople,
                gender,
                roomType,
                furnishing,
                parking,
                amenities
            }
            resetAdsToDisplay()
            setFilterData(filterByData)
            dispatch(loadSearchedFlats(flatData))
        } else if (pageToView==='flatmates') {
            const flatmateData={
                citySearch,
                addressSearch,
                rent,
                gender,
                roomType,
                foodType
            }
            resetAdsToDisplay()
            setFilterData(filtersForFlatmate)
            dispatch(loadSearchedFlatmates(flatmateData))
        }
    }, [])

    useEffect(() => {
        switch(pageToView){
            case 'flats':
                setAdsToDisplay([...adsToDisplay, ...flatSearch.flatSearchResult])
                break;
            case 'flatmates':
                setAdsToDisplay([...adsToDisplay, ...flatSearch.flatmateSearchResult])
                break;
            default:
                break;
        }
    }, [flatSearch.flatSearchResult, flatSearch.flatmateSearchResult]);

    const setCityAddressFilter = () => {
    const cityAndAddressFilter = {
        id: 'cityAndAddressFilter',
        value: `${citySearch} | ${addressSearch}`
    };
    const filters = []
    if (filters.length === 0) {
        filters.push(cityAndAddressFilter)
    } else {
        filters.forEach((filter) => {
            if (filter.id === 'cityAndAddressFilter') {
                filter.value = `${citySearch} | ${addressSearch}`
            }
        })
    }
    setFiltersToDisplay(filters)
    }

    useEffect(() => {
        resetAdsToDisplay()
        setCityAddressFilter()
    }, [citySearch, addressSearch])

    
    useEffect(() => {
        if (pageToView==='flats') {
            const flatData={
                citySearch,
                addressSearch,
                rent,
                numberOfPeople,
                gender,
                roomType,
                furnishing,
                parking,
                amenities
            }
            dispatch(loadSearchedFlats(flatData))
        } else if (pageToView==='flatmates') {
            const flatmateData={
                citySearch,
                addressSearch,
                rent,
                gender,
                roomType,
                foodType
            }
            dispatch(loadSearchedFlatmates(flatmateData))
        }
        // dispatch(loadSearchedFlats(flatData))
    }, [searchParams])

    useEffect(() => {
        if(!tabChanged) {
            filterData.forEach((filter) => {
                const filtersToApply = [];
                if(filter.id === 'rent' && !filter.isDefault){
                    filtersToApply.push(filter.filterValue)
                }
                filter.values.forEach((value) => {
                    if(value.isChecked) {
                        filtersToApply.push(value.value)
                    }
                })
                if(filtersToApply.length !== 0) {
                    searchParams.set(filter.id, filtersToApply)
                } else {
                    searchParams.delete(filter.id)
                }
            })
            setSearchParams(searchParams)
        } else if(tabChanged) {
            setTabChanged(false)
        }
    }, [filterData])

    const onFilterChange = (event) => {
        const [filterId, filterValue] = event.target.id.split('_');
        const isChecked = event.target.checked;
        const filterObj = [...filterData]
        const modifiedFilters = [...filtersToDisplay]
        filterObj.forEach((filter) => {
            if(filter.id === filterId){
                filter.values.forEach((value) => {
                    if(value.value === filterValue && isChecked) {
                        value.isChecked = true
                        // modifiedFilters.push(value.displayName)
                        modifiedFilters.push({id: event.target.id, value: value.displayName})
                    } else if(value.value === filterValue && !isChecked){
                        value.isChecked = false
                        remove(modifiedFilters, (item)=>item.value===value.displayName)
                    }
                })
            }
        })
        resetAdsToDisplay()
        setFiltersToDisplay(modifiedFilters)
        setFilterData(filterObj)
    }

    const sliderAfterChange = (value) => {
        const filterObj = [...filterData]
        const modifiedFilters = [...filtersToDisplay]
        filterObj.forEach((filter) => {
            if(filter.id === 'rent'){
                filter.filterValue = value
                filter.isDefault = false
                remove(modifiedFilters, (item)=>item.id==='rent')
                modifiedFilters.push({id: 'rent', value: `₹0 - ₹${value}`})
            }
        })
        resetAdsToDisplay()
        setFiltersToDisplay(modifiedFilters)
        setFilterData(filterObj)
    }

    const onFilterSelected = (event) => {
        event?.preventDefault();
        // Adding selected filter to open
        if(openedFilter===event.target.id) {
            setOpenedFilter('')
        } else {
            setOpenedFilter(event.target.id)
        }
    }

    const onSelectedFilterRemove = (event) => {
        const fullId = event.target.id
        const [filterId, filterValue] = event.target.id.split('_');
        const modifiedFilters = [...filtersToDisplay]
        const filterObj = [...filterData]
        remove(modifiedFilters, (item)=>item.id === fullId)
        
        filterObj.forEach((filter) => {
            if(filterId !== 'rent' && filter.id === filterId) {
                filter.values.forEach((value) => {
                    if(filterValue && value.value === filterValue) {
                        value.isChecked = false;
                    }
                })
            }
            if(filterId === 'rent' && filter.id === filterId) {
                filter.filterValue = 20000;
                filter.isDefault = true;
            }
        })
        resetAdsToDisplay()
        setFiltersToDisplay(modifiedFilters)
        setFilterData(filterObj)
    }

    const onPageToViewChange = (page) => {
        resetAdsToDisplay()
        setPageToView(page)
        if (page==='flats') {
            searchParams.delete("rent")
            searchParams.delete("gender")
            searchParams.delete("roomType")
            searchParams.delete("foodType")
            setFilterData(filterByData)
        } else if (page==='flatmates') {
            searchParams.delete("rent")
            searchParams.delete("numberOfPeople")
            searchParams.delete("gender")
            searchParams.delete("roomType")
            searchParams.delete("furnishing")
            searchParams.delete("parking")
            searchParams.delete("amenities")
            setFilterData(filtersForFlatmate)
        }
        setFiltersToDisplay([])
        setCityAddressFilter()
        searchParams.set('search', page)
        setTabChanged(true)
        setSearchParams(searchParams)
    }

    const loadMoreData = () => {
        switch(pageToView){
            case 'flats':
                const flatData={
                    citySearch,
                    addressSearch,
                    rent,
                    numberOfPeople,
                    gender,
                    roomType,
                    furnishing,
                    parking,
                    amenities
                }
                setFilterData(filterByData)
                loadSearchedFlats(flatData)
                break;
            case 'flatmates':
                const flatmateData={
                    citySearch,
                    addressSearch,
                    rent,
                    gender,
                    roomType,
                    foodType
                }
                setFilterData(filtersForFlatmate)
                dispatch(loadSearchedFlatmates(flatmateData))
                break;
            default:
                break;
        }
    }

    return (
        <>
            <InfiniteScroll
                dataLength={adsToDisplay.length}
                next={loadMoreData}
                hasMore={adsToDisplay.length < countForFlatAd}
                loader={<CardsSkeleton/>}
                endMessage={ adsToDisplay.length > 0 &&
                    <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            >
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-8">
                            <SearchBar spanFull></SearchBar>
                        </div>
                    </div>

                    <FilterBy 
                        filterByData={filterData} 
                        onFilterSelected={onFilterSelected}
                        onFilterChange={onFilterChange}
                        sliderAfterChange={sliderAfterChange}
                        filtersToDisplay={filtersToDisplay}
                        onSelectedFilterRemove={onSelectedFilterRemove}
                        pageToView={pageToView}
                        onPageToViewChange={onPageToViewChange}
                        openedFilter={openedFilter}
                    />

                    <div className="row">
                        {pageToView === 'flats' ?
                            <AllAds dataToView={adsToDisplay} />
                            :
                            <SelfAds dataToView={adsToDisplay} />
                        }
                    </div>
                </div>
            </InfiniteScroll>
        </>
        
    )
}
function mapStateToProps(state) {
    const {flatSearch, paginationReducer}=state
    return {flatSearch, paginationReducer}
}
const mapDispatchToProps = dispatch => {
    return {
        loadSearchedFlats: (flatData) =>dispatch(searchFlat(flatData)),
        resetSearchedFlats: _ => dispatch(setFlatSearchResult([])),
        setInitialLimit: _ => dispatch(setLimit(12)),
        setInitialOffset: _ => dispatch(setOffset(0)),
        loadSearchedFlatmates: (flatmateData) =>dispatch(searchFlatmate(flatmateData)),
        resetSearchedFlatmates: _ => dispatch(setFlatmateSearchResult([])),
        setInitialSelfAdLimit: _ => dispatch(setSelfAdLimit(12)),
        setInitialSelfAdOffset: _ => dispatch(setSelfAdOffset(0)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchResult)
