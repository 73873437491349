
// export const bac+54ewA kendUrlPackages=`http://localhost:4000/Wanderlust_Server/PackageInfoAPI`;
// export const bookingUrl=`http://localhost:4000/Wanderlust_Server/BookingAPI/getBooking/`;
export const domain = process.env.REACT_APP_HOST
// export const domain = "http://yashkulkarni.pythonanywhere.com"
export const loginUrl = `${domain}/account/login/`;
export const socialLoginUrl = `${domain}/account/login/using-`
export const getUsersUrl=`${domain}/account/getUser/`
export const getProfileUrl=`${domain}/account/getProfile/`
export const getReviewsUrl=`${domain}/account/getReviews/`
export const createAccountUrl=`${domain}/account/createAccount/`
export const updateProfileUrl=`${domain}/account/updateProfile/`
export const authenticateUrl=`${domain}/account/authenticate/`
export const verifyOtpUrl=`${domain}/account/verifyotp/`
export const logoutUrl=`${domain}/account/logout/`
export const getUserByIdUrl=`${domain}/account/getUserById/`
export const getUserProfile=`${domain}/account/getUserProfile/`

export const postAdUrl=`${domain}/findflatmate/postad/`;
export const postAdForSelfUrl=`${domain}/findflatmate/postAdForSelf/`;
export const uploadImageForAd=`${domain}/findflatmate/imageUpload/`;
export const searchFlatsUrl=`${domain}/findflatmate/searchflats/`
export const searchFlatmatesUrl=`${domain}/findflatmate/searchflatmates/`
export const getAllFlatsUrl=`${domain}/findflatmate/getflats/`;
export const getFlatByIdUrl=`${domain}/findflatmate/getflat/`;
export const getFlatRequirementByIdUrl=`${domain}/findflatmate/getflatRequirement/`;
export const getAllFlatRequirementsUrl=`${domain}/findflatmate/getflatRequirements/`;
export const getImagesUrl=`${domain}/findflatmate/getImages/`
export const getAllCitiesUrl=`${domain}/findflatmate/getCities/`

export const deleteFlatByIdUrl=`${domain}/findflatmate/deleteflatpost/`
export const deleteFlatmateByIdUrl=`${domain}/findflatmate/deleteflatrequirementpost/`
