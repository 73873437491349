import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Navbar from './components/Navbar';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
/* REDUX related imports*/
// import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
// import createSagaMiddleware from 'redux-saga';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import rootReducer from './reducers'
// import { watcherSaga } from './sagas/saga';
// const sagaMiddleware=createSagaMiddleware();
// const store = createStore(rootReducer,composeWithDevTools( applyMiddleware(sagaMiddleware) ))
// sagaMiddleware.run(watcherSaga)
import store from './store'
ReactDOM.render(
  <Provider store={store}>
	  <Navbar/>
  </Provider>,
document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
