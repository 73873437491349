import React from 'react'

function CameraIcon(props){
    return(
        <svg id="5" 
        dataname="Layer 1" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 122.88 90.78"
        width='2.5em'
        height='2.5em'>
            {/* <defs><style>.cls-1{fillRule:'evenodd'}</style></defs> */}
            <title>black camera</title>
            <path fillRule='evenodd' fill={props.fillState} id="5" d="M46.86.05H90.48l10,17.7H120.9a2,2,0,0,1,2,2V88.8a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V19.73a2,2,0,0,1,2-2h9.21V11.4H22.56v6.35H34.92L42.63,2.53c1.56-3.1.71-2.48,4.23-2.48ZM66.64,31.7A19.55,19.55,0,0,1,80.47,65.08l-.23.21A19.55,19.55,0,1,1,66.64,31.7ZM75.4,42.49a12.38,12.38,0,1,0-.16,17.7L75.4,60a12.38,12.38,0,0,0,0-17.52Zm34.67-16a5.9,5.9,0,1,1-5.9,5.9,5.9,5.9,0,0,1,5.9-5.9ZM66.65,21.72A29.53,29.53,0,1,1,37.12,51.25,29.53,29.53,0,0,1,66.65,21.72Z"/>
        </svg>
    )
}

export default CameraIcon;