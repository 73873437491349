import React, { Component } from "react";
import '../sass/_loader.scss'

const Loader = () => {
    return (
        // <div class="loader"></div>
        <div class="overlay">
            <div class="overlay__inner">
                <div class="overlay__content">
                    <span class="spinner"></span>
                </div>
            </div>
        </div>
    )
}

export default Loader;