export const getAllFlatsMockResonce = ([
    {
        "bhk": 1,
        "noOfBathrooms": 1,
        "rent": 1,
        "deposit": 1,
        "maintainance": 1,
        "noOfPeopleRequired": 1,
        "flatRating": 1,
        "description": "Need a friendly flatmate",
        "address": {
            "id": 1,
            "pincode": "424101",
            "flatNoAndbuildingName": "Smruti",
            "area": "Kargaon road, near A.B. Highschool",
            "city": "Chalisgaon",
            "state": "MAHARASHTRA",
            "landMark": "Opposite Bachapan School"
        },
        "flatmate": [
            {
                "id": 1,
                "age": 23,
                "mobileNumber": "9876543210",
                "gender": "m",
                "nativeState": "mh",
                "nativePlace": "jl",
                "worksAt": "in",
                "drinks": false,
                "foodType": "both",
                "behaviouralRating": 1,
                "totalRating": 1,
                "image": null,
                "userReview": null,
                "flat": 1
            }
        ],
        "flatImages": [
            {
                "id": 1,
                "image": "/media/flatImages/flats_VD9I1hb.jpg"
            },
            {
                "id": 2,
                "image": "/media/flatImages/newFlats_lOxqffT.jpg"
            }
        ]
    },
    {
        "bhk": 2,
        "noOfBathrooms": 1,
        "rent": 12500,
        "deposit": 12500,
        "maintainance": 2000,
        "noOfPeopleRequired": 1,
        "flatRating": 5,
        "description": "Require a friendly person, in 2 bhk fully furnished flat",
        "address": {
            "id": 2,
            "pincode": "411034",
            "flatNoAndbuildingName": "Park Avenue",
            "area": "Wakad",
            "city": "Pune",
            "state": "MAHARASHTRA",
            "landMark": "Near Datta Mandir, Bhumakar Bridge"
        },
        "flatmate": [],
        "flatImages": [
            {
                "id": 3,
                "image": "/media/flatImages/apartment.jpg"
            },
            {
                "id": 4,
                "image": "/media/flatImages/flats.jpg"
            },
            {
                "id": 5,
                "image": "/media/flatImages/newFlats.jpg"
            }
        ]
    },
    {
        "bhk": 3,
        "noOfBathrooms": 3,
        "rent": 20000,
        "deposit": 20000,
        "maintainance": 0,
        "noOfPeopleRequired": 2,
        "flatRating": 4,
        "description": "Required a friendly flat mate for this awesome 3 BHK flat with awesome people",
        "address": {
            "id": 3,
            "pincode": "411034",
            "flatNoAndbuildingName": "Royal Pass",
            "area": "Magarpatta City, Hadapsar",
            "city": "Pune",
            "state": "MAHARASHTRA",
            "landMark": "near amanora mall"
        },
        "flatmate": [],
        "flatImages": [
            {
                "id": 6,
                "image": "/media/flatImages/newFlats_Jdcrrcm.jpg"
            },
            {
                "id": 7,
                "image": "/media/flatImages/apartment_3Bwg7Tn.jpg"
            }
        ]
    }
])