export const getAllFlatRequiremntMockResponse = ([
    {
        adPostedBy: {
            aboutMe: "I am a very calm person looking for peaceful flat mates",
            age: 26,
            behaviouralRating: 1,
            drinks: false,
            foodType: "vegetarian",
            gender: "M",
            havePets: true,
            id: 7,
            image: "/media/images/brett-jordan-E_tS8jZTJq8-unsplash.jpg",
            mobileNumber: "9876504321",
            nativePlace: "Chalisgaon",
            nativeState: "Maharashtra",
            nativeStateIsoCode: "MH",
            smokes: false,
            totalRating: 1,
            userReview: "",
            worksAt: "softwareEngineer",
            user: {
                email: "yash.kulkarni97@gmail.com",
                first_name: "Tapu",
                last_name: "Gada",
                profile: 7,
                username: "yash.kulkarni97@gmail.com",
            },
        },
        furnishing: "required",
        id: 6,
        internet: "required",
        maxNumberOfFlatmates: "maxTwoOther",
        moveInDate: "2022-08-12",
        parking: "requiredForBoth",
        preferredLengthOfStay: "6-12",
        preferred_area: "Magarpatta",
        preferred_city: "Pune",
        rentBudget: "5000-10000",
        roomType: "separate",
    },
    {
        adPostedBy: {
            aboutMe: "I am a very calm person looking for peaceful flat mates",
            age: 26,
            behaviouralRating: 1,
            drinks: false,
            foodType: "vegetarian",
            gender: "M",
            havePets: true,
            id: 7,
            image: "/media/images/brett-jordan-E_tS8jZTJq8-unsplash.jpg",
            mobileNumber: "9876504321",
            nativePlace: "Chalisgaon",
            nativeState: "Maharashtra",
            nativeStateIsoCode: "MH",
            smokes: false,
            totalRating: 1,
            userReview: "",
            worksAt: "softwareEngineer",
            user: {
                email: "yash.kulkarni97@gmail.com",
                first_name: "Tapu",
                last_name: "Gada",
                profile: 7,
                username: "yash.kulkarni97@gmail.com",
            },
        },
        furnishing: "required",
        id: 6,
        internet: "required",
        maxNumberOfFlatmates: "maxTwoOther",
        moveInDate: "2022-08-12",
        parking: "requiredForBoth",
        preferredLengthOfStay: "6-12",
        preferred_area: "Magarpatta",
        preferred_city: "Pune",
        rentBudget: "5000-10000",
        roomType: "separate",
    },
    {
        adPostedBy: {
            aboutMe: "I am a very calm person looking for peaceful flat mates",
            age: 26,
            behaviouralRating: 1,
            drinks: false,
            foodType: "vegetarian",
            gender: "M",
            havePets: true,
            id: 7,
            image: "/media/images/brett-jordan-E_tS8jZTJq8-unsplash.jpg",
            mobileNumber: "9876504321",
            nativePlace: "Chalisgaon",
            nativeState: "Maharashtra",
            nativeStateIsoCode: "MH",
            smokes: false,
            totalRating: 1,
            userReview: "",
            worksAt: "softwareEngineer",
            user: {
                email: "yash.kulkarni97@gmail.com",
                first_name: "Tapu",
                last_name: "Gada",
                profile: 7,
                username: "yash.kulkarni97@gmail.com",
            },
        },
        furnishing: "required",
        id: 6,
        internet: "required",
        maxNumberOfFlatmates: "maxTwoOther",
        moveInDate: "2022-08-12",
        parking: "requiredForBoth",
        preferredLengthOfStay: "6-12",
        preferred_area: "Magarpatta",
        preferred_city: "Pune",
        rentBudget: "5000-10000",
        roomType: "separate",
    }
])