import { serialize } from 'object-to-formdata'

export const createPostData=(formValue)=>{
	const postCreator = JSON.parse(sessionStorage.getItem("user"))?.logged_user?.profile?.id;
    const Ad={
        address_pincode:formValue.pincode,                          //max_length=6)
        address_flatNoAndbuildingName:formValue.buildingName,   
        address_area:formValue.area,    
        address_city:formValue.city,                                //max_length=20)
        address_state:formValue.state,                              //max_length=20)
        address_landMark:formValue.landmark || "NA",                 //null=True)
        flatImage1:formValue.images[0] || null,
        flatImage2:formValue.images[1] || null,
        flatImage3:formValue.images[2] || null,
        flatImage4:formValue.images[3] || null,
        flatImage5:formValue.images[4] || null,
        flatImage6:formValue.images[5] || null,
        flatImage7:formValue.images[6] || null,
        flatImage8:formValue.images[7] || null,
        flatImage9:formValue.images[8] || null,
        flatImage10:formValue.images[9] || null,
        // flatmate:formValue.listOfPeople,
        bhk:formValue.bhk || 1,
        noOfBathrooms:formValue.bathroom || 1,
        rent:formValue.rent,
        deposit:formValue.deposit,
        maintainance:formValue.amount || 0,
        noOfPeopleRequired:formValue.numberOfPeopleRequired,
        description:formValue.description || " ",               //blank=True)
        propertyType:formValue.propertyType || " ",             //blank=True)
        numberOfFloor:formValue.numberOfFloor || 1,             //default=1)
        totalFloors:formValue.totalFloors || 1,                 //default=1)
        roomType :formValue.roomType || " ",                    //blank=True)
        gender:formValue.gender || " ",                         //blank=True)
        availableFrom:formValue.availableFrom || " ",           //True)
        furnishing:formValue.furnishing || " ",                 //blank=True)
        parking:formValue.parking || " ",                       //blank=True)
        balcony:formValue.balcony || " ",                       //null=True)
        waterSupply:formValue.waterSupply || " ",               //blank=True)
        gym:formValue.gym || " ",                               //blank=True)
        nonVegAllowed:formValue.nonVegAllowed || " ",           //blank=True)
        gatedSecurity:formValue.gatedSecurity || " ",           //blank=True)
        lift:formValue.lift || false,                           //default=False)
        ac:formValue.ac || false,                               //default=False)
        intercom:formValue.intercom || false,                   //default=False)
        gasPipeline:formValue.gasPipeline || false,             //default=False)
        wifi:formValue.wifi || false,                           //default=False)
        waterPurifier:formValue.waterPurifier || false,                 //default=False)
        swimmingPool:formValue.swimmingPool || false,           //default=False)
        park:formValue.park || false,                           //default=False)
        geyser:formValue.geyser || false,             //default=False)
        visitorParking:formValue.visitorparking || false,       //default=False)
        maid:formValue.maid || false,                           //default=False)
        cook:formValue.cook || false,                           //default=False)
        numberOfPeople:formValue.numberOfPeople || "1",         //default=1)
        flatmate: postCreator
    }

    const formData=serialize(Ad)
    return formData;
}