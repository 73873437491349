export const valuesForRentBudget = {
    '0-4999': 'Upto ₹5000/month',
    '5000-9999': '₹5000 to ₹10000/month',
    '10000-14999': '₹10000 to ₹15000/month',
    '15000-19000':'₹15000 to ₹20000/month',
    '20000-24999':'₹20000 to ₹25000/month',
    '25000-29999':'₹25000 to ₹30000/month',
    '30000-50000':'above ₹30000/month'
  }

export const valuesForFoodPreference = {
    'vegetarian' : 'Vegetarian',
    'nonVegetarian' : 'Non Vegetarian'
  }

export const valuesForNumberOfFlatmates = {
    'maxOneOther': 'Max 1 Other',
    'maxTwoOther': 'Max 2 Other',
    'maxThreeOther': 'Max 3 Other',
    'flexible': 'Flexible'
  }

export const commonValues = {
    'required': 'Required',
    'notRequired': 'Not Required',
    'flexible': 'Flexible'
  }

export const valuesForParking = {
    'requiredForCar': 'Required for Car',
    'requiredForBike': 'Required for Bike',
    'requiredForBoth': 'Required for Car & Bike',
    'notRequired': 'Not Required',
    'flexible': 'Flexible',
  }

export const valuesForContractPeriod = {
    '-3': 'Upto 3 Months',
    '3-6': '3 to 6 Months',
    '6-12': '6 to 12 Months',
    '12+': 'More than 12 Months',
  }

export const formatMoveInDate = (fromDate) => {
    const months = ["January", "February", "March", "April", "May", "June",
     "July", "August", "September", "October", "November", "December"];
    const moveInFrom = new Date(fromDate)
    const month = months[moveInFrom.getMonth()]
    const date= moveInFrom.getDate()
    const year = moveInFrom.getFullYear()
    return `${date} ${month} ${year}`
}