import { Segmented, Slider } from "antd";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import '../sass/_filterBy.scss';
import { StyledBtnBorder, StyledFilter } from "./styledComponents/filterByStyledComponents";
import ChevronDown from "./svgComponents/ChevronDown";
import CloseIcon from "./svgComponents/CloseIcon";

// 1. Price range filter
// 2. Shared between number of flatmates
// 3. Gender
// 4. Room type sharing, common 
// 5. Required bhk
// 6. Places

const FilterBy = ({
    filterByData, 
    onFilterSelected, 
    onFilterChange, 
    sliderAfterChange, 
    filtersToDisplay,
    onSelectedFilterRemove,
    pageToView,
    onPageToViewChange,
    openedFilter =""
}) => {

    let filterRefs = useRef([]);
    filterRefs.current=[];

    const [showAllFilters, setShowAllFilters] = useState(false);

    useEffect(()=> {
        let handler = (event) => {
            if(openedFilter !== "" &&
            filterRefs.current.length > 0
            && !filterRefs.current.includes(event.target)
            && !(event?.target?.className.includes('filterSection'))
            && !(event?.target?.className.includes('ant-slider-rail'))
            && !(event?.target?.className.includes('ant-slider-track'))
            && !(event?.target?.className.includes('ant-slider-step'))
            && !(event?.target?.className.includes('ant-slider-handle'))
            && !(event?.target?.className.includes('ant-slider-mark'))
            ) {
                onFilterSelected(event)
            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    });

    const addToRefs = (element) => {
        if(element && !filterRefs.current.includes(element)){
            filterRefs.current.push(element)
        }
    }

    const getFilters = (filter) => {
        return (
            <div 
                className="col-md-3 col-12 p-2 border-right my-2 filterSection" 
                ref={addToRefs} 
                key={filter.id}
                id={filter.id}>
                <div className="d-flex justify-content-between clickable filterSection" id={filter.id}  onClick={onFilterSelected}>
                    <div className="d-flex mr-2 align-items-center filterSection" id={filter.id}>
                        {filter.name}
                    </div>
                    <div className={(openedFilter === filter.id) ? 'isActive filterSection' : 'filterSection'} id={filter.id} >
                        <ChevronDown id={filter.id}/>
                    </div>
                </div>
                {(openedFilter === filter.id) && !filter.isCustom &&
                    <div className="row filterSection">
                        <div className="col-12 accordion px-1 filterSection">
                            {
                                filter.values.map((value) => {
                                    return (
                                        <div class="check-box d-flex align-items-center m-2 mb-3 filterSection">
                                            <input 
                                                class="check-box-input filterSection" 
                                                type="checkbox" 
                                                value={value.value} 
                                                id={`${filter.id}_${value.value}`}
                                                onChange={onFilterChange} 
                                                checked={value.isChecked}/>
                                            <label class="check-box-label filterSection filter-options" for="flexCheckDefault">
                                                {value.displayName}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    
                }
                {(openedFilter === filter.id) && filter.isCustom && 
                    <div className="row filterSection">
                        <div className="col-12 accordion filterSection">
                            <div className="price-wrapper my-4 filterSection">
                                <Slider
                                    vertical
                                    marks={filter.options} 
                                    defaultValue={20000}
                                    value={filter.filterValue}
                                    min={filter.min} 
                                    max={filter.max} 
                                    step={500} 
                                    onAfterChange={sliderAfterChange}
                                    reverse={filter.filterFor === 'flats' ? true : false}
                                    className="filterSection"
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    const loadFilters = () => {
        return filterByData.map((filter, index) => {
            if (showAllFilters) {
               return getFilters(filter)
            } else if (!showAllFilters && index < 3) {
                return getFilters(filter)
            }
            
        })
    }

    const displaySelectedFilters = () => {
        return filtersToDisplay.map((filter) => {
            // return (<div>{filter.value}</div>)
            return (
                <>
                    <StyledFilter 
                        onClick={onSelectedFilterRemove} 
                        id={filter.id} 
                        disabled={filter.id === 'cityAndAddressFilter'}>
                        <span className="mr-1" id={filter.id}>
                            {filter.value}
                        </span>
                        {
                            filter.id !== 'cityAndAddressFilter'
                            ? <CloseIcon height='1.5em' width='1.5em' id={filter.id}/>
                            : null
                        }
                        
                    </StyledFilter>
                    
                </>
            )
         })
    }

    return (
        <div className="filter-by my-md-5 my-4 mx-3 px-3">
            <div className=" py-3">
                <Segmented 
                block
                options={[
                    {
                        label: (
                            <div className="my-0">Flats</div>
                        ),
                        value: 'flats'
                    },
                    {
                        label: (
                            <div>Flatmates</div>
                        ),
                        value: 'flatmates'
                    }
                ]} 
                value={pageToView}
                size="large"
                onChange={onPageToViewChange} 
                />
            </div>
            <div className="d-flex justify-content-between row mx-0 py-3">
                <div className='col-md-3 col-12 p-2'>
                    <StyledBtnBorder 
                        className="bottom-border" 
                        onClick={()=>setShowAllFilters(!showAllFilters)}>
                        {
                            showAllFilters ? 'Show Less': 'Show all Filters'
                        }
                    </StyledBtnBorder> 
                </div>
                {loadFilters()}
            </div>
            <div className="row my-2 py-3 mx-0">
                {displaySelectedFilters()}
            </div>
        </div>
    )
}

export default FilterBy;