import { domain } from "../BackendUrl";
import noImage from '../assets/noImage.jpg';
import {  valuesForRentBudget, formatMoveInDate } from './SelfAdsConstant'
import { useNavigate } from "react-router-dom";
import TrashIcon from "./svgComponents/TrashIcon";

// const SelfAdsCard = () => {

    const loadImage = (card) => {
        const {id, adPostedBy} = card;
        const {image} = adPostedBy;
        const imageUrl = image && image !== "" ? `${domain}${image}`: noImage
        return(
                <img 
                id={id} 
                className="img-fluid d-block w-100 adsImg" 
                src={imageUrl} alt="..."/>
        )
    }

    const loadTitle = (card)=>{
        const {adPostedBy} = card;
        const {user} = adPostedBy;
        return (
            <>
                <strong>{`${user.first_name} ${user.last_name}`}</strong>
            </>
            )
    }

    const loadDescription = (card) => {
        const {id, adPostedBy, rentBudget, moveInDate} = card;
        const {user, aboutMe, age, gender} = adPostedBy;
        return(
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-7 px-0">
                            <b>{valuesForRentBudget[rentBudget].slice(0,-3)}</b>
                        </div>
                        <div className="col-4 offset-1 px-0">
                            {`${age} yr ${gender === 'M' ? 'Man' : 'Woman'}`}
                        </div>
                    </div>
                    {/* text-nowrap */}
                    <div className="row mt-1">
                        {`${aboutMe.slice(0,35)}....`}
                    </div>
                    <div className="row mt-1">
                        Move in &nbsp;<b>{formatMoveInDate(moveInDate)}</b>
                    </div>
                </div>
            </>
        )
    }

export const loadSelfAdsCard = (card, index, wrapperClass, profileId, deleteFlatmatePost) => {
        return (
            <div className={wrapperClass} key={`card-${index}`} id={card.id}>
                <div className="shadow card mb-5 mx-0 adsCard clickable w-100">
                    <a href={`view-post-for-flat-requirement/${card.id}`} className="cardLink" key={index} id={card.id}>
                        {loadImage(card)}
                        <div className="card-body" key={`card-body${index}`} id={card.id}>
                            <h5 className="card-title" key={`card-title${index}`} id={card.id}>
                                {loadTitle(card)}
                            </h5>
                            <p className="card-text" key={`card-text${index}`} id={card.id}>
                                {loadDescription(card)}
                            </p>
                        {/* <p class="card-text"><small className="text-muted">Last updated 3 mins ago</small></p> */}
                        </div>
                    </a>
                    {deleteFlatmatePost &&
                    <div class="row mx-3 mb-3 justify-content-end">
                        <div className="clickable" onClick={(e) => deleteFlatmatePost(card.id, profileId, 'selfAds')}>
                            <TrashIcon height="1.5em" width="1.5em"/>
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    }
// }

// export default SelfAdsCard;