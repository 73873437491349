import axios from "axios";
import { getAllCitiesUrl,
    getAllFlatsUrl,
    postAdUrl,
    getFlatByIdUrl,
    loginUrl,
    searchFlatsUrl,
    authenticateUrl,
    verifyOtpUrl,
    updateProfileUrl,
    postAdForSelfUrl,
    getFlatRequirementByIdUrl,
    getAllFlatRequirementsUrl,
    socialLoginUrl,
    searchFlatmatesUrl, 
    deleteFlatByIdUrl,
    getUserProfile,
    deleteFlatmateByIdUrl} from "../BackendUrl";
import {
    call,
    put
    } from "redux-saga/effects"
import {
    hideLoader, 
    setAllAds,
    setAllAdsForFlatRequirements,
    setCities,
    setCount,
    setFlatmateSearchResult,
    setFlatSearchResult,
    setLoggedInUser,
    setOffset,
    setPost,
    setResponse,
    setSelfAdCount,
    setSelfAdOffset,
    showLoader,
    updateUserMultipleData } from "../actions";
import { getAllFlatsMockResonce } from "../mockResponses/getAllFlatsMock";
import store from '../store'
import { getAllFlatRequiremntMockResponse } from "../mockResponses/getAllFlatRequirementMock";

export function requestGetCities(){
    return axios.request({
        method:'GET',
        url: getAllCitiesUrl
    })
} 

export function requestGetAllAds(){
    const state = store.getState();
    const {paginationReducer}= state
    const { limit, offset} = paginationReducer
    // const limit = 11;
    // const offset= 0;
    return axios.request({
        method:'get',
        url:`${getAllFlatsUrl}?limit=${limit}&offset=${offset}`
    })
}

export function requestGetAdById(flatId){
    return axios.request({
        method:'get',
        url:`${getFlatByIdUrl}${flatId}/`
    })
}

export function requestGetAdForFlatRequirementById(adId){
    return axios.request({
        method:'get',
        url:`${getFlatRequirementByIdUrl}${adId}/`
    })
}

// Request for fetching Ads for people looking for Flat
export function requestGetAllFlatRequirementsAds(){
    const state = store.getState();
    const {paginationReducer}= state
    const { limit, offset} = paginationReducer
    // const limit = 11;
    // const offset= 0;
    return axios.request({
        method:'get',
        url:`${getAllFlatRequirementsUrl}?limit=${limit}&offset=${offset}`
    })
}

export function requestPostAd(payload){
    return axios.request({
        method: 'POST',
        url: postAdUrl,
        data: payload
    })
}

export function requestPostAdForSelf(payload){
    return axios.request({
        method: 'POST',
        url: postAdForSelfUrl,
        data: payload
    })
}

export function requestLogin(payload){
    const {user} = payload
    return axios.request({
        method: 'POST',
        url: loginUrl,
        data: user
    })
}

export function requestSocialLogin(payload){
    const {user, author} = payload
    return axios.request({
        method: 'POST',
        url: `${socialLoginUrl}${author}`,
        data: user
    })
}

export function requestRegistration(payload){
    const {userData} = payload
    // authenticateUrl  createAccountUrl
    return axios.request({
        method: 'POST',
        url: authenticateUrl,
        data: userData
    })
}

export function requestVerifyOtp(payload){
    const {otpData} = payload;
    return axios.request({
        method: 'POST',
        url: verifyOtpUrl,
        data: otpData
    })
}

export function requestFlatSearch(payload){
    const state = store.getState();
    const {paginationReducer}= state
    const { limit, offset} = paginationReducer
    const {flatData} = payload
    const {addressSearch, 
        citySearch, 
        rent, 
        numberOfPeople, 
        gender, 
        roomType,
        furnishing,
        parking,
        amenities} = flatData
    return axios.request({
        method: 'GET',
        url: `${searchFlatsUrl}?limit=${limit}&offset=${offset}`,
        params: {searchFor: addressSearch,
            city: citySearch,
            filters: {
                rent,
                numberOfPeople,
                gender,
                roomType,
                furnishing,
                parking,
                amenities
            }
        }
    })
}
export function requestFlatmateSearch(payload){
    const state = store.getState();
    const {paginationReducer}= state
    const { limit, offset} = paginationReducer
    const {flatmateData} = payload
    const {
        addressSearch, 
        citySearch, 
        rent,
        gender, 
        roomType,
        foodType
    } = flatmateData
    return axios.request({
        method: 'GET',
        url: `${searchFlatmatesUrl}?limit=${limit}&offset=${offset}`,
        params: {searchFor: addressSearch,
            city: citySearch,
            filters: {
                rent,
                gender,
                roomType,
                foodType
            }
        }
    })
}

export function requestUserProfileUpdate(payload){
    const {userProfile} = payload
    return axios.request({
        method: 'PUT',
        url: updateProfileUrl,
        data: userProfile
    })
}

export function requestDeleteFlatPost(payload){
    const {flatId, flatmate} = payload;
    return axios.request({
        method: 'POST',
        url: deleteFlatByIdUrl,
        data: {flatId, flatmate}
    })
}

export function requestDeleteFlatMatePost(payload){
    const {flatmatePostId, adPostedBy} = payload;
    return axios.request({
        method: 'POST',
        url: deleteFlatmateByIdUrl,
        data: {flatmatePostId, adPostedBy}
    })
}

export function requestGetUserProfile(payload) {
    const {username} = payload;
    return axios.request({
        method: 'POST',
        url: getUserProfile,
        data: {username}
    })
}




export function* handleGetCities(action){
    try{
        const response=yield call(requestGetCities)
        const {data}=response;
        
        yield put(setCities(data))
    }catch(error){
        const cities= ["Pune", "Mumbai", "Banglore", "Delhi"] 
        yield put(setCities(cities))
    }
}

export function* handleGetAllAds(){
    try{
        const state = store.getState();
        const {paginationReducer}= state
        const { limit, offset} = paginationReducer
        yield put(showLoader())
        const response=yield call (requestGetAllAds)
        const {data:{results, count}}=response;
        yield put(setOffset(offset+limit))
        yield put(setAllAds(results))
        yield put(setCount(count))

    }catch{
        yield put(setAllAds(getAllFlatsMockResonce))
    }finally{
        yield put(hideLoader())
    }
}

export function* handleGetAdById({flatId}){
    try{
        yield put(showLoader())
        const response=yield call (requestGetAdById, flatId)
        const {data}=response;
        yield put(setPost(data))
    }catch{
        const response={
            status:'Error',
            errorId: 404,
            statusText:'Add not Found'
        }
        yield put(setResponse(response))
        return response
    }
    finally {
        yield put(hideLoader())
    }
}

export function* handleGetAdForFlatRequirementById({adId}){
    try{
        yield put(showLoader())
        const response=yield call (requestGetAdForFlatRequirementById, adId)
        const {data}=response;
        yield put(setPost(data))
    }catch{
        const response={
            status:'Error',
            errorId: 404,
            statusText:'Add not Found'
        }
        yield put(setResponse(response))
        return response
    }
    finally {
        yield put(hideLoader())
    }
}

export function* handleGetAllFlatRequirementsAds(){
    try{
        const state = store.getState();
        const {paginationReducer}= state
        const { limitForSelfAd, offsetForSelfAd} = paginationReducer
        yield put(showLoader())
        const response=yield call (requestGetAllFlatRequirementsAds)
        const {data:{results, count}}=response;
        yield put(setSelfAdOffset(limitForSelfAd+offsetForSelfAd))
        yield put(setAllAdsForFlatRequirements(results))
        yield put(setSelfAdCount(count))
    }catch{
        yield put(setAllAdsForFlatRequirements(getAllFlatRequiremntMockResponse))
    }
    finally{
        yield put(hideLoader())
    }
}

export function* handleCreatePost({postData}){
    try{
        const response=yield call (requestPostAd, postData)
        const {data}=response;
        yield put(setPost(data))
        yield put(setResponse(response))
        return response
    }catch{
        const response={
            status:400,
            statusText:'Post Creation Failed'
        }
        yield put(setResponse(response))
        return response
    }
}

export function* handleLogin(user){
    try{
        yield put(showLoader())
        const response=yield call (requestLogin, user)
        const {data}=response;
        const storeDate = {
            logged_user: data,
            logged_fullName: `${data.first_name} ${data.last_name}`,
            dialog_visible: false,
            logged_out: false,
            loginForm_visible: false,
            registerForm_visible: false,
            loginError: false,
            registerError: false,
            errorMessage: ''
        }
        sessionStorage.setItem("user", JSON.stringify(storeDate));
        yield put(setLoggedInUser(storeDate))
    }catch({response}){
        const {data: {error}, status} = response;
        const storeDate = {
            loginError: true,
            registerError: false,
            errorMessage: error
        }
        yield put(setLoggedInUser(storeDate))
        return response
    } finally{
        yield put(hideLoader())
    }
}

export function* handleSocialLogin(user, author){
    try{
        yield put(showLoader())
        const response=yield call (requestSocialLogin, user, author)
        const {data}=response;
        const storeDate = {
            logged_user: data,
            logged_fullName: `${data.first_name} ${data.last_name}`,
            dialog_visible: false,
            logged_out: false,
            loginForm_visible: false,
            registerForm_visible: false,
            loginError: false,
            registerError: false,
            errorMessage: ''
        }
        sessionStorage.setItem("user", JSON.stringify(storeDate));
        yield put(setLoggedInUser(storeDate))
    }catch({response}){
        const {data: {error}, status} = response;
        const storeDate = {
            loginError: true,
            registerError: false,
            errorMessage: error
        }
        yield put(setLoggedInUser(storeDate))
        return response
    } finally{
        yield put(hideLoader())
    }
}

export function* handleRegister(userData){
    try{
        yield put(showLoader())
        const response=yield call (requestRegistration, userData)
        const {data}=response;
        const user_registration={...userData, otp_sent: true }
        sessionStorage.setItem("user_under_registration", JSON.stringify(user_registration));
        const fieldsToUpdate = {
            otpForm_visible: true,
            registerForm_visible: true
        }
        yield put(updateUserMultipleData(fieldsToUpdate))
    }catch{
        const response={
            status:400,
            statusText:'Registration Failed'
        }
        const user_registration={...userData, otp_sent: false }
        return response
    }finally{
        yield put(hideLoader())
    }
}

export function* handleCheckOtp(otpData){
    try{
        yield put(showLoader())
        const response=yield call (requestVerifyOtp, otpData)
        const {data}=response;
        const user_registration={otp_sent: false }
        sessionStorage.setItem("user_under_registration", JSON.stringify(user_registration));
        const storeData = {
            logged_user: data,
            logged_fullName: `${data.first_name} ${data.last_name}`,
            dialog_visible: false,
            logged_out: false,
            loginForm_visible: false,
            registerForm_visible: false,
            otpForm_visible: false,
            loginError: false,
            registerError: false,
            errorMessage: ''
        }
        yield put(setLoggedInUser(storeData))
    }catch({response}){
        const {data: {error}, status} = response;
        const storeDate = {
            loginError: false,
            registerError: true,
            errorMessage: error
        }
        yield put(setLoggedInUser(storeDate))
        return response
    }finally{
        yield put(hideLoader())
    }
}

export function* handleFlatSearch(searchData){
    try{
        const state = store.getState();
        const {paginationReducer}= state
        const { limit, offset} = paginationReducer
        yield put(showLoader())
        const response=yield call (requestFlatSearch, searchData)
        const {data:{results, count}}=response;
        yield put(setOffset(offset+limit))
        yield put(setFlatSearchResult(results))
        yield put(setCount(count))
    }catch{
        const response={
            status:400,
            statusText:'Oops! dont have any Post'
        }
        yield put(setFlatSearchResult([]))
        // yield put(setResponse(response))
        // TODO: Handle error scenario by navigating to commoon error page
        // return response
    }finally{
        yield put(hideLoader())
    }
}

export function* handleFlatmateSearch(searchData){
    try{
        // Using same limit offset same as flatSearch
        const state = store.getState();
        const {paginationReducer}= state
        const { limit, offset} = paginationReducer

        yield put(showLoader())
        const response=yield call (requestFlatmateSearch, searchData)
        const {data:{results, count}}=response;
        yield put(setOffset(offset+limit))
        yield put(setFlatmateSearchResult(results))
        yield put(setCount(count))
    }catch{
        const response={
            status:400,
            statusText:'Oops! dont have any Post'
        }
        yield put(setFlatmateSearchResult([]))
        // yield put(setResponse(response))
        // TODO: Handle error scenario by navigating to commoon error page
        // return response
    }finally{
        yield put(hideLoader())
    }
}

export function* handleUserProfileUpdate(userProfile){
    try{
        const response = yield call(requestUserProfileUpdate, userProfile)
        let storedUser = JSON.parse(sessionStorage.getItem("user"));
        storedUser.logged_user.profile=response.data
        sessionStorage.setItem("user", JSON.stringify(storedUser));
        yield put(setLoggedInUser(storedUser))
    }catch{
        const response={
            status:400,
            statusText:'Oops! dont have any Post'
        }
        yield put(setFlatSearchResult([]))
        // yield put(setResponse(response))
        // TODO: Handle error scenario by navigating to commoon error page
        return response
    }
}

export function* handleCreatePostForSelf({postData}){
    try{
        const response=yield call (requestPostAdForSelf, postData)
        const {data}=response;
        yield put(setPost(data))
        yield put(setResponse(response))
        return response
    }catch{
        const response={
            status:400,
            statusText:'Post Creation Failed'
        }
        yield put(setResponse(response))
        return response
    }
}

export function* handleDeleteFlatPost(flatId, flatmate) {
    const response=yield call(requestDeleteFlatPost, flatId, flatmate)
    window.location.reload()
    return response
}

export function* handleDeleteFlatmatePost(flatmatePostId, adPostedBy) {
    const response=yield call(requestDeleteFlatMatePost, flatmatePostId, adPostedBy)
    window.location.reload()
    return response
}

export function* handleGetUserProfile(userName) {
    try {
        yield put(showLoader())
        const response = yield call(requestGetUserProfile, userName)
        const {data}=response;
        const storeDate = {
            logged_user: data,
            logged_fullName: `${data.first_name} ${data.last_name}`,
            dialog_visible: false,
            logged_out: false,
            loginForm_visible: false,
            registerForm_visible: false,
            loginError: false,
            registerError: false,
            errorMessage: ''
        }
        sessionStorage.setItem("user", JSON.stringify(storeDate));
        yield put(setLoggedInUser(storeDate))
    } catch({response}){
        const {data: {error}, status} = response;
        const storeDate = {
            loginError: true,
            registerError: false,
            errorMessage: error
        }
        yield put(setLoggedInUser(storeDate))
        // yield put(setResponse(response))
        return response
    } finally{
        yield put(hideLoader())
    }
}