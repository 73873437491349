import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { domain } from "../BackendUrl";
// import ResponsiveSlider from './ResponsiveSlider';
import Slider from "react-slick";
import BedroomIcon from './svgComponents/BedroomIcon';
import BathroomIcon from './svgComponents/BathroomIcon';
import FlatmateIcon from './svgComponents/FlatmateIcon';
import ArrowNext from './svgComponents/ArrowNext'
import { StyledCarouselButtons } from './styledComponents/homeStyledComponents';
import ArrowPrevious from './svgComponents/ArrowPrevious';
import AcIcon from './svgComponents/AcIcon';
import '../styles/viewPost.css'
import { useParams } from 'react-router-dom';
import { getAdById } from '../actions';
import '../sass/_viewPost.scss'
import WifiIcon from './svgComponents/WifiIcon';
import GeyserIcon from './svgComponents/GeyserIcon';
import RentIcon from './svgComponents/RentIcon';
import CookIcon from './svgComponents/CookIcon';
import AmenitiesIcon from './svgComponents/AmenitiesIcon';
import GasIcon from './svgComponents/GasIcon';
import MaidIcon from './svgComponents/MaidIcon';
import PeopleIcon from './svgComponents/PeopleIcon';
import IntercomIcon from './svgComponents/IntercomIcon';
import LiftIcon from './svgComponents/LiftIcon';
import SwimmingpoolIcon from './svgComponents/SwimmingpoolIcon';
import VegIcon from './svgComponents/VegIcon';
import NonVegIcon from './svgComponents/NonVegIcon';
import SecurityIcon from './svgComponents/SecurityIcon';
import GymIcon from './svgComponents/GymIcon';
import FurnitureIcon from './svgComponents/FurnitureIcon';
import FridgeIcon from './svgComponents/FridgeIcon';
import WaterSupplyIcon from './svgComponents/WaterSupplyIcon';
import ParkingIcon from './svgComponents/ParkingIcon';
import ViewPostSkeleton from './ViewPostSkeleton';
import NotFoundPage from './NotFoundPage';

function ViewPost(){

  // this.props.match.params.id
    const {flatId} = useParams()
    // const postData = {}
    const dispatch = useDispatch()  
    useEffect(() => {
      dispatch(getAdById(flatId))
    }, [])
    const {postData = {}} = useSelector(state => {
      return state.createPost
    })

    const {isLoading = false, response = {}} = useSelector(state => {
      return state.loader
    })

    const {
            ac,
            address_area,
            address_city,
            address_flatNoAndbuildingName,
            address_landMark,
            address_pincode,
            address_state,
            availableFrom,
            balcony,
            bhk,
            cook,
            deposit,
            description,
            waterPurifier,
            flatRating,
            flatValidated,
            flatmate = [],
            furnishing,
            gasPipeline,
            gatedSecurity,
            gender,
            geyser,
            gym,
            id,
            intercom,
            lift,
            maid,
            maintainance,
            noOfBathrooms,
            noOfPeopleRequired,
            nonVegAllowed,
            numberOfFloor,
            numberOfPeople,
            park,
            parking,
            propertyType,
            rent,
            roomType,
            swimmingPool,
            totalFloors,
            visitorParking,
            waterSupply,
            wifi,
            // flatImage1,
            // flatImage2,
            // flatImage3,
            // flatImage4,
            // flatImage5,
            // flatImage6,
            // flatImage7,
            // flatImage8,
            // flatImage9,
            // flatImage10,
    } = postData || {}

    const loadFlatmates = (mate) => {
      const {
        user,
        mobileNumber
      } = mate || {};
      const {
        email, 
        first_name,
        last_name
      } = user || {};

      return (
        <div className='row mt-2 ml-2 p-2 border rounded'>
          <div className='col-12'>
            {`${first_name} ${last_name}`}
          </div>
          <div className='col-12'>
            {mobileNumber}
          </div>
          <div className='col-12'>
            {email}
          </div>
        </div>
      )
    }

    const getFurnishing = () => {
    
      if(furnishing === 'fullFurnished'){
        return ({
          available: true,
          text: 'Full Furnished',
          icon: <FurnitureIcon width="2em" height="2em" fill="#000"/>
        })
      } else if(furnishing === 'semiFurnished'){
        return ({
          available: true,
          text: 'Semi Furnished',
          icon: <FurnitureIcon width="2em" height="2em" fill="#000"/>
        })
      }else{
        return({
          available: true,
          text: 'Un Furnished',
          icon: <FurnitureIcon width="2em" height="2em" fill="#000"/>
        })
      }
    }

    const amenities = [
      {
        available:ac,
        text: 'AC',
        icon: <AcIcon width="2em" height="2em" fill="#000"/>
      },
      {
        available:cook,
        text: 'Cook',
        icon: <CookIcon width="2em" height="2em" fill="#000"/>
      },
      getFurnishing(),
      {
        available: gatedSecurity,
        text: 'Security',
        icon: <SecurityIcon width="2em" height="2em" fill="#000"/>
      },
      {
        available: geyser,
        text: 'Geyser',
        icon: <GeyserIcon width="2em" height="2em" fill="#000"/>
      },
      {
        available: gym,
        text: 'Gym',
        icon: <GymIcon width="2em" height="2em" fill="#000"/>
      },
      {
        available: intercom,
        text: 'Intercom' ,
        icon: <IntercomIcon width="2em" height="2em" fill="#000"/>
      },
      {
        available: lift ,
        text: 'Lift' ,
        icon: <LiftIcon width="2em" height="2em" fill="#000"/>
      },
      {
        available: maid,
        text: 'Maid',
        icon: <MaidIcon width="2em" height="2em" fill="#000"/>
      },
      {
        available: true,
        text: nonVegAllowed? 'Non Veg' : 'Veg',
        icon: nonVegAllowed? <NonVegIcon width="2em" height="2em" fill="#000"/> : <VegIcon width="2em" height="2em" fill="#000"/>
      },
      {
        available: true,
        text: waterSupply,
        icon: <WaterSupplyIcon width="2em" height="2em" fill="#000"/>
      },
      {
        available: wifi,
        text: 'Wifi',
        icon: <WifiIcon width="2em" height="2em" fill="#000"/>
      },
      {
        available: swimmingPool,
        text: 'swimmingPool',
        icon: <SwimmingpoolIcon width="2em" height="2em" fill="#000"/>
      },
      {
        available: true,
        text: parking,
        icon: <ParkingIcon width="2em" height="2em" fill="#000"/>
      }
    ]

    const images=[]
    for(let i=1; i<=10;i++){
        if(postData[`flatImage${i}`]){
            images.push(postData[`flatImage${i}`])
        }
        
    }

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          // <StyledCarouselButtons
          //   className={className}
          //   onClick={onClick}
          // />
          <div className={`${className} d-flex flex-column`} onClick={onClick} > 
            <ArrowNext  />
            {/* <i className="fas fa-long-arrow-alt-right" /> */}
          </div>
          
        );
      }
      
    function SamplePrevArrow(props) {
        const {className, style, onClick } = props;
        return (
            <div className={`${className} d-flex flex-column`} onClick={onClick} >
              <ArrowPrevious />
            </div>
        );
    }

    const sliderSettings = {
        // className: "center",
        // centerMode: true,
        // centerPadding: "60px",
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    const loadAvailableFromDate = (fromDate) => {
      const months = ["January", "February", "March", "April", "May", "June",
       "July", "August", "September", "October", "November", "December"];
      // const currentDate = new Date();
      const availableFrom = new Date(fromDate)
      // if(currentDate >= availableFrom){
      //     return `Availble now`
      // }
      const month = months[availableFrom.getMonth()]
      const date= availableFrom.getDate()
      const year = availableFrom.getFullYear()
      return `${date} ${month} ${year}`
  }

    const loadAmenities = () => {
      return amenities.map(amenity=>{
        const {available, text, icon} = amenity;
        return( available &&
          // <div className='col-md-6 col-12 float-sm-right'>
          //   <div className='p-2 d-flex flex-row align-items-center'>
          //     <div className='col-3 align-items-center'>
          //       {icon}
          //     </div>
          //     <div className='col-7 align-items-center text-capitalize'>
          //       {text}
          //     </div>
          //   </div>
          // </div>
          <div className='flex-row border rounded m-2'>
            <div className='p-2 d-flex flex-row align-items-center'>
              <div className='px-2'>
                {icon}
              </div>
              <div className='align-items-center text-capitalize px-2'>
                {text}
              </div>
            </div>
          </div>
        )
      })
    }
  
    if(isLoading) {
      return <ViewPostSkeleton />
    }

    if(response && response.status === 'Error') {
      return <NotFoundPage />
    }

    return (
        <>
            <div className="container-fluid" style={{ background: 'white', padding:'10px'}}>
                <br/>
                <div className="container-fluid" style={{ padding:'10px'}}>
                <br/>
                    <Slider {...sliderSettings}>
                        {
                            images.map((item, index) => {
                                const imgUrl = `${domain}${item}`
                                return (
                                  // style={{margin:'1.5em', width:'500px',  height: '400px', padding:'1px'}}
                                    <div key={`${imgUrl}img${index}`} >
                                        <img id={`cardId${id}`} className="img-fluid" style={{padding:'5px', width:'490px',  height: '400px'}} src={imgUrl} alt="..."/>
                                    </div>  
                                )
                            })
                        }
                    </Slider>
                </div>
                <div className='container-fluid my-4' >
                    {/* <div style={{ background: 'white', padding:'10px'}}> */}
                      <div className='row m-2' style={{ background: 'white', padding:'10px'}}>
                        <h2>{`${address_area},
                            ${address_city}`}</h2>
                      </div>
                      <hr/>
                      <div className='m-2 d-flex flex-row row'>
                        <div className='p-2 m-3 border rounded float-left'>
                          <div className='d-flex flex-row justify-content-center f18'>
                            <span className='b'>
                              {`₹${rent}`}
                            </span>
                            <span>/month</span>
                          </div>
                          <div className='d-flex flex-row justify-content-center'>
                              {maintainance>0?maintainance:'Maintenance Incld.'}
                          </div>
                        </div>
                        <div className="md-3 mx-3 d-flex flex-md-row align-items-md-center flex-column align-items-start">
                          {/* <div className="w-100 d-flex flex-row flex-wrap"> */}
                              <div className="m-1 d-flex flex-row align-items-center">
                              <BedroomIcon height='2em' width='2em'/> 
                                <span className='f16 b mx-2'>{bhk} BHK</span> &nbsp;
                              </div>
                              <div className="m-1 d-flex flex-row align-items-center">
                              <BathroomIcon height='2em' width='2em'/> 
                                <span className='f16 b mx-2'>{noOfBathrooms} {noOfBathrooms > 1 ? 'Bathrooms' : 'Bathroom'}</span> &nbsp;
                              </div>
                              <div className="m-1 d-flex flex-row align-items-center">
                              <FlatmateIcon height='2em' width='2em'/> 
                                <span className='f16 b mx-2'>{numberOfPeople} Living</span>
                              </div>
                        </div>
                        <div className="m-3 d-flex flex-row align-items-center">&#9642;{` ${noOfPeopleRequired} ${gender} flatmate required`}</div>
                          {/* </div>        */}
                      </div>
                      <hr/>
                      <div className='container m-2 p-2'>
                        <div className='row p-2 f20 b'>
                          Property Address:
                        </div>
                        <div className='container f16'>
                          <div className='row'>
                          {address_flatNoAndbuildingName} {propertyType},
                          </div>
                          <div className='row'>
                          {address_landMark!=='NA'?address_landMark+',':''}
                          </div>
                          <div className='row'>
                          {address_area}, {address_city},
                          </div>
                          <div className='row'>
                          {address_pincode},
                          </div>
                          <div className='row'>
                          {address_state?.charAt(0)+address_state?.slice(1).toLowerCase()}
                          </div>
                        </div>
                      </div>
                      <hr/>
                      <div className='container m-2 p-2'>
                        <div className='row p-2 f20 b'>
                          Flatmate Overview:
                        </div>
                        <div className='container f16'>
                          <div className='row'>
                            Flatmates:
                          </div>
                          <div className='row'>
                            <div className='col-md-5 col-12'>
                              {
                                flatmate.map((mate) => loadFlatmates(mate))
                              }
                            </div>
                          </div>
                          <hr/>
                          <div className='row'>
                          Description By Flatmate:
                          </div>
                          <div className='row'>
                            <div className='col-12'>
                              {description ? description : `Looking for cool ${gender} flatmate to share
                              accomodation with us. All the details are mentioned above. You can visit the flat 
                              anytime.`}
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <hr/>
                      <div className='container m-2 p-2'>
                        <div className='row p-2 f20 b'>
                          Room Overview:
                        </div>
                        <div className='container f16'>
                          <div className='row'>
                            Rental Details:
                          </div>
                          <div className='row'>
                            <div className='col-12 col-md-6'>
                              <div className='row'>
                                <div className='col-5'>Rent</div>
                                <div className='col-1'>:</div>
                                <div className='col-5'>₹ {rent} per Month</div>
                              </div>

                              <div className='row'>
                                <div className='col-5'>Deposit</div>
                                <div className='col-1'>:</div>
                                <div className='col-5'>₹ {deposit}</div>
                              </div>

                              <div className='row'>
                                <div className='col-5'>Room type</div>
                                <div className='col-1'>:</div>
                                <div className='col-5'>{roomType}</div>
                              </div>
                            </div>

                            <div className='col-12 col-md-6'>
                              <div className='row'>
                                <div className='col-5'>Maintenance</div>
                                <div className='col-1'>:</div>
                                <div className='col-5'>{maintainance>0 ? `₹${maintainance}` : 'Inclusive in Rent'}</div>
                              </div>

                              <div className='row'>
                                <div className='col-5'>Floor</div>
                                <div className='col-1'>:</div>
                                <div className='col-5'>{numberOfFloor} of {totalFloors}</div>
                              </div>

                              <div className='row'>
                                <div className='col-5'>Available from</div>
                                <div className='col-1'>:</div>
                                <div className='col-5'>{loadAvailableFromDate(availableFrom)}</div>
                              </div>
                            </div>
                          </div>
                          <hr/>
                          <div className='row'>
                            Amenities:
                          </div>
                          <div className='row my-2 flex-md-row flex-column mr-5 pr-4'>
                            {loadAmenities()}
                          </div>
                          
                        </div>
                        <div>
                        </div>
                      </div>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}

// const mapStateToProps = state => {
//     const {createPost}=state
//     return createPost
//   }
  
// const mapDispatchToProps = dispatch => {
//   return {
//       getAds:_=>dispatch(getAdById()),
//       setAdToView:(postData)=>dispatch(setPost(postData))
//   }
// }

export default ViewPost;