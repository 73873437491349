import React from 'react'

function VerifiedIcon(){
    return (
        <svg id="Layer_1" 
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg" 
            width="2em" height="2em" 
            viewBox="0 0 122.88 116.87"
        >
            {/* <defs>
                <style>.cls-1{{fill:'#10a64a',fillRule:'evenodd'}}.cls-2{{fill:'#fff'}}</style>
                </defs> */}
                <title>verified-symbol</title>
                <polygon
                    fill='#fff'
                    fillRule='evenodd'
                    stroke='#10a64a'
                    strokeWidth={'10px'}
                    points="61.37 8.24 80.43 0 90.88 17.79 111.15 22.32 109.15 42.85 122.88 58.43 109.2 73.87 111.15 94.55 91 99 80.43 116.87 61.51 108.62 42.45 116.87 32 99.08 11.73 94.55 13.73 74.01 0 58.43 13.68 42.99 11.73 22.32 31.88 17.87 42.45 0 61.37 8.24 61.37 8.24"/>
                <path
                    fill='#10a64a'
                    d="M37.92,65c-6.07-6.53,3.25-16.26,10-10.1,2.38,2.17,5.84,5.34,8.24,7.49L74.66,39.66C81.1,33,91.27,42.78,84.91,49.48L61.67,77.2a7.13,7.13,0,0,1-9.9.44C47.83,73.89,42.05,68.5,37.92,65Z"/>
        </svg>
    )
}

export default VerifiedIcon