import React from 'react'

function ArrowPrevious(){
    return (
        // <svg 
        // version="1.1" 
        // id="Layer_1" 
        // xmlns="http://www.w3.org/2000/svg" 
        // xmlnsXlink="http://www.w3.org/1999/xlink" 
        // x="0px" y="0px" 
        // viewBox="0 0 122.88 122.88"  
        // xmlSpace="preserve"
        // height='50px'
        // width="50px"
        // fill="white">
        //     <g>
        //         <path d="M84.93,4.66C77.69,1.66,69.75,0,61.44,0C44.48,0,29.11,6.88,18,18C12.34,23.65,7.77,30.42,4.66,37.95 C1.66,45.19,0,53.13,0,61.44c0,16.96,6.88,32.33,18,43.44c5.66,5.66,12.43,10.22,19.95,13.34c7.24,3,15.18,4.66,23.49,4.66 c8.31,0,16.25-1.66,23.49-4.66c7.53-3.12,14.29-7.68,19.95-13.34c5.66-5.66,10.22-12.43,13.34-19.95c3-7.24,4.66-15.18,4.66-23.49 c0-8.31-1.66-16.25-4.66-23.49c-3.12-7.53-7.68-14.29-13.34-19.95C99.22,12.34,92.46,7.77,84.93,4.66L84.93,4.66z M65.85,47.13 c2.48-2.52,2.45-6.58-0.08-9.05s-6.58-2.45-9.05,0.08L38.05,57.13c-2.45,2.5-2.45,6.49,0,8.98l18.32,18.62 c2.48,2.52,6.53,2.55,9.05,0.08c2.52-2.48,2.55-6.53,0.08-9.05l-7.73-7.85l22-0.13c3.54-0.03,6.38-2.92,6.35-6.46 c-0.03-3.54-2.92-6.38-6.46-6.35l-21.63,0.13L65.85,47.13L65.85,47.13z M80.02,16.55c5.93,2.46,11.28,6.07,15.76,10.55 c4.48,4.48,8.09,9.83,10.55,15.76c2.37,5.71,3.67,11.99,3.67,18.58c0,6.59-1.31,12.86-3.67,18.58 c-2.46,5.93-6.07,11.28-10.55,15.76c-4.48,4.48-9.83,8.09-15.76,10.55C74.3,108.69,68.03,110,61.44,110s-12.86-1.31-18.58-3.67 c-5.93-2.46-11.28-6.07-15.76-10.55c-4.48-4.48-8.09-9.82-10.55-15.76c-2.37-5.71-3.67-11.99-3.67-18.58 c0-6.59,1.31-12.86,3.67-18.58c2.46-5.93,6.07-11.28,10.55-15.76c4.48-4.48,9.83-8.09,15.76-10.55c5.71-2.37,11.99-3.67,18.58-3.67 C68.03,12.88,74.3,14.19,80.02,16.55L80.02,16.55z"/>
        //     </g>
        // </svg>
        // <svg 
        //     id="Layer_1" 
        //     dataname="Layer 1" 
        //     xmlns="http://www.w3.org/2000/svg" 
        //     viewBox="0 0 122.88 108.06"
        //     height='25px'
        //     width="25px"
        //     fill="#bf2100">
        //     <title>back-arrow</title>
        //     <path d="M63.94,24.28a14.28,14.28,0,0,0-20.36-20L4.1,44.42a14.27,14.27,0,0,0,0,20l38.69,39.35a14.27,14.27,0,0,0,20.35-20L48.06,68.41l60.66-.29a14.27,14.27,0,1,0-.23-28.54l-59.85.28,15.3-15.58Z"/>
        // </svg>
        <svg 
            width="30" 
            height="12" 
            viewBox="0 0 30 12" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
            id="prev arrow icon" 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M0.000162125 6L6.1756 0V4.5H29.3335V7.5H6.1756V12L0.000162125 6Z" 
            fill="#bf2100"/>
        </svg>

    )
}

export default ArrowPrevious;