import styled from 'styled-components';
import { device } from './homeStyledComponents';


export const SearchBarComponent=styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${props => props.spanFull ? "2em 0em" : "2em 1em"};
    @media ${device.laptop} {
        margin: ${props => props.spanFull ? "2em 0em" : "2em 10em"};
    }
    /* margin:2em 10em; */
`

export const SearchBarDiv=styled.div`
    display:flex;
    width:100%;
    justify-content: center;
    align-items: center;
    @media ${device.laptop} {
        width: 80%;
    }
`

export const StyledSelect=styled.select`
    background: #fff;
    flex-basis:40%;
    font-size: 1em;
    padding: 0.25em 0.75em;
    height: 3em;
    width:9rem;
    border: 1.6px solid #bf2100;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    @media ${device.laptop} {
        flex-basis:25%;
        font-size: 1em;
        padding: 0.25em 1em;
        height: 4em;
        width:5em;
    }
    &:focus {
    outline-width: 0px; 
  }
`

export const StyledInputDiv=styled.div`
    display: flex;
    flex-basis: 50%;
    border: 1.6px solid #bf2100;
    border-right: none;
    border-left: none;
    height: 3em;
    background:#fff;
    @media ${device.laptop} {
        flex-basis:60%;
        height: 4em;
  }
`

export const StyledInput=styled.input`
    flex-basis:98%;
    font-size: 1em;
    height: 2.7em;
    padding: 0.25em 0em 0.25em 1em;
    border: none;
    &:focus {
    outline-width: 0px;
    background: #fff;
    }
    @media ${device.laptop} {
        height: 3.8em;
        font-size: 1em;
        padding: 0.25em 1em;
    }
`

export const StyledClearButton = styled.button`
    background: #fff;
    align-items: center;
    color: #bf2100;
    font-size: 1.5em;
    border-radius:10em;
    border:none;
    padding-left: 0%;
    padding-right: 0%;
    @media ${device.laptop} {
        border-radius:30em;
        margin:0.2em;
        padding-left: 6px;
        padding-right: 6px;
  }
  &:focus {
    outline-width: 0px; // <Thing> when hovered
}
`

export const StyledSearchButton = styled.button`
    flex-basis: 10%;
    background: #fff;
    border: 1.6px solid #bf2100;
    border-left: none;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 3em;
    width:5em;
    padding: 0.25em 0.5em;
    &:focus {
        outline-width: 0px;
    }
    @media ${device.laptop} {
        // flex-basis:20%;
        height: 4em;
        // width:5em;
        // padding: 0.25em 0.25em;
  }
`


