import { useNavigate } from 'react-router-dom';
import '../sass/_emptyResult.scss'
import { StyledButton } from "./styledComponents/homeStyledComponents";
import NotFoundIcon from "./svgComponents/NotFoundIcon";

const NotFoundPage = () => {
    const navigate = useNavigate();
    return (
        <div className='notFoundPage container'>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center">
                        <NotFoundIcon height='25em' width='25em'/>
                    </div>
                    <div className="container">
                        <div className="row d-flex justify-content-center mt-4">
                            <span className="title mt-2">
                                Oops!
                            </span>
                        </div>
                        <div className="row d-flex flex-column justify-content-center my-2">
                            <div className="subtitle">
                                We are unable to find any page matching your request! 
                            </div>
                            <div className="subtitle mt-3">
                                Please go back
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mt-2">
                            <StyledButton 
                                className="btn my-4 py-2 px-4 emptyBtn" 
                                primary
                                onClick={()=>navigate(`/`)}>
                                Go Back to Home Page
                            </StyledButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage;