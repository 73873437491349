import React from "react";
function PrivacyPolicy() {
  return (
    <div
      className="site-layout-background"
      style={{ padding: 30, minHeight: 360 }}
    >
      <div name="privacyPolicy">
        <h2 style={{ textAlign: "center" }}>Privacy Policy</h2>
        <div>
          <p style={{ fontSize: "1rem" }}>
            Flamily.co.in Pty Ltd (Flamily.co.in) is committed to protecting
            your privacy. Flamily.co.in’s privacy policy is outlined below. This
            privacy policy is regularly reviewed and Flamily.co.in may amend it
            from time to time. While Flamily.co.in will endeavour to notify you
            of any major changes to this Privacy Policy from time to time, you
            agree that you will periodically review the most up-to-date version
            of this Privacy Policy available. This policy sets out how
            Flamily.co.in will collect, use, store, disclose and provide access
            to information about you and how you can keep that information
            accurate. By using the Website, you agree to be bound by and consent
            to this policy. In this Policy, defined terms have the meaning given
            to them in Flamily.co.in’s Terms and Conditions of Use:
            Flamily.co.in/terms.
          </p>
          <ol>
            <li>
              Collection<br></br>
              Flamily.co.in needs to collect personal information about you
              primarily to provide you with the Services, with access to the
              Website, and with other services related to its business. This
              personal information may include, but is not limited to, your
              name, telephone number, street address, email address and credit
              card details.
            </li>
            <li>
              Use and disclosure
              <ol type="a">
                <li>
                  In addition to the primary purpose for which Flamily.co.in
                  collects and uses your personal information, Flamily.co.in,
                  its related bodies corporate and associated entities (as those
                  terms are defined in the Corporations Act 2001 (Cth)) (Other
                  Entities) and its nominated agents may also use the personal
                  information Flamily.co.in collects, and you consent to their
                  use of that personal information:
                  <ol type="i">
                    <li>
                      for purposes necessary or incidental to the provision of
                      goods and services to you including, without limitation,
                      for marketing or promotional purposes;
                    </li>
                    <li>to manage and enhance the Services;</li>
                    <li>
                      to communicate with you, including by email, mail or
                      telephone;
                    </li>
                    <li>
                      to personalise and customise your services, experience,
                      advertising and content that you view and engage with on
                      the Website, the Other Entities’ websites or the websites
                      of our service providers and business partners;
                    </li>
                    <li>
                      to verify your identity on the Website and the Other
                      Entities’ websites;
                    </li>
                    <li>
                      to investigate you and your use of the Website and the
                      Services if Flamily.co.in has reason to suspect that you
                      are in breach of these Terms and Conditions of Use of or
                      have otherwise engaged in unlawful activity; and/or
                    </li>
                    <li>as required or permitted by any law.</li>
                  </ol>
                </li>
                <li>
                  Flamily.co.in may disclose your personal information, and you
                  consent to Flamily.co.in disclosing your personal information,
                  to Flamily.co.in’s related bodies corporate and associated
                  entities (as those terms are defined in the Corporations Act
                  2001 (Cth)) (Other Entities) whereby your personal information
                  will be collected, used, disclosed, managed and stored in
                  accordance with this policy.
                  <ol type="i">
                    <li>
                      Flamily.co.in engages to perform functions or provide
                      goods or services on its behalf;
                    </li>
                    <li>
                      that are Flamily.co.in’s agents, business partners or
                      joint venture entities;
                    </li>
                    <li>
                      authorised by you to receive information held by
                      Flamily.co.in;
                    </li>
                    <li>
                      as part of any investigation into you, your activities or
                      the activities of another member, for example, if
                      Flamily.co.in has reason to suspect that you have
                      committed a breach of these Terms and Conditions of Use or
                      have otherwise engaged in unlawful activity, and
                      Flamily.co.in reasonably believes that disclosure is
                      necessary to police, any relevant authority or enforcement
                      body, or your internet service provider or network
                      administrator;
                    </li>
                    <li>
                      as part of a sale (or proposed sale) of all or part of
                      Flamily.co.in’s business or the preparation for a listing
                      of Flamily.co.in; and/or
                    </li>
                    <li>as required or permitted by any law.</li>
                  </ol>
                </li>
                <li>
                  Users of the Website other than yourself cannot access your
                  personal information, such as your address, email address or
                  telephone number, unless you give them access to that
                  information.
                </li>
                <li>
                  Flamily.co.in will not disclose to any user other than the
                  intended recipient the contents of any messages sent through
                  the messaging service available on the Website.
                </li>
                <li>
                  Flamily.co.in will not disclose to any user the content or
                  source of any complaint made against, or feedback received,
                  about that user (or any other user), including the nature,
                  substance, or origin of that complaint or feedback.
                </li>
              </ol>
            </li>
            <li>
              Storage<br></br>
              Flamily.co.in will take all reasonable steps to protect the
              personal information it holds about you from misuse, loss, or
              unauthorised access. You acknowledge that the security of online
              transactions you conduct using this website cannot be guaranteed.
              Flamily.co.in does not accept responsibility for misuse of, or
              loss of, or unauthorised access to, your personal information
              where the security of that information is not within
              Flamily.co.in’s control.
            </li>
            <li>
              Overseas disclosure
              <ol type="a">
                <li>
                  In some circumstances, Flamily.co.in may need to disclose your
                  personal information to entities located overseas where
                  Flamily.co.in may not be in a position to know that those
                  entities comply with Australian privacy laws or that the laws
                  of the country in which that entity operates (if any) are
                  substantially similar to Australian privacy laws. When
                  necessary, Flamily.co.in will inform you that such disclosure
                  may be necessary and will seek your express consent to
                  disclose your personal information to such overseas entities.
                </li>
                <li>
                  If you request Upgraded Services from Flamily.co.in, it will
                  be necessary for Flamily.co.in to collect your credit card
                  details and disclose those details to Stripe or Braintree who
                  may or may not be obliged to comply with the Australian
                  Privacy Principles under the Privacy Act 1988 (Cth). By
                  requesting Upgraded Services, you hereby acknowledge and
                  accept these matters and expressly consent to Flamily.co.in
                  disclosing your personal information to Stripe or Braintree
                  (wherever located) with full knowledge that it may not be
                  obliged to comply with the Australian Privacy Principles.
                  Flamily.co.in will not be accountable to you in respect of the
                  use or disclosure by that overseas entity of your personal
                  information and you will not be able to seek redress under the
                  Privacy Act in respect of such use or disclosure. You also
                  expressly acknowledge and accept that you may not be able to
                  seek redress under the laws of the country in which Stripe or
                  Braintree is located or in the country or place in which that
                  personal information is subsequently or otherwise disclosed or
                  accessed. You may withdraw your consent to such disclosure at
                  any time by providing not less than 7 days’ prior written
                  notice to Flamily.co.in using the contact details provided
                  below. If you do not consent to such disclosure, or you do
                  consent but later withdraw that consent, you will not be able
                  to use the Upgraded Services or, if you have already started
                  using the Upgraded Services at the time you withdraw your
                  consent, you will not be able to continue to use those
                  Upgraded Services. You acknowledge and agree that you will not
                  be entitled to any refund in respect of the Upgraded Services
                  if you withdraw your consent.
                </li>
              </ol>
            </li>
            <li>
              Cookies<br></br>
              Flamily.co.in may use cookies on the Website to provide you with a
              better experience and so that it can gather statistical
              information about user visits to the website. Cookies are small
              data files that are downloaded onto your computer when you visit a
              particular website. Cookies help provide added functionality to
              the Website or help Flamily.co.in analyse Website usage. You can
              disable cookies by turning them off in your browser, however, some
              areas of the website may not function properly if you do so.
            </li>
            <li>
              Access<br></br>
              You have the right to seek access to and update the personal
              information which Flamily.co.in holds about you. You can seek
              access to and update your personal information by contacting
              Flamily.co.in directly at support@Flamily.co.in.
            </li>
            <li>
              Website tracking<br></br>
              Flamily.co.in (including its related bodies corporate) collects
              data about your browsing activity on the Website, such as the
              search terms you have used and other details of the way you use
              the Website, in order to provide suggested search results to you
              and, once aggregated with other users’ data, other users based on
              your and other users’ searches on the Website. This data does not
              include your personal information. However, this data may be
              associated with your personal information if you have an Account
              or if you sign-up for an Account in the future. The collection,
              use and disclosure, storage of, and access to, that data (if it is
              associated with your personal information) will be in accordance
              with this Privacy Policy. Flamily.co.in will not provide that data
              to any third parties in a way which will enable them to personally
              identify you.
            </li>
            <li>
              Subscriptions<br></br>
              If you wish to stop receiving emails or other communications from
              Flamily.co.in which may be sent to you in the future, please
              notify Flamily.co.in by using the contact details given below, or
              by clicking the unsubscribe link at the bottom of any email
              newsletter you have received from Flamily.co.in.
            </li>
            <li>
              Contact Details<br></br>
              Flamily.co.in Team<br></br>
              support@Flamily.co.in
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
