import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import AllAds from "./AllAds";
import { getAllAds, getAllAdsForFlatRequirement, setAllAds, setAllAdsForFlatRequirements, setLimit, setOffset, setSelfAdLimit, setSelfAdOffset } from "../actions";
import { connect } from "react-redux";
import SelfAds from "./SelfAds";
import SearchBar from "./SearchBar";
import CardsSkeleton from "./CardsSkeleton";

const ShowMore = ({
        getAds,
        setInitialLimit, 
        setInitialOffset, 
        flatSearch, 
        paginationReducer, 
        resetInitialAds,
        setInitialSelfAdLimit,
        setInitialSelfAdOffset,
        resetInitialAdsForFlatRequirement,
        getAdsForFlatRequirements
    }) => {

    const {adsFor} = useParams()
    const {countForFlatAd, countForSelfAd} = paginationReducer
    const [pageToView, setPageToView] = useState(adsFor);
    const [initialLoad, setInitialLoad] = useState(true)
    const [adsToDisplay, setAdsToDisplay] = useState([])
    const [totalCount, setTotalCount] = useState(0);
    // const [hasMore, setHasMore] = useState(true)

    
    // 1. Empty the old Data
    // 2. Initialize offsets and limits
    // 3. fetch new data
    // 4. on load more get new data and append it to old data

    useEffect(()=>{
        window.scrollTo(0, 0)
        switch(pageToView){
            case 'flats':
                resetInitialAds()
                setInitialLimit()
                setInitialOffset()
                getAds()
                break;

            case 'flatmates':
                resetInitialAdsForFlatRequirement()
                setInitialSelfAdLimit()
                setInitialSelfAdOffset()
                getAdsForFlatRequirements()
                break;

            default:
                break;
        }
    }, [])

    useEffect(()=>{
        if(initialLoad){
            setAdsToDisplay([])
            setInitialLoad(false)
        } else {
            setAdsToDisplay([...adsToDisplay, ...flatSearch.ads])
        }
    }, [flatSearch.ads])

    useEffect(()=>{
        if(initialLoad){
            setAdsToDisplay([])
            setInitialLoad(false)
        } else {
            setAdsToDisplay([...adsToDisplay, ...flatSearch.adsForFlatRequirement])
        }
    },[flatSearch.adsForFlatRequirement])

    const loadMoreData = () => {
        switch(pageToView){
            case 'flats':
                getAds()
                break;
            case 'flatmates':
                getAdsForFlatRequirements()
                break;
            default:
                break;
        }
    }
    
    return (
        <>
            <InfiniteScroll
                dataLength={adsToDisplay.length} //This is important field to render the next data
                next={loadMoreData}
                hasMore={pageToView === 'flats' ? adsToDisplay.length < countForFlatAd : adsToDisplay.length < countForSelfAd}
                loader={<CardsSkeleton/>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                    </p>
                }
                // hasChildren
            >
                <div className="container my-4">
                    <div className="row mb-5">
                        <div className="col-md-8 mb-md-3">
                            <SearchBar spanFull search={pageToView}></SearchBar>
                        </div>
                    </div>
                    {pageToView === 'flats' &&
                        <AllAds dataToView={adsToDisplay}></AllAds>
                    }
                    {
                        pageToView === 'flatmates' &&
                        <SelfAds dataToView={adsToDisplay}/>
                    }
                </div>
            </InfiniteScroll>
        </>
    )
}
const mapStateToProps = state => {
	const {flatSearch, paginationReducer} = state
	return {flatSearch, paginationReducer}
}

const mapDispatchToProps = dispatch => {
    return {
        getAds: _ =>dispatch(getAllAds()),
        resetInitialAds: _ => dispatch(setAllAds([])),
        setInitialLimit: _ => dispatch(setLimit(12)),
        setInitialOffset: _ => dispatch(setOffset(0)),
        getAdsForFlatRequirements : _ => dispatch(getAllAdsForFlatRequirement()),
        resetInitialAdsForFlatRequirement: _ => dispatch(setAllAdsForFlatRequirements([])),
        setInitialSelfAdLimit: _ => dispatch(setSelfAdLimit(12)),
        setInitialSelfAdOffset: _ => dispatch(setSelfAdOffset(0)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ShowMore);