import { GET_USER, UPDATE_USER, LOGGED_OUT, SET_LOGGED_IN_USER, UPDATE_MULTIPLE} from "../actions";

const userData = {
    // JSON.parse(sessionStorage.getItem("user"))
    logged_user: '',
    // sessionStorage.getItem("fullName"),
    logged_fullName: '',
    dialog_visible: false,
    logged_out: false,
    loginForm_visible: false,
    registerForm_visible: false,
    otpForm_visible: false,
    loginError: false,
    registerError: false,
    errorMessage: ''
  };

export default function userAuth(state = userData, action) {
    var {type, valueToStore, dataToStore, fieldsToUpdate}=action
    switch (type) {
        case GET_USER:
            return {...state}
        
        case UPDATE_USER:
            return{
                ...state,
                dialog_visible: false,
                logged_out: false,
                loginForm_visible: false,
                registerForm_visible: false,
                otpForm_visible: false,
                loginError: false,
                registerError: false,
                errorMessage: '',
                ...valueToStore   
            }

        case UPDATE_MULTIPLE:
            return{
                ...state,
                ...fieldsToUpdate
            }
        case LOGGED_OUT:
            return{
                ...state,
                dialog_visible: false,
                logged_out: true,
                loginForm_visible: false,
                registerForm_visible: false,
                otpForm_visible: false,
                loginError: false,
                registerError: false,
                errorMessage: '',
            }

        case SET_LOGGED_IN_USER:
            return{
                ...state,
                ...dataToStore
            }

        default:
            return {...state,}
    }
  }