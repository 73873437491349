import { Skeleton } from "antd"

const CardsSkeleton = () => {

    const loadCardSkeleton = (id) => {
        return (
            <div className="col col-md-4 col-sm-12 d-flex" key={`card-${id}`} id={id}>
                <div className="card mb-5 mx-0 adsCard w-100" key={id} id={id}>
                    <Skeleton.Image active={true} className="adsImg w-100"/>
                    <div className="card-body" key={`card-${id}}`} id={1}>
                        <h5 className="card-title" key={`card-title${id}`} id={1}>
                            <Skeleton active />
                        </h5>
                    </div>
                </div>
            </div>
        )
    }

    const skeletonCards = [1,2,3,4,5,6]
        return (
            <div className='container' style={{minHeight:'50px'}}>
                <div className="row">
                    <div className="card-deck w-100">
                        {
                            skeletonCards.map(loadCardSkeleton)
                        }
                    </div>
                </div>
            </div>
        )
}

export default CardsSkeleton;