const ParkingIcon = ({height, width, fill}) => {
    return (
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        xmlSpace="preserve" 
        height={height ? height : '1em'}
        width={width ? width : '1em'}
        fill={fill ? fill : "#bf2100"}
        shapeRendering="geometricPrecision" 
        textRendering="geometricPrecision" 
        imageRendering="optimizeQuality" 
        fillRule="evenodd" 
        clipRule="evenodd" 
        viewBox="0 0 512 444.66">
            <path d="m472.72 416.5-36.95-108.46c-1.61-4.74-4.32-9.19-9.18-9.19h-20.68l13.86-28.15h6.82c11.58 0 20.32 4.92 26.92 12.49 4.29 4.91 7.05 10.47 8.88 15.83L512 444.66H0l44.38-144.87c2.03-6.49 5.46-13.25 10.69-18.48h.11c6.47-6.44 14.74-10.61 25.22-10.61h13.74l15.09 28.15H80.4c-4.87 0-7.77 4.57-9.18 9.19L37.98 416.5h434.74zm-195.03-33.51a31.082 31.082 0 0 1-17.82 5.72c-5.76.01-11.54-1.55-16.63-4.78l-.08-.09c-15.89-10.11-30.56-21.25-43.86-33.11-13.77-12.2-26.17-25.35-37.07-39.07-14.75-18.49-26.83-38.07-36.07-57.99-9.43-20.39-15.92-41.33-19.24-62.03-3.47-21.51-3.53-42.6-.01-62.49 3.54-19.95 10.62-38.79 21.41-55.76 4.34-6.74 9.35-13.34 15.03-19.63 5.39-6.09 11.48-11.83 18.15-17.13 15.02-11.96 31.22-21.16 47.96-27.33 16.96-6.26 34.62-9.42 52.32-9.3 17.63.13 35.04 3.5 51.62 10.25 16.04 6.65 31.33 16.23 45.27 28.95 4.91 4.45 9.6 9.43 13.99 14.79 4.47 5.43 8.43 11.04 11.91 16.75 11.48 18.99 18.55 40.3 21.5 62.78 3.01 22.78 1.83 46.88-3.15 70.99-7.51 36.48-23.21 71.7-44.95 102.49-21.4 30.31-48.86 56.64-80.28 75.99zm42.87-236.75c0 8.7-2.01 16.59-6.03 23.67-4.04 7.07-9.98 12.69-17.83 16.84-7.85 4.16-17.37 6.23-28.53 6.23h-20.12v37.89h-40.27V98.9h60.39c16.88 0 29.83 4.29 38.85 12.86 9.03 8.57 13.54 20.06 13.54 34.48zm-54.3 15.63c8.96 0 13.44-7.19 13.44-15.63 0-8.45-4.48-15.64-13.44-15.64h-18.21v31.27h18.21zm-2.11 198.98c-2.46 1.8-5.8 2.04-8.53.29-29.52-18.79-54.35-41.36-73.82-65.85-26.89-33.74-43.84-71.15-49.63-107.18-5.9-36.53-.39-71.66 17.79-100.24 7.17-11.3 16.32-21.59 27.48-30.46 25.66-20.46 54.97-31.24 84.13-31.03 28.12.2 55.86 10.7 79.83 32.57 8.43 7.65 15.52 16.43 21.31 25.95 19.55 32.19 23.76 73.25 15.15 114.87-13.4 65.2-56.46 126.33-113.71 161.08z"/>
        </svg>
    )
}

export default ParkingIcon;