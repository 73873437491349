import { CREATE_POST, GET_POST, SET_POST, SET_RESPONSE, UPDATE_PROFILE } from "../actions";

const adToPost = {
    postData:{},
    response:{}
  };

export default function createPost(state = adToPost, action) {
    var {type}=action
    const {postData, response}=action
    switch (type) {
        case CREATE_POST:
            return {...state, response, postData }

        case SET_POST:
            return { ...state, response, postData}

        case SET_RESPONSE:
            return { ...state, response}
    
        case GET_POST:
            return { ...state}
        
        // case UPDATE_PROFILE:
        //     return {...state, response}
        
        default:
            return {...state,}
    }
}