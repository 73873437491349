import React, { Component } from 'react'
import { Divider } from 'antd'
// import { FaInstagram, FaFacebookSquare, FaLinkedin } from 'react-icons/fa'
import FbIcon from './svgComponents/FbIcon'
import { Link } from 'react-router-dom'
import InstaIcon from './svgComponents/InstaIcon'

class BottomNavbar extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>

                <footer className="container-fluid" style={{ background: "#343a40" }}>
                    <div className="row text-center" style={{ color: "white" }}>
                        <div className="col-md-4">
                            <Divider style={{borderColor:"white"}} />
                            <h5 style={{ color: "white" }}>Flamily</h5>
                            <Divider style={{borderColor:"white"}} />
                            <Link className="nav-link" style={{ color: "white", textAlign:'left', marginLeft:'2rem', fontSize:'1rem' }}
                             to='/about-us'>About Us</Link>
                            <Link className="nav-link" style={{ color: "white", textAlign:'left', marginLeft:'2rem', fontSize:'1rem' }}
                             to='/terms-and-condition'>Terms and Conditions</Link>
                            <Link className="nav-link" style={{ color: "white", textAlign:'left', marginLeft:'2rem', fontSize:'1rem' }}
                             to={{pathname:"/privacy-policy", docId:'3'}}>Privacy Policy</Link>
                        </div>

                        <div className="col-md-4">
                            
                            <Divider style={{borderColor:"white"}} />
                            <h5 style={{ color: "white" }}>Our hours</h5>
                            
                            <Divider style={{borderColor:"white"}} />
                            <p>24 * 7</p>
                        </div>

                        <div className="col-md-4">
                           
                            <Divider style={{borderColor:"white"}} />
                            <h5 style={{ color: "white" }}>Find Us At</h5>
                            
                            <Divider style={{borderColor:"white"}} />
                          
                            <p><InstaIcon></InstaIcon>&nbsp;Instagram</p>
                            <p><FbIcon></FbIcon>&nbsp;Facebook</p>
                        </div>

                        <div className="col-12" style={{padding:'10px'}}>
                            <Divider style={{borderColor:"white"}} />
                            <h5 style={{ color: "white" }}>&copy; 2020 Flamily Technologies Solutions</h5>
                            <h5 style={{ color: "white" }}>flamily.org@gmail.com</h5>
                        </div>

                    </div>
                </footer>

            </React.Fragment>
        )
    }
}

export default BottomNavbar