import React, { Component } from 'react';
import { Divider, Layout, Card} from 'antd';
import { Navigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import SearchBar from './SearchBar';
import { CardContainer, StyledDivider, StyledPrimaryActionButton } from './styledComponents/homeStyledComponents';
import postAdpic from '../assets/double-bed.svg';
import teamspic from '../assets/teamwork.svg';
import AllAds from './AllAds';
import Login from './Login';
import { connect } from 'react-redux';
import Register from './Register';
import { getAllAds, getAllAdsForFlatRequirement, setLimit, setLoggedInUser, setOffset, updateUserData } from '../actions';
import SelfAds from './SelfAds';

const { Meta } = Card;

class Home extends Component {
	constructor() {
		super();
		this.state={
			navigateToPostAd:false,
			navigateToPostAdForSelf: false
		}
	}

	componentDidMount(){
		const {storeUserOnRelaod, getAds, loadAllAdsForFlatRequirement,setInitialLimit,
			setInitialOffset} = this.props
		setInitialLimit();
		setInitialOffset();
		getAds();
		loadAllAdsForFlatRequirement();
		storeUserOnRelaod(JSON.parse(sessionStorage.getItem("user")))
	}

	checkLogin = () => {
		const {logged_user} = this.props;
		return logged_user ? true : false
	}

	postAd=()=>{
		const { updateData } = this.props;
		if(this.checkLogin()){
			this.setState({navigateToPostAd:true})
		}
		updateData('loginForm_visible', true)
	}

	postAdForSelf=()=>{
		const { updateData } = this.props;
		if(this.checkLogin()){
			this.setState({navigateToPostAdForSelf:true})
		}
		updateData('loginForm_visible', true)
	}

	hideModal = (e) => {
		const { updateData } = this.props;
		updateData('loginForm_visible',false)
		updateData('registerForm_visible',false)
	}

	render() {
		const {ads=[], adsForFlatRequirement=[]} = this.props
		const {loginForm_visible, registerForm_visible} = this.props
		if(this.state.navigateToPostAd){
			// replace={true}
			return <Navigate to="/postad"></Navigate>
		}
		if(this.state.navigateToPostAdForSelf){
			// replace={true}
			return <Navigate to="/postad-for-self"></Navigate>
		}
		return (
			<>
                <>
				{/*  show={this.state.visibility.loginForm_visible} onHide={this.hideModal} */}
				<Modal style={{ paddingTop: 80 }} show={loginForm_visible} onHide={this.hideModal} size="md">
				{/* <Modal.Header closeButton/> */}
				{/* <Modal.Header>
				<Modal.Title>Login Here</Modal.Title>
				</Modal.Header> */}

					<Modal.Body>
						<Login></Login>
					</Modal.Body>
				{/* 
				<Modal.Footer>
				<Button variant="secondary">Close</Button>
				<Button variant="primary">Save changes</Button>
				</Modal.Footer> */}
				</Modal>
				<Modal className='registerSection' show={registerForm_visible} onHide={this.hideModal} size="lg">
					<Modal.Body>
						<Register></Register>
					</Modal.Body>
				</Modal>
                <CardContainer>
                    <SearchBar search='flats'></SearchBar>
                    {/* <Divider style={{ background: '#bf2100' }} /> */}
					<div className="container my-5 py-4">
						<div className="row m-auto">
							<div className="col-12 col-md-6 col-sm-12 col-xm-12">
								<Card 
								style={{ borderRadius: '1em', marginBottom:'1em' }} 
								hoverable 
								className='shadow'
								bordered
								onClick={this.postAdForSelf}>
									<Meta 	
										avatar={
											<img
												src={postAdpic}
												style={{ width: '7rem', height: '7rem' }}
												alt="Post Your free Ad with Flamily"
											/>
										}
                                        title={
											<h5 className="mt-md-3">
												<strong>Need a room</strong>
											</h5>
										}
                                        description={<h6>Discover Your Perfect Place, create a free post on Flamily</h6>}
									/>
								</Card>
							</div>

							<div className="col-12 col-md-6 col-sm-12 col-xm-12">
								<Card 
								style={{ borderRadius: '1em', marginBottom:'1em' }} 
								hoverable 
								className='shadow'
								bordered
								onClick={this.postAd}>
									<Meta
                                        avatar={
											<img
												src={teamspic}
												style={{ width: '7rem', height: '7rem' }}
												alt="find flatmate with flamily"
											/>
										}
										title={ 
											<h5 className="mt-md-3">
												<strong>Need flatmate</strong>
											</h5>
										}
                                        description={<h6>Find Your Perfect Flatmate, create a free post on Flamily</h6>}
									/>
								</Card>
							</div>
							
						</div>
					</div>
					<div className="row my-2 mx-0 px-0">
						<div className="col-md-1 col-3 px-0">
							<StyledDivider/>
						</div>
						<div className="col-md-3 col-6 d-flex align-items-center justify-content-center">
							<h6 className="brandColor mb-0"><b>Flats Available</b></h6>
						</div>
						<div className="col-md-8 col-3 px-0">
							<StyledDivider/>
						</div>
                	</div>
					<AllAds dataToView={ads} pageFrom={'home'}/>
					<div className="row my-2 mx-0">
						<div className="col-md-1 col-3 px-0">
							<StyledDivider/>
						</div>
						<div className="col-md-3 col-6 d-flex align-items-center justify-content-center">
							<h6 className="brandColor mb-0"><b>Looking for flats</b></h6>
						</div>
						<div className="col-md-8 col-3 px-0">
							<StyledDivider/>
						</div>
                	</div>
					<SelfAds dataToView={adsForFlatRequirement} pageFrom={'home'}/>
					{/* <div className='col-12 col-md-3 offset-md-8'>
						<StyledPrimaryActionButton
							primary
							type="submit"
							className="col btn"
							onClick={this.login}>
								Show more
						</StyledPrimaryActionButton>
					</div>
					<Divider style={{ background: '#bf2100', marginLeft:'2em', marginRight:'2em' }} /> */}
				</CardContainer>  
                </>
            </>
		);
	}
}
// GET_USER
function mapStateToProps(state) {
	const {userAuth, flatSearch}=state
	return {...userAuth,...flatSearch}
  }
  
const mapDispatchToProps = dispatch => {
	return {
		getAds:_=>dispatch(getAllAds()),
		loadAllAdsForFlatRequirement:_=>dispatch(getAllAdsForFlatRequirement()),
		updateData: (field,value) => dispatch(updateUserData(field,value)),
		storeUserOnRelaod: (storeDate) => dispatch(setLoggedInUser(storeDate)),
		setInitialLimit: _ => dispatch(setLimit(8)),
        setInitialOffset: _ => dispatch(setOffset(0))
	}
}
  
export default connect(mapStateToProps,mapDispatchToProps)(Home);
// export default connect(mapStateToProps)(Home);
