import { serialize } from 'object-to-formdata'

export const UpdateUserProfile=(formValue)=>{
    const userProfile = {
        // user:{
            // profile: {
                age:formValue.ageToUpdate,
                drinks:formValue.drinksToUpdate,
                smokes:formValue.smokesToUpdate,
                havePets:formValue.havePetsToUpdate, 
                foodType:formValue.foodTypeToUpdate,
                gender:formValue.genderToUpdate,
                id:formValue.id,
                image:formValue.imageToUpdate,
                mobileNumber:formValue.numberToUpdate,
                nativePlace:formValue.nativePlaceToUpdate,
                nativeState:formValue.nativeStateToUpdate,
                nativeStateIsoCode:formValue.nativeStateIsoCodeToUpdate,
                worksAt:formValue.worksAtToUpdate,
                aboutMe: formValue.aboutMeToUpdate
            // }
        // }
    }
    const formData=serialize(userProfile)
    return formData;
}