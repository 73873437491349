import styled from 'styled-components';


export const StyledFilter=styled.button`
    /* Adapt the colors based on primary prop */
    background: #fff;
    color: #000;
    font-weight: 700;
    font-size: 12px;
    font-family: Helvetica;
    border: 2px solid #bf2100;
    border-radius: 25px;
    padding: 0px 20px;
    margin: 0 8px 5px;
    text-align: center;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    height:44px;
    &:focus {
    outline-width: 0px; 
  }
`

export const StyledBtnBorder = styled.button`
  background: #fff;
    color: #000;
    font-weight: 700;
    font-size: 12px;
    font-family: Helvetica;
    border: none;
    position: relative;
    padding: 0px 20px;
    margin: 0 8px 5px;
    text-align: center;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    height:44px;
    &:focus {
    outline-width: 0px; 
  }
`