import React, {Component} from 'react'
import debounce from 'lodash/debounce';
import { Button, Divider, Mentions, Result, Steps } from 'antd'
import { Link} from 'react-router-dom';
import { StyledIconContainer,
    StyledImageNameInPreviewList, 
    StyledImagePreviewContainer, 
    StyledImagePreviewList, 
    StyledLabel, 
    StyledPrimaryActionButton, 
    StyledStep, 
    StyledStepContainer, 
    StyledStepDivider, 
    StyledTrashIconContainer,
    StyledResultButton,
    StyledLink} from './styledComponents/homeStyledComponents';
import ApartmentsIcon from './svgComponents/ApartmentsIcon';
import HomeAddress from './svgComponents/HomeAddress';
import RentIcon from './svgComponents/RentIcon';
import CameraIcon from './svgComponents/CameraIcon';
import PeopleIcon from './svgComponents/PeopleIcon';
import AmenitiesIcon from './svgComponents/AmenitiesIcon';
import UploadIcon from './svgComponents/UploadIcon';
import { connect } from 'react-redux';
import { createPost, getPost } from '../actions';
import { createPostData } from './models/Post';
import TrashIcon from './svgComponents/TrashIcon';
import LiftIcon from './svgComponents/LiftIcon';
import AcIcon from './svgComponents/AcIcon';
import IntercomIcon from './svgComponents/IntercomIcon';
import GasIcon from './svgComponents/GasIcon';
import WifiIcon from './svgComponents/WifiIcon';
import SwimmingpoolIcon from './svgComponents/SwimmingpoolIcon';
import GeyserIcon from './svgComponents/GeyserIcon';
import MaidIcon from './svgComponents/MaidIcon';
import CookIcon from './svgComponents/CookIcon';
import SuccessIcon from './svgComponents/SuccessIcon';
import ErrorIcon from './svgComponents/ErrorIcon';

const { Option } = Mentions;
const { Step } = Steps;
class PostAd extends Component{
    constructor(props) {
        super(props)
        this.loadGithubUsers = debounce(this.loadGithubUsers, 800);
        this.submitRegistration = debounce(this.submitRegistration, 500);
        this.state = {
            current:0,
            search: '',
            loading: false,
            users: [],
            progress: {
                propertyDetailsUpdated: false,
                rentalDetailsUpdated: false,
                localityDetailsUpdated: false,
                amenitiesUpdated: false,
                flatmatesInfoUpdated: false,
                gallaryUpdated:false
            },
            formValue: {
                lift: false,
                ac: false,
                intercom: false,
                gasPipeline: false,
                wifi: false,
                waterPurifier: false,
                swimmingPool: false,
                park: false,
                geyser: false,
                visitorparking: false,
                maid: false,
                cook: false,
                numberOfPeopleRequired: "",
                numberOfPeople: "",
                listOfPeople: [],
                nonVegAllowed: "",
                gatedSecurity: "",
                waterSupply: "",
                gym: "",
                bathroom: "",
                balcony: "",
                description: "",
                parking: "",
                availableFrom: "",
                furnishing: "",
                maintainance: "",
                amount: "",
                rent: "",
                deposit: "",
                pincode: "",
                state: "",
                landmark: "",
                city: "",
                area: "",
                buildingName: "",
                roomType: "",
                gender: "",
                numberOfFloor: "",
                totalFloors: "",
                bhk: "",
                propertyType: "",
                images: []
            },
            formErrorMessage: {
                numberOfPeopleRequiredErrorMessage: "",
                listOfPeopleErrorMessage: "",
                numberOfPeopleErrorMessage: "",
                nonVegAllowedErrorMessage: "",
                gatedSecurityErrorMessage: "",
                waterSupplyErrorMessage: "",
                gymErrorMessage: "",
                bathroomErrorMessage: "",
                balconyErrorMessage: "",
                descriptionErrorMessage: "",
                parkingErrorMessage: "",
                availableFromErrorMessage: "",
                furnishingErrorMessage: "",
                maintainanceErrorMessage: "",
                amountErrorMessage: "",
                rentErrorMessage: "",
                depositErrorMessage: "",
                pincodeErrorMessage: "",
                stateErrorMessage: "",
                landmarkErrorMessage: "",
                cityErrorMessage: "",
                areaErrorMessage: "",
                buildingNameErrorMessage: "",
                roomTypeErrorMessage: "",
                genderErrorMessage: "",
                numberOfFloorErrorMessage: "",
                totalFloorsErrorMessage: "",
                bhkErrorMessage: "",
                propertyTypeErrorMessage: ""
            },
            formValid: {
                propertyDetailsButtonActive: false,
                localityDetailsButtonActive: false,
                rentalDetailsButtonActive: false,
                amenitiesButtonActive: false,
                flatmatesInfoButtonActive: false,
                gallaryButtonActive:true,
                nonVegAllowedValid: false,
                gatedSecurityValid: false,
                waterSupplyValid: false,
                gymValid: false,
                bathroomValid: false,
                balconyValid: false,
                descriptionValid: true,
                parkingValid: false,
                availableFromValid: false,
                furnishingValid: false,
                maintainanceValid: false,
                amountValid: false,
                rentValid: false,
                depositValid: false,
                pincodeValid: false,
                stateValid: false,
                landmarkValid: true,
                cityValid: false,
                areaValid: false,
                buildingNameValid: false,
                roomTypeValid: false,
                genderValid: false,
                numberOfFloorValid: false,
                totalFloorsValid: false,
                bhkValid: false,
                propertyTypeValid: false,
                numberOfPeopleValid: false,
                listOfPeopleValid: true,
                numberOfPeopleRequiredValid: false
            },
            formStepsVisibility: {
                current:0,
                propertyDetailsVisible:true,
                localityDetailsVisible:false,
                rentalDetailsVisible:false,
                galleryVisible:false,
                amenitiesVisible:false,
                peopleVisible:false
            }

        }

    }
    
    handleChange = event => {
        const { formValue } = this.state
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const fieldName = target.name;
        this.validateField(fieldName, value)
        this.setState({ formValue: { ...formValue, [fieldName]: value } })
    }

    handleImageChange = event => {
        const files = event.target.files        
        const { formValue } = this.state
        const { images }=formValue;
        for (let i = 0; i < files.length; i++) {
            images.push(files[i])
        }
        this.setState({ formValue: { ...formValue, images } })
    }

    deleteImage = event =>{
        const { formValue }=this.state;
        const { images }=formValue;
        const id=event.target.id;
        event.preventDefault();
        images.splice(id, 1);
        this.setState({ formValue: { ...formValue, images } })
    }

    loadUploadedImages = _ => {
        let fileList = this.state.formValue.images
        let imageArray = []
        for (let i = 0; i < fileList.length; i++) {
            imageArray.push(
                <StyledImagePreviewContainer key={i}>
                    <StyledImagePreviewList src={URL.createObjectURL(fileList[i])} alt={fileList[i].name}/>
                        <StyledImageNameInPreviewList className="text-truncate mr-0">
                            {fileList[i].name}
                        </StyledImageNameInPreviewList>
                        <StyledTrashIconContainer id={i} onClick={this.deleteImage}>
                            <TrashIcon id={i}/>
                        </StyledTrashIconContainer>
                </StyledImagePreviewContainer>
                )
        }
        return imageArray.map(_ => _)
    }

    updateProgressPercent = (event) => {
        let formName = event.target.name
        const { formStepsVisibility, progress } = this.state
        let { current,
            propertyDetailsVisible,
            localityDetailsVisible,
            rentalDetailsVisible,
            galleryVisible,
            amenitiesVisible,
            peopleVisible
        } = formStepsVisibility

        const tempData={
            propertyDetailsVisible:false,
            localityDetailsVisible:false,
            rentalDetailsVisible:false,
            galleryVisible:false,
            amenitiesVisible:false,
            peopleVisible:false
        }
        // let percent = progress.progressPercent
        switch (formName) {
            case 'propertyDetails':
                if (propertyDetailsVisible) {
                    current=1;
                    this.setState({
                        formStepsVisibility:{...tempData,current,localityDetailsVisible:true},
                        progress:{...progress, propertyDetailsUpdated:true}});
                }
                break

            case 'localityDetails':
                if (localityDetailsVisible ) {
                    current=2;
                this.setState({
                    formStepsVisibility:{...tempData,current,rentalDetailsVisible:true},
                    progress:{...progress, localityDetailsUpdated:true}});
                }
                break
            case 'rentalDetails':
                if (rentalDetailsVisible) {
                    current=3;
                    this.setState({
                        formStepsVisibility:{...tempData,current,amenitiesVisible:true},
                        progress:{...progress, rentalDetailsUpdated:true}});
                }
                
                break
            
            case 'amenities':
                if (amenitiesVisible) {
                    current= 4
                    this.setState({
                        formStepsVisibility:{...tempData,current,peopleVisible:true},
                        progress:{...progress, amenitiesUpdated:true}});
                }
               
                break
            case 'flatmatesInfo':
                if (peopleVisible) {
                    current = 5
                this.setState({
                    formStepsVisibility:{...tempData,current,galleryVisible:true},
                    progress:{...progress, flatmatesInfoUpdated:true}});
                }
                
                break
            case 'gallery':
                if (galleryVisible) {
                    current = 5
                    this.setState({
                        formStepsVisibility:{...tempData,current},
                    progress:{...progress, gallaryUpdated:true}});
                }
                
                break

            default:
                break

        }
    }

    validateField = (fieldName, value) => {
        const error = this.state.formErrorMessage
        const valid = this.state.formValid

        switch (fieldName) {
            case "propertyType":
                if (value === "") {
                    error.propertyTypeErrorMessage = "This field is Required"
                    valid.propertyTypeValid = false
                }
                else {
                    error.propertyTypeErrorMessage = ""
                    valid.propertyTypeValid = true
                }
                break

            case "bhk":
                if (value === "") {
                    error.bhkErrorMessage = "This field is Required"
                    valid.bhkValid = false
                }
                else {
                    error.bhkErrorMessage = ""
                    valid.bhkValid = true
                }
                break

            case "totalFloors":
                if (value === "") {
                    error.totalFloorsErrorMessage = "This field is Required"
                    valid.totalFloorsValid = false
                }
                else if (value === 0) {
                    error.totalFloorsErrorMessage = "Total Floors can't be zero"
                    valid.totalFloorsValid = false
                }
                else if (value < parseInt(this.state.formValue.numberOfFloor)) {
                    error.totalFloorsErrorMessage = "Total Floors can't be less than Floor"
                    valid.totalFloorsValid = false
                }
                else {
                    error.totalFloorsErrorMessage = ""
                    valid.totalFloorsValid = true
                }
                break

            case "numberOfFloor":
                if (value === "") {
                    error.numberOfFloorErrorMessage = "This field is Required"
                    valid.numberOfFloorValid = false
                }
                else if (value > parseInt(this.state.formValue.totalFloors)) {
                    error.numberOfFloorErrorMessage = "Floor can't be greater than Total Floors"
                    valid.numberOfFloorValid = false
                }
                else {
                    error.numberOfFloorErrorMessage = ""
                    valid.numberOfFloorValid = true
                }
                break

            case "gender":
                if (value === "") {
                    error.genderErrorMessage = "This field is Required"
                    valid.genderValid = false
                }
                else {
                    error.genderErrorMessage = ""
                    valid.genderValid = true
                }
                break

            case "roomType":
                if (value === "") {
                    error.roomTypeErrorMessage = "This field is Required"
                    valid.roomTypeValid = false
                }
                else {
                    error.roomTypeErrorMessage = ""
                    valid.roomTypeValid = true
                }
                break

            case "buildingName":
                if (value === "") {
                    error.buildingNameErrorMessage = "This field is Required"
                    valid.buildingNameValid = false
                }
                else {
                    error.buildingNameErrorMessage = ""
                    valid.buildingNameValid = true
                }
                break

            case "area":
                if (value === "") {
                    error.areaErrorMessage = "This field is Required"
                    valid.areaValid = false
                }
                else {
                    error.areaErrorMessage = ""
                    valid.areaValid = true
                }
                break

            case "city":
                if (value === "") {
                    error.cityErrorMessage = "This field is Required"
                    valid.cityValid = false
                }
                else {
                    error.cityErrorMessage = ""
                    valid.cityValid = true
                }
                break

            // case "landmark":
            //     error.landmarkErrorMessage = ""
            //     valid.landmarkValid = true
            //     break

            case "state":
                if (value === "") {
                    error.stateErrorMessage = "This field is Required"
                    valid.stateValid = false
                }
                else {
                    error.stateErrorMessage = ""
                    valid.stateValid = true
                }
                break

            case "pincode":
                if (value === "") {
                    error.pincodeErrorMessage = "This field is Required"
                    valid.pincodeValid = false
                }
                else if (value.length > 6) {
                    error.pincodeErrorMessage = "Invalid Pincode"
                    valid.pincodeValid = false
                }
                else {
                    error.pincodeErrorMessage = ""
                    valid.pincodeValid = true
                }
                break

            case "deposit":
                if (value === "") {
                    error.depositErrorMessage = "This field is Required"
                    valid.depositValid = false
                }
                else {
                    error.depositErrorMessage = ""
                    valid.depositValid = true
                }
                break

            case "rent":
                if (value === "") {
                    error.rentErrorMessage = "This field is Required"
                    valid.rentValid = false
                }
                else {
                    error.rentErrorMessage = ""
                    valid.rentValid = true
                }
                break

            case "amount":
                if (value === "") {
                    error.amountErrorMessage = "This field is Required"
                    valid.amountValid = false
                }
                else {
                    error.amountErrorMessage = ""
                    valid.amountValid = true
                }
                break

            case "maintainance":
                if (value === "") {
                    error.maintainanceErrorMessage = "This field is Required"
                    valid.maintainanceValid = false
                }
                else if (value === "included") {
                    error.amountErrorMessage = ""
                    error.maintainanceErrorMessage = ""
                    valid.maintainanceValid = true
                    valid.amountValid = true
                }
                else {
                    error.maintainanceErrorMessage = ""
                    valid.maintainanceValid = true
                }
                break

            case "furnishing":
                if (value === "") {
                    error.furnishingErrorMessage = "This field is Required"
                    valid.furnishingValid = false
                }
                else {
                    error.furnishingErrorMessage = ""
                    valid.furnishingValid = true
                }
                break

            case "availableFrom":
                if (value === "") {
                    error.availableFromErrorMessage = "This field is Required"
                    valid.availableFromValid = false
                }
                else {
                    error.availableFromErrorMessage = ""
                    valid.availableFromValid = true
                }
                break

            case "parking":
                if (value === "") {
                    error.parkingErrorMessage = "This field is Required"
                    valid.parkingValid = false
                }
                else {
                    error.parkingErrorMessage = ""
                    valid.parkingValid = true
                }
                break

            // case "description":
            //     error.descriptionErrorMessage = ""
            //     valid.descriptionValid = true
            //     break

            case "balcony":
                if (value === "") {
                    error.balconyErrorMessage = "This field is Required"
                    valid.balconyValid = false
                }
                else {
                    error.balconyErrorMessage = ""
                    valid.balconyValid = true
                }
                break

            case "bathroom":
                if (value === "") {
                    error.bathroomErrorMessage = "This field is Required"
                    valid.bathroomValid = false
                }
                else {
                    error.bathroomErrorMessage = ""
                    valid.bathroomValid = true
                }
                break

            case "gym":
                if (value === "") {
                    error.gymErrorMessage = "This field is Required"
                    valid.gymValid = false
                }
                else {
                    error.gymErrorMessage = ""
                    valid.gymValid = true
                }
                break

            case "waterSupply":
                if (value === "") {
                    error.waterSupplyErrorMessage = "This field is Required"
                    valid.waterSupplyValid = false
                }
                else {
                    error.waterSupplyErrorMessage = ""
                    valid.waterSupplyValid = true
                }
                break

            case "nonVegAllowed":
                if (value === "") {
                    error.nonVegAllowedErrorMessage = "This field is Required"
                    valid.nonVegAllowedValid = false
                }
                else {
                    error.nonVegAllowedErrorMessage = ""
                    valid.nonVegAllowedValid = true
                }
                break

            case "gatedSecurity":
                if (value === "") {
                    error.gatedSecurityErrorMessage = "This field is Required"
                    valid.gatedSecurityValid = false
                }
                else {
                    error.gatedSecurityErrorMessage = ""
                    valid.gatedSecurityValid = true
                }
                break

            case "numberOfPeople":
                if (value === "") {
                    error.numberOfPeopleErrorMessage = "This field is Required"
                    valid.numberOfPeopleValid = false
                }
                else {
                    error.numberOfPeopleErrorMessage = ""
                    valid.numberOfPeopleValid = true
                }
                break

            case "numberOfPeopleRequired":
                if (value === "") {
                    error.numberOfPeopleRequiredErrorMessage = "This field is Required"
                    valid.numberOfPeopleRequiredValid = false
                }
                else {
                    error.numberOfPeopleRequiredErrorMessage = ""
                    valid.numberOfPeopleRequiredValid = true
                }
                break

            default:
                break
        }

        if (valid.propertyTypeValid && valid.bhkValid && valid.numberOfFloorValid &&
            valid.totalFloorsValid && valid.roomTypeValid && valid.genderValid) {
            valid.propertyDetailsButtonActive = true
        } else {
            valid.propertyDetailsButtonActive = false
        }

        if (valid.buildingNameValid && valid.areaValid && valid.cityValid &&
            valid.landmarkValid && valid.stateValid && valid.pincodeValid) {
            valid.localityDetailsButtonActive = true
        } else {
            valid.localityDetailsButtonActive = false
        }

        if (valid.rentValid && valid.depositValid && valid.maintainanceValid &&
            valid.amountValid && valid.availableFromValid && valid.furnishingValid && valid.parkingValid) {
            valid.rentalDetailsButtonActive = true
        } else {
            valid.rentalDetailsButtonActive = false
        }

        if (valid.bathroomValid && valid.balconyValid && valid.waterSupplyValid &&
            valid.gymValid && valid.nonVegAllowedValid && valid.gatedSecurityValid) {
            valid.amenitiesButtonActive = true
        } else {
            valid.amenitiesButtonActive = false
        }

        if (valid.numberOfPeopleRequiredValid && valid.numberOfPeopleValid && valid.listOfPeopleValid) {
            valid.flatmatesInfoButtonActive = true
        } else {
            valid.flatmatesInfoButtonActive = false
        }

        this.setState({ formErrorMessage: error, formValid: valid })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.updateProgressPercent(event)
        // const valid = this.state.formValid
        // if (valid.propertyDetailsButtonActive && valid.localityDetailsButtonActive
        //     && valid.rentalDetailsButtonActive && valid.amenitiesButtonActive 
        //     && valid.flatmatesInfoButtonActive && valid.gallaryButtonActive) {
        //     this.submitRegistration()
        // }
        if (event.target.id==="gallery") {
            this.submitRegistration()
        }
    }

    submitRegistration = () => {
        this.setState({ successMessage: "", errorMessage: "" })
        const {createAd}=this.props;
        const dataToPost=createPostData(this.state.formValue)
        createAd(dataToPost)
        this.setState({adPosted: true})
    }

    loadFloors() {
        let floors = []
        for (let i = 0; i < 20; i++) {
            floors.push(<option key={i} value={i}>{i}</option>)
        }
        return floors;
    }

    onSearch = search => {
        this.setState({ search, loading: !!search, users: [] });
        this.loadGithubUsers(search);
    };

    loadGithubUsers(key) {
        if (!key) {
            this.setState({
                users: [],
            });
            return;
        }

        // axios(`${getUserByIdUrl}?key=${key}`)
        //     .then(res => res.data)
        //     // .then(res => (res.json()))
        //     .then(items  => {
        //         const { search } = this.state;
        //         if (search !== key) return;

        //         this.setState({
        //             users: items.slice(0, 10),
        //             loading: false,
        //         });
        //     });
    }

    loadPropertyDetails = (_) => {
        return (
            <>
                <h3>Property Details</h3>
                <form className="form align" name="propertyDetails" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="form-group col-sm-10 col-md-5">
                            <label>
                                Property Type:<span className="text-danger" >*</span>
                            </label>
                            <select className="form-control" name="propertyType" value={this.state.formValue.propertyType} onChange={this.handleChange}
                            >
                                <option value="">Select Your Building Type</option>
                                <option value="apartment">Apartment</option>
                                <option value="gatedSociety">Gated Society</option>
                                <option value="independentHouseVilla">Independent House/Villa</option>
                                <option value="gatedCommunityVilla">Gated Community/Villa</option>
                            </select>
                            <span name="propertyTypeError" className="text-danger">{this.state.formErrorMessage.propertyTypeErrorMessage}</span>
                        </div>

                        <div className="form-group col-md-5 col-sm-10">
                            <label>
                                BHK:<span className="text-danger" >*</span>
                            </label>
                            <select className="form-control" name="bhk" value={this.state.formValue.bhk} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="">Select Number of BHK</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="4+">More than 4</option>
                            </select>
                            <span name="bhk" className="text-danger">{this.state.formErrorMessage.bhkErrorMessage}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-sm-10 col-md-5">
                            <label>
                                Number of Floor:<span className="text-danger" >*</span>
                            </label>
                            <select className="form-control" name="numberOfFloor" value={this.state.formValue.numberOfFloor} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="">Select Floor</option>
                                {this.loadFloors()}
                            </select>
                            <span name="numberOfFloorError" className="text-danger">{this.state.formErrorMessage.numberOfFloorErrorMessage}</span>
                        </div>

                        <div className="form-group col-md-5 col-sm-10">
                            <label>
                                Total Floors:<span className="text-danger" >*</span>
                            </label>
                            <select className="form-control" name="totalFloors" value={this.state.formValue.totalFloors} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="">Select Total Floors</option>
                                {this.loadFloors()}
                            </select>
                            <span name="totalFloorsError" className="text-danger">{this.state.formErrorMessage.totalFloorsErrorMessage}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-sm-10 col-md-5">
                            <label>
                                Room Type:<span className="text-danger" >*</span>
                            </label>
                            <select className="form-control" name="roomType" value={this.state.formValue.roomType} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="">Select Your Room Type</option>
                                <option value="single">Single Room</option>
                                <option value="shared">Shared Room</option>
                            </select>
                            <span name="roomTypeError" className="text-danger">{this.state.formErrorMessage.roomTypeErrorMessage}</span>
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>
                                Tenant Gender:<span className="text-danger" >*</span>
                            </label>
                            <select className="form-control" name="gender" value={this.state.formValue.gender} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="">Select Your Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>
                            <span name="genderError" className="text-danger">{this.state.formErrorMessage.genderErrorMessage}</span>
                        </div>

                    </div>


                    <div>
                        <span><span className="text-danger" >*</span>marked fields are mandatory</span><br />
                        <center><StyledPrimaryActionButton primary name="register" type="submit"
                            disabled={!(this.state.formValid.propertyDetailsButtonActive)}
                        >Save & Continue</StyledPrimaryActionButton></center>
                    </div>
                </form>
            </>
        );
    }

    loadLocalityDetails = (_) => {
        return (
            <>
                <h3>Locality Details</h3>
                <form className="form align" name="localityDetails" onSubmit={this.handleSubmit}>
                    <div className="row">

                        <div className="form-group col-sm-10 col-md-5">
                            <label>House/Building Name:</label><span className="text-danger" >*</span>
                            <input type="text" name="buildingName" id="buildingName" className="form-control"
                                placeholder="Enter House No/Building Name"
                                value={this.state.formValue.buildingName}
                                onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                            </input>
                            <span name="buildingNameError" className="text-danger">{this.state.formErrorMessage.buildingNameErrorMessage}</span>
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Enter Area:</label><span className="text-danger" >*</span>
                            <input type="text" name="area" id="area" className="form-control"
                                placeholder="Enter Area"
                                value={this.state.formValue.area}
                                onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                            </input>
                            <span name="areaError" className="text-danger">{this.state.formErrorMessage.areaErrorMessage}</span>
                        </div>

                    </div>

                    <div className="row">

                        <div className="form-group col-sm-10 col-md-5">
                            <label>City:</label><span className="text-danger" >*</span>
                            <input type="text" name="city" id="city" className="form-control"
                                placeholder="Enter City"
                                value={this.state.formValue.city}
                                onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                            </input>
                            <span name="cityError" className="text-danger">{this.state.formErrorMessage.cityErrorMessage}</span>
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Landmark:</label>
                            <input type="text" name="landmark" id="landmark" className="form-control"
                                placeholder="Enter Landmark"
                                value={this.state.formValue.landmark}
                                onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                            </input>
                            <span name="landmarkError" className="text-danger">{this.state.formErrorMessage.landmarkErrorMessage}</span>
                        </div>

                    </div>

                    <div className="row">

                        <div className="form-group col-sm-10 col-md-5">
                            <label>State:</label><span className="text-danger" >*</span>
                            <input type="text" name="state" id="state" className="form-control"
                                placeholder="Enter State"
                                value={this.state.formValue.state}
                                onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                            </input>
                            <span name="stateError" className="text-danger">{this.state.formErrorMessage.stateErrorMessage}</span>
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Pincode:</label><span className="text-danger" >*</span>
                            <input type="number" name="pincode" id="pincode" className="form-control"
                                placeholder="Enter Pincode"
                                value={this.state.formValue.pincode}
                                onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                            </input>
                            <span name="pincodeError" className="text-danger">{this.state.formErrorMessage.pincodeErrorMessage}</span>
                        </div>

                    </div>

                    <div>
                        <span><span className="text-danger" >*</span>marked fields are mandatory</span><br />
                        <center>
                            <StyledPrimaryActionButton primary name="register" type="submit"
                            disabled={!(this.state.formValid.localityDetailsButtonActive)}>
                                Save & Continue
                            </StyledPrimaryActionButton>
                        </center>
                    </div>
                </form>
            </>
        )
    }

    loadRentalDetails = (_) => {
        return (
            <>
                <h3>Rental Details</h3>
                <form className="form align" name="rentalDetails" onSubmit={this.handleSubmit}>
                    <div className="row">

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Rent:</label><span className="text-danger" >*</span>
                            <input type="number" name="rent" id="rent" className="form-control"
                                placeholder="Enter Rent"
                                onChange={this.handleChange}
                                value={this.state.formValue.rent}
                                onBlur={this.updateProgress}>
                            </input>
                            <span name="rentError" className="text-danger">{this.state.formErrorMessage.rentErrorMessage}</span>
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Deposit:</label><span className="text-danger" >*</span>
                            <input type="number" name="deposit" id="deposit" className="form-control"
                                placeholder="Enter Deposit"
                                value={this.state.formValue.deposit}
                                onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                            </input>
                            <span name="depositError" className="text-danger">{this.state.formErrorMessage.depositErrorMessage}</span>
                        </div>

                    </div>

                    <div className="row">

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Maintainance:</label><span className="text-danger" >*</span>
                            <select className="form-control" name="maintainance" value={this.state.formValue.maintainance}
                                onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="">Select One</option>
                                <option value="included">Included in Rent</option>
                                <option value="extra">Extra</option>
                            </select>
                            <span name="maintainanceError" className="text-danger">{this.state.formErrorMessage.maintainanceErrorMessage}</span>
                        </div>

                        {this.state.formValue.maintainance === "extra" ?
                            <div className="form-group col-sm-10 col-md-5">
                                <label>Amount:</label>
                                <input type="number" name="amount" id="amount" className="form-control"
                                    value={this.state.formValue.amount}
                                    onChange={this.handleChange}
                                    onBlur={this.updateProgress}
                                    placeholder="Enter Maintainance">
                                </input>
                                <span name="amountError" className="text-danger">{this.state.formErrorMessage.amountErrorMessage}</span>
                            </div> : null}

                    </div>

                    <div className="row">

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Available From:</label><span className="text-danger" >*</span>
                            <input type="date" name="availableFrom" id="availableFrom" className="form-control"
                                value={this.state.formValue.availableFrom}
                                onChange={this.handleChange}
                                onBlur={this.updateProgress}
                                placeholder="Flat will be Available From">
                            </input>
                            <span name="availableFromError" className="text-danger">{this.state.formErrorMessage.availableFromErrorMessage}</span>
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Furnishing:</label><span className="text-danger" >*</span>
                            <select className="form-control" name="furnishing" value={this.state.formValue.furnishing} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="">Select Furnishing</option>
                                <option value="fullFurnished">Full Furnished</option>
                                <option value="semiFurnished">Semi Furnished</option>
                                <option value="unFurnished">UnFurnished</option>
                            </select>
                            <span name="furnishingError" className="text-danger">{this.state.formErrorMessage.furnishingErrorMessage}</span>
                        </div>

                    </div>

                    <div className="row">

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Parking:</label><span className="text-danger" >*</span>
                            <select className="form-control" name="parking" value={this.state.formValue.parking} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="">Select Parking</option>
                                <option value="bike">Bike</option>
                                <option value="car">Car</option>
                                <option value="both">Bike & Car</option>
                                <option value="NA">Not Available</option>
                            </select>
                            <span name="parkingError" className="text-danger">{this.state.formErrorMessage.parkingErrorMessage}</span>
                        </div>

                    </div>

                    {/* <div className="row">
                        <div className="form-group col-sm-10 col-md-10">
                            <label>Description:</label>
                            <textarea name="description" id="description" className="form-control"
                                placeholder="Enter Description"
                                value={this.state.formValue.description}
                                onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                            </textarea >
                            <span name="descriptionError" className="text-danger">{this.state.formErrorMessage.descriptionErrorMessage}</span>
                        </div>

                    </div> */}

                    <div>
                        <span><span className="text-danger" >*</span>marked fields are mandatory</span><br />
                        <center>
                            <StyledPrimaryActionButton primary name="register" type="submit"
                            disabled={!(this.state.formValid.rentalDetailsButtonActive)}>
                                Save & Continue
                            </StyledPrimaryActionButton>
                            </center>
                    </div>
                </form>
            </>
        )
    }

    loadGallery = (_) => {
        const uploadButton = (
            <div style={{ border: "1px dotted black" }} >
                <UploadIcon/>
                <div style={{ margin: 10 }}>Upload</div>
            </div>
        );
        return (
            <>
                <h3>Gallery</h3>
                <form className="form align" id="gallery" onSubmit={this.handleSubmit}>
                    <div className="row" >

                        <div className="container jumbotron form-group col-sm-10 col-md-10" style={{ textAlign: "center" }}>
                            <h5>Add photos to get 3X more responses.</h5>
                            <h6>90% tenants contact on properties with photos</h6>
                            <input type="file" name="image" id="image" accept="image/png, image/jpeg"
                                onChange={this.handleImageChange} multiple
                                hidden>
                            </input>
                            <label htmlFor="image">{uploadButton}</label>
                            <br />
                            <br />
                            <span>
                                {this.state.formValue.images.length >= 1 ? this.loadUploadedImages() : null}
                            </span>

                            {/* <span name="buildingNameError" className="text-danger">{this.state.formErrorMessage.contactNumber}</span> */}
                        </div>

                    </div>

                    <div>
                        {/* {this.state.formValue.image?this.state.formvalue.images[1].name:null} */}
                        <span><span className="text-danger" >*</span>marked fields are mandatory</span><br />
                        <center><StyledPrimaryActionButton primary name="register" type="submit"
                        >Save & Continue</StyledPrimaryActionButton></center>
                    </div>
                </form>
            </>
        )
    }

    loadAmenities = (_) => {
        return (
            <>
                <h3>Amenities</h3>
                <form className="form align" name="amenities" onSubmit={this.handleSubmit}>
                    <div className="row">

                        <div className="form-group col-sm-10 col-md-5">
                            <label>
                                {/* <FaBath/>  */}
                                Bathroom:</label><span className="text-danger" >*</span>
                            <select className="form-control" name="bathroom" value={this.state.formValue.bathroom} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="">Select Number of Bathrooms</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="4+">More than 4</option>
                            </select>
                            <span name="bathroomError" className="text-danger">{this.state.formErrorMessage.bathroomErrorMessage}</span>
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Balcony:</label><span className="text-danger" >*</span>
                            <select className="form-control" name="balcony" value={this.state.formValue.balcony} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="select">Select Number of Balcony</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="4+">More than 4</option>
                            </select>
                            <span name="balconyError" className="text-danger">{this.state.formErrorMessage.balconyErrorMessage}</span>
                        </div>

                    </div>

                    <div className="row">

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Water Supply:</label><span className="text-danger" >*</span>
                            <select className="form-control" name="waterSupply" value={this.state.formValue.waterSupply} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="">Select Water Supply</option>
                                <option value="corporation">Corporation</option>
                                <option value="bore">Bore</option>
                                <option value="both">Both</option>
                            </select>
                            <span name="waterSupplyError" className="text-danger">{this.state.formErrorMessage.waterSupplyErrorMessage}</span>
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Gym:</label><span className="text-danger" >*</span>
                            <select className="form-control" name="gym" value={this.state.formValue.gym} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="">Gym Available</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            <span name="gymError" className="text-danger">{this.state.formErrorMessage.gymErrorMessage}</span>
                        </div>



                    </div>

                    <div className="row">

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Non-Veg Allowed:</label><span className="text-danger" >*</span>
                            <select className="form-control" name="nonVegAllowed" value={this.state.formValue.nonVegAllowed} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                <option value="">Non-Veg allowed</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            <span name="nonVegAllowedError" className="text-danger">{this.state.formErrorMessage.nonVegAllowedErrorMessage}</span>
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>Gated Security:</label><span className="text-danger" >*</span>
                            <select className="form-control" name="gatedSecurity" value={this.state.formValue.gatedSecurity} onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                                    <option value="">Is there Gated Security?</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            <span name="gatedSecurityError" className="text-danger">{this.state.formErrorMessage.gatedSecurityErrorMessage}</span>
                        </div>

                    </div>

                    <label>Other Amenities:</label><br />
                    <div className="row">

                        <div className="form-group col-sm-10 col-md-5">
                            <label>
                                {/* <LiftIcon/> */}
                                <input type="checkbox" name="lift" checked={this.state.formValue.lift} onChange={this.handleChange}></input>
                                &nbsp;&nbsp;Lift
                            </label>
                            {/* <span name="buildingNameError" className="text-danger">{this.state.formErrorMessage.contactNumber}</span> */}
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>
                                {/* <AcIcon/> */}
                                <input type="checkbox" name="ac" checked={this.state.formValue.ac} onChange={this.handleChange}></input>
                                &nbsp;&nbsp;AC
                            </label>
                            {/* <span name="buildingNameError" className="text-danger">{this.state.formErrorMessage.contactNumber}</span> */}
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>
                                {/* <IntercomIcon/> */}
                                <input type="checkbox" name="intercom" checked={this.state.formValue.intercom} onChange={this.handleChange}></input>
                                &nbsp;&nbsp;Intercom
                            </label>
                            {/* <span name="buildingNameError" className="text-danger">{this.state.formErrorMessage.contactNumber}</span> */}
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>
                                {/* <GasIcon/> */}
                                <input type="checkbox" name="gasPipeline" checked={this.state.formValue.gasPipeline} onChange={this.handleChange}></input>
                                &nbsp;&nbsp;Gas Pipeline
                            </label>
                            {/* <span name="buildingNameError" className="text-danger">{this.state.formErrorMessage.contactNumber}</span> */}
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>
                                {/* <WifiIcon/> */}
                                <input type="checkbox" name="wifi" checked={this.state.formValue.wifi} onChange={this.handleChange}></input>
                                &nbsp;&nbsp;Wifi
                            </label>
                            {/* <span name="buildingNameError" className="text-danger">{this.state.formErrorMessage.contactNumber}</span> */}
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            <label>
                                <input type="checkbox" name="waterPurifier" checked={this.state.formValue.waterPurifier} onChange={this.handleChange}></input>
                                &nbsp;&nbsp;Fire Extinguisher
                            </label>
                            {/* <span name="buildingNameError" className="text-danger">{this.state.formErrorMessage.contactNumber}</span> */}
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            {/* <SwimmingpoolIcon/> */}
                            <label>
                                <input type="checkbox" name="swimmingPool" checked={this.state.formValue.swimmingPool} onChange={this.handleChange}></input>
                                &nbsp;&nbsp;Swimming Pool
                            </label>
                            {/* <span name="buildingNameError" className="text-danger">{this.state.formErrorMessage.contactNumber}</span> */}
                        </div>

                        {/* <div className="form-group col-sm-10 col-md-5">
                            <label>
                                <input type="checkbox" name="park" checked={this.state.formValue.park} onChange={this.handleChange}></input>
                                &nbsp;&nbsp;Park
                            </label>
                        </div> */}

                        <div className="form-group col-sm-10 col-md-5">
                            {/* <GeyserIcon/> */}
                            <label>
                                <input type="checkbox" name="geyser" checked={this.state.formValue.geyser} onChange={this.handleChange}></input>
                                &nbsp;&nbsp;Geyser
                            </label>
                            {/* <span name="buildingNameError" className="text-danger">{this.state.formErrorMessage.contactNumber}</span> */}
                        </div>

                        {/* <div className="form-group col-sm-10 col-md-5">
                            <label>
                                <input type="checkbox" name="visitorparking" checked={this.state.formValue.visitorparking} onChange={this.handleChange}></input>
                                &nbsp;&nbsp;Visitor Parking
                            </label>
                        </div> */}

                        <div className="form-group col-sm-10 col-md-5">
                            {/* <MaidIcon/> */}
                            <label>
                                <input type="checkbox" name="maid" checked={this.state.formValue.maid} onChange={this.handleChange}></input>
                                &nbsp;&nbsp;Maid
                            </label>
                            {/* <span name="buildingNameError" className="text-danger">{this.state.formErrorMessage.contactNumber}</span> */}
                        </div>

                        <div className="form-group col-sm-10 col-md-5">
                            {/* <CookIcon/> */}
                            <label>
                                <input type="checkbox" name="cook" checked={this.state.formValue.cook} onChange={this.handleChange}></input>
                                &nbsp;&nbsp;Cook
                            </label>
                            {/* <span name="buildingNameError" className="text-danger">{this.state.formErrorMessage.contactNumber}</span> */}
                        </div>

                    </div>

                    <div>
                        <span><span className="text-danger" >*</span>marked fields are mandatory</span><br />
                        <center>
                            <StyledPrimaryActionButton primary name="register" type="submit"
                            disabled={!(this.state.formValid.amenitiesButtonActive)}>
                                Save & Continue
                                </StyledPrimaryActionButton>
                                </center>

                    </div>
                </form>


            </>
        )
    }

    saveListOfPeople = (event) => {
        const { listOfPeople } = this.state.formValue
        const error = this.state.formErrorMessage
        const valid = this.state.formValid
        if (this.state.formValue.numberOfPeople === "") {
            valid.listOfPeopleValid = false
            error.listOfPeopleErrorMessage = "You need to specify number of flatmates first"
        }
        else if (parseInt(this.state.formValue.numberOfPeople) < listOfPeople.length) {
            valid.listOfPeopleValid = false
            error.listOfPeopleErrorMessage = "You can't tag more people than Mentioned"
        }
        else {
            valid.listOfPeopleValid = true
            error.listOfPeopleErrorMessage = ""
            listOfPeople.push(event.value)
        }

        if (valid.numberOfPeopleRequiredValid && valid.numberOfPeopleValid && valid.listOfPeopleValid) {
            valid.flatmatesInfoButtonActive = true
        } else {
            valid.flatmatesInfoButtonActive = false
        }

        this.setState({
            listOfPeople, listOfPeopleErrorMessage: error, listOfPeopleValid: valid
        })

    }

    validatePrevious = (_) => {
        const error = this.state.formErrorMessage
        const valid = this.state.formValid
        if (this.state.formValue.numberOfPeople === "") {
            valid.listOfPeopleValid = false
            error.listOfPeopleErrorMessage = "You need to specify number of flatmates first"
            error.numberOfPeopleErrorMessage = "Please specify this first"
            valid.numberOfPeopleValid = false
        } else {
            valid.listOfPeopleValid = true
            error.listOfPeopleErrorMessage = ""
            // error.numberOfPeopleErrorMessage=""
            // valid.numberOfPeopleValid=true
        }
        this.setState({ formErrorMessage: error, formValid: valid })
    }

    loadPeople = (_) => {
        const { users, loading } = this.state;
        return (
            <>
                <h3>Flatmates' Info</h3>
                <form className="form align" name="flatmatesInfo" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="form-group col-sm-10 col-md-10">
                            <label>Number of Flatmate/s Required:</label><span className="text-danger" >*</span>
                            <select className="form-control" name="numberOfPeopleRequired"
                                value={this.state.formValue.numberOfPeopleRequired}
                                onChange={this.handleChange}
                            >
                                <option value="">Select the Number of People Required</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                            <span name="numberOfPeopleRequiredError" className="text-danger">{this.state.formErrorMessage.numberOfPeopleRequiredErrorMessage}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-sm-10 col-md-10">
                            <label>Number of Flatmates in Flat (Except You):</label><span className="text-danger" >*</span>
                            <select className="form-control" name="numberOfPeople"
                                value={this.state.formValue.numberOfPeople}
                                onChange={this.handleChange}
                            >
                                <option value="">Select the Number of People Living in Flat</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </select>

                            <span name="numberOfPeopleError" className="text-danger">{this.state.formErrorMessage.numberOfPeopleErrorMessage}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-sm-10 col-md-10">
                            <label>Description:</label>
                            <textarea name="description" id="description" className="form-control"
                                rows="5"
                                placeholder="Enter Description"
                                value={this.state.formValue.description}
                                onChange={this.handleChange}
                                onBlur={this.updateProgress}>
                            </textarea >
                            <span name="descriptionError" className="text-danger">{this.state.formErrorMessage.descriptionErrorMessage}</span>
                        </div>

                    </div>

                    {/* TODO: Disabling flatmate tagging for now, will need to add BE functionality to handle this first */}
                    {/* <div className="row">
                        <div className="form-group col-sm-10 col-md-10">
                            <label>Tag flatmates in Post:</label><span className="text-danger" >*</span>
                            <Mentions style={{ width: '100%' }} loading={loading}
                                onSearch={this.onSearch} onSelect={this.saveListOfPeople}
                                name="listOfPeople"
                                className="form-control"
                                placeholder="Start with @ and mention email id eg. @emailId"
                                onFocus={this.validatePrevious}>
                                {users.map(({ username, avatar_url: avatar }) => (
                                    <Option key={username} value={username} style={{ width: '100%', height: '10%' }} className="antd-demo-dynamic-option">
                                        <img src={avatar} alt='' style={{ height: '10%', width: '10%' }} />
                                        <span>{username}</span>
                                    </Option>
                                ))}
                            </Mentions>
                            <span name="listOfPeopleError" className="text-danger">{this.state.formErrorMessage.listOfPeopleErrorMessage}</span>
                        </div>
                    </div> */}

                    <div>
                        <span><span className="text-danger" >*</span>marked fields are mandatory</span><br />
                        <center><StyledPrimaryActionButton primary name="register" type="submit"
                            disabled={!(this.state.formValid.flatmatesInfoButtonActive)}>
                                Save & Continue
                                </StyledPrimaryActionButton>
                                </center>
                    </div>
                </form>
            </>
        )
    }

    // genExtra = (_) => (
    //     <span>
    //         {/* <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '32px' }} /> */}
    //         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    //         {/* icon={<EditFilled />} */}
    //         <Button type="primary" shape="round" size='large'
    //             onClick={event => {
    //                 // If you don't want click extra trigger collapse, you can prevent this:
    //                 // event.stopPropagation();
    //                 this.setState({ progress: { ...this.state.progress, panel: _ } })
    //             }}
    //         />
    //     </span>
    // );

    onStepChange = event => {
        const changeTo=event.target.id;
        const { formStepsVisibility, progress } = this.state
        let { current
        } = formStepsVisibility

        // const buttonActive = [
        //     'propertyDetailsButtonActive',
        //     'localityDetailsButtonActive',
        //     'rentalDetailsButtonActive',
        //     'amenitiesButtonActive',
        //     'flatmatesInfoButtonActive',
        //     'gallaryButtonActive',
        // ]

        const steps=[
            'propertyDetailsVisible',
            'localityDetailsVisible',
            'rentalDetailsVisible',
            'amenitiesVisible',
            'peopleVisible',
            'galleryVisible',
        ]

        const updateStatus=[
            'propertyDetailsUpdated',
            'localityDetailsUpdated',
            'rentalDetailsUpdated',
            'amenitiesUpdated',
            'flatmatesInfoUpdated',
            'gallaryUpdated'
        ]
        
        if(changeTo && (progress[updateStatus[changeTo]])){
            this.setState({ formStepsVisibility:{...formStepsVisibility, [steps[current]]:false,[steps[changeTo]]:true, current:changeTo} });
        }
        // this.setState({ formStepsVisibility:{...formStepsVisibility, [tempData[current]]:false,[tempData[changeTo]]:true, current:changeTo} });
      };

    getFill=(visible,updated)=>{
        if(visible){
            return '#bf2100'
        }else if(updated){
            return '#000'
        }
        return '#c1c3c7'
    }

    getPointer(active,updated){
        if(active){
            return 'default'
        }else if(updated){
            return 'pointer'
        }
        return 'not-allowed'
    }

    loadSteps=()=>{
        const { formStepsVisibility, progress } = this.state
        let {
            propertyDetailsVisible,
            localityDetailsVisible,
            rentalDetailsVisible,
            amenitiesVisible,
            peopleVisible,
            galleryVisible
        } = formStepsVisibility
       const{
        propertyDetailsUpdated,
        localityDetailsUpdated,
        rentalDetailsUpdated,
        amenitiesUpdated,
        flatmatesInfoUpdated,
        gallaryUpdated
       }=progress
        return(
            <>
                <StyledStepContainer onClick={this.onStepChange}>
                    <StyledStep id='0' 
                        active={propertyDetailsVisible} 
                        color={this.getFill(propertyDetailsVisible,propertyDetailsUpdated)}
                        pointer={this.getPointer(propertyDetailsVisible,propertyDetailsUpdated)} 
                    >
                        <StyledIconContainer id='0'>
                            <ApartmentsIcon id='0' 
                            fillState={this.getFill(propertyDetailsVisible,propertyDetailsUpdated)}/>
                        </StyledIconContainer> 
                        <center>                   
                        <StyledLabel id='0'>Property Details</StyledLabel>
                        </center></StyledStep>

                    <StyledStepDivider type="vertical" active={propertyDetailsUpdated} 
                    color={this.getFill(localityDetailsVisible,propertyDetailsUpdated)}/>
                    
                    <StyledStep id='1' 
                        active={localityDetailsVisible} 
                        color={this.getFill(localityDetailsVisible,localityDetailsUpdated)} 
                        pointer={this.getPointer(localityDetailsVisible,localityDetailsUpdated)}
                    >
                    
                        <StyledIconContainer id='1'>
                            <HomeAddress id='1'
                            fillState={this.getFill(localityDetailsVisible,localityDetailsUpdated)}/>
                        </StyledIconContainer>
                        <center>
                        <StyledLabel id='1'>Locality Details</StyledLabel>
                        </center>
                    </StyledStep>
                    <StyledStepDivider type="vertical" active={localityDetailsUpdated}
                    color={this.getFill(rentalDetailsVisible,localityDetailsUpdated)}/>
                    
                    <StyledStep id='2' 
                        active={rentalDetailsVisible} 
                        color={this.getFill(rentalDetailsVisible,rentalDetailsUpdated)}
                        pointer={this.getPointer(rentalDetailsVisible,rentalDetailsUpdated)}
                    >
                        <StyledIconContainer id='2'>
                            <RentIcon id='2'
                            fillState={this.getFill(rentalDetailsVisible,rentalDetailsUpdated)}/>
                        </StyledIconContainer>
                        <center>
                        <StyledLabel id='2'>Rental Details</StyledLabel>
                        </center>
                    </StyledStep>
                    <StyledStepDivider type="vertical" active={rentalDetailsUpdated}
                    color={this.getFill(amenitiesVisible,rentalDetailsUpdated)}/>
                    
                    <StyledStep id='3' 
                        active={amenitiesVisible} 
                        color={this.getFill(amenitiesVisible,amenitiesUpdated)}
                        pointer={this.getPointer(amenitiesVisible,amenitiesUpdated)}
                    >
                        <StyledIconContainer id='3'>
                            <AmenitiesIcon id='3'
                            fillState={this.getFill(amenitiesVisible,amenitiesUpdated)}/>
                        </StyledIconContainer>
                        <center>
                        <StyledLabel id='3'>Amenities</StyledLabel>
                        </center>
                    </StyledStep>
                    <StyledStepDivider type="vertical" active={amenitiesUpdated}
                    color={this.getFill(peopleVisible,amenitiesUpdated)}/>
                    
                    <StyledStep id='4' 
                        active={peopleVisible} 
                        color={this.getFill(peopleVisible,flatmatesInfoUpdated)}
                        pointer={this.getPointer(peopleVisible,flatmatesInfoUpdated)}
                    >
                        <StyledIconContainer id='4'>
                            <PeopleIcon id='4'
                            fillState={this.getFill(peopleVisible,flatmatesInfoUpdated)}/>
                        </StyledIconContainer>
                        <center>
                        <StyledLabel id='4'>Flatmates</StyledLabel>
                        </center>
                    </StyledStep>
                    <StyledStepDivider type="vertical" active={flatmatesInfoUpdated}
                    color={this.getFill(galleryVisible,flatmatesInfoUpdated)}/>
                    
                    <StyledStep id='5' 
                        active={galleryVisible} 
                        color={this.getFill(galleryVisible,gallaryUpdated)}
                        pointer={this.getPointer(galleryVisible,gallaryUpdated)}
                    >
                        <StyledIconContainer id='5'>
                            <CameraIcon id='5' 
                            fillState={this.getFill(galleryVisible,gallaryUpdated)}/>
                        </StyledIconContainer>
                        <center>
                        <StyledLabel id='5'>Gallary</StyledLabel>
                        </center>
                    </StyledStep>
                </StyledStepContainer>
            </>
        )
    }

    render() {
        const { formStepsVisibility, adPosted } = this.state;
        const {current, 
            propertyDetailsVisible,
            localityDetailsVisible,
            rentalDetailsVisible,
            galleryVisible,
            amenitiesVisible,
            peopleVisible
        } = formStepsVisibility
        const {status, data}=this.props

        if(adPosted && status===400){
            return (
                <>
                <div className="container" style={{background:'white'}}>
                    <div className="col-12 col-md-12  tab-content" >
                        <Result
                            icon={<ErrorIcon/>}
                            title="Sorry error Occured for post creation!"
                            subTitle="Please Try in some time"
                            extra={[
                                <StyledLink type="primary" to="/">Go to Home Page</StyledLink>
                            ]}
                        />
                    </div>
                </div>
                </>
            )
        } else if(adPosted && status===201){
            return (
                <>
                <div className="container" style={{background:'white'}}>
                    <div className="col-12 col-md-12  tab-content" >
                        <Result
                            icon={<SuccessIcon/>}
                            title="Post created Successfully for your Flat!"
                            subTitle="You will get your Flamily member soon..."
                            extra={[
                            <StyledLink 
                                type="primary" 
                                key="console"
                                to="/"
                            >
                                Go to Home Page
                            </StyledLink>,
                            <StyledLink 
                                key="buy"
                                to={`/viewPost/${data.id}`}
                            >
                                View Your Post
                            </StyledLink>,
                            ]}
                        />
                    </div>
                </div>
                </>
            )
        }
        else{
            return (
                <React.Fragment>
                    <div className="container" style={{background:'white'}}><br></br>
                {/* <div className="row jumbotron ">
                    Progress
                    <Progress strokeColor={{
                        from: '#108ee9',
                        to: '#87d068'
                    }} percent={this.state.progress.progressPercent} status="active"></Progress>
                </div> */}
                        <div className="row">
                            <div className="col-3 col-md-3 col-sm-4">
                                {this.loadSteps()}
                            </div>
    
                            <div className="col-9 col-md-9  tab-content" >
    
                                <div id="propertyDetails">
                                    { propertyDetailsVisible ? this.loadPropertyDetails() : null}
                                </div>
    
                                <div id="localityDetails">
                                    { localityDetailsVisible ? this.loadLocalityDetails() : null}
                                </div>
    
                                <div id="rentalDetails">
                                    { rentalDetailsVisible ? this.loadRentalDetails() : null}
                                </div>
    
                                <div id="amenities">
                                    {amenitiesVisible ? this.loadAmenities() : null}
                                </div>
    
                                <div id="people">
                                    { peopleVisible ? this.loadPeople() : null}
                                </div>
                                
                                <div id="gallery">
                                    { galleryVisible ? this.loadGallery() : null}
                                </div>
    
                                <br/><br/><br/>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}

  
const mapDispatchToProps = dispatch => {
    return {
        createAd:_=>dispatch(createPost(_))
    }
}

const mapStateToProps=state=>{
    const {createPost:{response}}=state
    return response
}       

  
export default connect(mapStateToProps,mapDispatchToProps)(PostAd);

// export default PostAd;