import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import '../sass/_adCards.scss'
import { getAllAdsForFlatRequirement } from "../actions";
import {  valuesForRentBudget, formatMoveInDate } from './SelfAdsConstant'
import FlatmateIcon from "./svgComponents/FlatmateIcon";
import CardsSkeleton from "./CardsSkeleton";
import EmptyResultResponse from "./EmptyResultResponse";
import SearchPeopleIcon from "./svgComponents/SearchPeopleIcon";
import { loadSelfAdsCard } from "./SelfAdsCard";

const SelfAds = ({dataToView, pageFrom, loader}) => {
    const [adsForFlatRequirementToView, setAdsForFlatRequirementToView] = useState(dataToView);
    const {isLoading} = loader;
    useEffect(() => {
        setAdsForFlatRequirementToView(dataToView)
    }, [dataToView]);

    const navigate = useNavigate();

    const onCardClicked=(event)=>{
        event.stopPropagation()
        const {id}=event.target;
        if(id){
            navigate(`/view-post-for-flat-requirement/${id}`)
        }  
    }

    const navigateToMoreAds=(event)=>{
        event.stopPropagation()
        navigate('/view-more/flatmates')
    }

    const loadMoreCard = () => {
        return (
            <div className="col col-md-4 col-sm-12 d-flex clickable" key='card-show-more' id='card-show-more'>
                <div className="card shadow mb-5 mx-0" onClick={navigateToMoreAds} style={{ borderRadius:'10px', border:'2px solid #e7e4e4' }}>                      
                    <div className="card-body d-flex row align-items-center justify-content-center" id='card-show-more'>
                        <div className="d-flex card-img-top  align-items-center justify-content-center">
                            <div className="d-flex align-items-center justify-content-center" 
                            style={{height:'15em', 
                            width:'15em', 
                            // borderRadius:'100%', border:'3.5px solid #bf2100' 
                            }}>
                                {/* <FlatmateIcon id='0' 
                                    fillState='#bf2100'
                                    width='8em'
                                    height='8em'
                                /> */}
                                <SearchPeopleIcon id='0'/>
                            </div> 
                        </div> 
                        <div className="row mx-0 mt-5" >
                            <center>
                                <h5 className="card-title brandColor" id='card-show-more' style={{fontSize:'25px'}}>
                                    <b>View More Flats</b>
                                </h5>
                            </center>
                        </div> 
                        
                    </div>
                </div>
            </div>
        )
    }

    if(isLoading) {
        return (
             <CardsSkeleton />
        )
            
    }

    return(
        <>
            <div className="container">
                <div className="row">
                    {adsForFlatRequirementToView && adsForFlatRequirementToView.length > 0 ?
                        <div className="card-deck w-100">
                            {adsForFlatRequirementToView.map((card, index)=>loadSelfAdsCard(card, index, 'col col-md-4 col-sm-12 d-flex'))}
                            {pageFrom === 'home' && loadMoreCard()}
                        </div>
                        :
                        <EmptyResultResponse />
                    }
                </div>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    const {flatSearch, loader}=state
    return {flatSearch, loader}
}
  
const mapDispatchToProps = dispatch => {
    return {
        loadAllAdsForFlatRequirement:_=>dispatch(getAllAdsForFlatRequirement()),
        // setAdToView:(postData)=>dispatch(setPost(postData))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SelfAds);