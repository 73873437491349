export const GET_USER='GET_USER'
export const UPDATE_USER='UPDATE_USER'
export const UPDATE_MULTIPLE='UPDATE_MULTIPLE'
export const LOG_IN='LOG_IN'
export const SOCIAL_LOGIN='SOCIAL_LOGIN'
export const REGISTER='REGISTER'
export const VERIFY_OTP='VERIFY_OTP'
export const LOGGED_OUT='LOGGED_OUT'
export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER'
export const GET_USER_PROFILE = 'GET_USER_PROFILE'

export const NAVIGATE='NAVIGATE'
export const UPDATE_PROFILE='UPDATE_PROFILE'
// export const LOGGED_USER='LOGGED_USER'
// export const FULL_NAME='FULL_NAME'
// export const DIALOG_VISIBLE='DIALOG_VISIBLE'
// export const LOGGED_OUT='LOGGED_OUTr'

export const SET_LIMIT='SET_LIMIT'
export const GET_LIMIT='GET_LIMIT'
export const SET_OFFSET='SET_OFFSET'
export const GET_OFFSET='GET_OFFSET'
export const SET_COUNT = 'SET_COUNT'
export const GET_COUNT = 'GET_COUNT'

export const GET_SELF_AD_LIMIT = 'GET_SELF_AD_LIMIT'
export const SET_SELF_AD_LIMIT = 'SET_SELF_AD_LIMIT'
export const GET_SELF_AD_OFFSET = 'GET_SELF_AD_OFFSET'
export const SET_SELF_AD_OFFSET = 'SET_SELF_AD_OFFSET'
export const SET_SELF_AD_COUNT = 'SET_SELF_AD_COUNT'
export const GET_SELF_AD_COUNT = 'GET_SELF_AD_COUNT'

export const GET_ALL_ADS='GET_ALL_ADS'
export const SET_ALL_ADS='SET_ALL_ADS'
export const GET_CITIES='GET_CITIES'
export const SET_CITIES='SET_CITIES'
export const GET_AD_BY_ID='GET_AD_BY_ID'
export const GET_AD_FOR_FLAT_REQUIREMENT_BY_ID ='GET_AD_FOR_FLAT_REQUIREMENT_BY_ID'
export const GET_ALL_ADS_FOR_FLAT_REQUIREMENT = 'GET_ALL_ADS_FOR_FLAT_REQUIREMENT'
export const SET_ALL_ADS_FOR_FLAT_REQUIREMENT = 'SET_ALL_ADS_FOR_FLAT_REQUIREMENT'
// export const GET_ALL_BOOKS = 'GET_ALL_BOOKS'
// export const ADD_A_BOOK = 'ADD_A_BOOK'

export const SEARCH_FLAT = 'SEARCH_FLAT'
export const SEARCH_FLATMATE = 'SEARCH_FLATMATE'
export const SET_FLAT_SEARCH_RESULT='SET_FLAT_SEARCH_RESULT'
export const SET_FLATMATE_SEARCH_RESULT='SET_FLATMATE_SEARCH_RESULT'

export const CREATE_POST='CREATE_POST'
export const CREATE_POST_FOR_SELF = 'CREATE_POST_FOR_SELF'
export const SET_POST='SET_POST'
export const GET_POST='GET_POST'
export const SET_RESPONSE='SET_RESPONSE'

export const SHOW_LOADER='SHOW_LOADER'
export const HIDE_LOADER='HIDE_LOADER' 
// export const SET_SELECTED_BOOK = 'SET_SELECTED_BOOK'
// export const UPDATE_BOOK_DETAILS = 'UPDATE_BOOK_DETAILS'
export const DELETE_FLAT_POST='DELETE_FLAT_POST'
export const DELETE_FLATMATE_POST='DELETE_FLATMATE_POST'

export function getUserData(){
  return {
    type: GET_USER
  }
}

export function updateUserData(field,value){
  const valueToStore={[field]:value}
  return{
    type: UPDATE_USER,
    valueToStore
  }
}

export function updateUserMultipleData(fieldsToUpdate){
  // const valueToStore={[field]:value}
  return{
    type: UPDATE_MULTIPLE,
    fieldsToUpdate
  }
}

export function userLogout(){
  return{
    type: LOGGED_OUT
  }
}

export const getCities = ()=>({
  type: GET_CITIES
})

export function setCities(cities){
  return {
    type: SET_CITIES,
    cities
  }
}

export const getAllAds = () =>({
  type: GET_ALL_ADS
})

export const getAllAdsForFlatRequirement = () => ({
  type: GET_ALL_ADS_FOR_FLAT_REQUIREMENT
})

export const getAdById = (flatId) =>({
  type: GET_AD_BY_ID,
  flatId
})

export const getAdForFlatRequirementById = (adId) =>({
  type: GET_AD_FOR_FLAT_REQUIREMENT_BY_ID,
  adId
})

export function setAllAds(ads){
  return {
    type: SET_ALL_ADS,
    ads
  }
}

export function setAllAdsForFlatRequirements(adsForFlatRequirement){
  return {
    type: SET_ALL_ADS_FOR_FLAT_REQUIREMENT,
    adsForFlatRequirement
  }
}

export function createPost(postData){
  return{
    type: CREATE_POST,
    postData
  }
}

export function createPostForSelf(postData){
  return{
    type: CREATE_POST_FOR_SELF,
    postData
  }
}
export function setPost(postData){
  return{
    type: SET_POST,
    postData
  }
}

export function setResponse(response){
  return{
    type: SET_RESPONSE,
    response
  }
}

export const getPost = (postId) =>({
  type: GET_POST,
  postId
})

export function logIn(user){
  return{
    type: LOG_IN,
    user
  }
}

export function socialLogin(user, author){
  return{
    type:SOCIAL_LOGIN,
    user,
    author
  }
}

export const setLoggedInUser = (dataToStore) => ({
  type: SET_LOGGED_IN_USER,
  dataToStore
})

export function register(userData){
  return{
    type: REGISTER,
    userData
  }
}
export function verifyOtp(otpData){
  return {
    type: VERIFY_OTP,
    otpData
  }
}

export function searchFlat(flatData){
  return{
    type: SEARCH_FLAT,
    flatData
  }
}

export function searchFlatmate(flatmateData){
  return{
    type: SEARCH_FLATMATE,
    flatmateData
  }
}

export function setFlatSearchResult(flatSearchResult) {
  return{
    type: SET_FLAT_SEARCH_RESULT,
    flatSearchResult
  }
}

export function setFlatmateSearchResult(flatmateSearchResult) {
  return{
    type: SET_FLATMATE_SEARCH_RESULT,
    flatmateSearchResult
  }
}

export const navigateTo = (navigationObject) => ({
  type: NAVIGATE,
  navigationObject
})

export const updateUserProfile = (userProfile) => ({
  type: UPDATE_PROFILE,
  userProfile
})

export const getLimit = () => ({
  type: GET_LIMIT,
})

export const getOffset = () => ({
  type: GET_OFFSET,
})

export const getCount = () =>( {
  type: GET_COUNT
})

export const setLimit = (limit) => ({
  type: SET_LIMIT,
  limit
})

export const setOffset = (offset) => ({
  type: SET_OFFSET,
  offset
})

export const setCount = (countForFlatAd) => ({
  type: SET_COUNT,
  countForFlatAd
})

export const getSelfAdLimit = () => ({
  type: GET_SELF_AD_LIMIT,
})

export const getSelfAdOffset = () => ({
  type: GET_SELF_AD_OFFSET,
})

export const getSelfAdCount = () => ({
  type: GET_SELF_AD_COUNT
})

export const setSelfAdLimit = (limitForSelfAd) => ({
  type: SET_SELF_AD_LIMIT,
  limitForSelfAd
})

export const setSelfAdOffset = (offsetForSelfAd) => ({
  type: SET_SELF_AD_OFFSET,
  offsetForSelfAd
})

export const setSelfAdCount = (countForSelfAd) => ({
  type: SET_SELF_AD_COUNT,
  countForSelfAd
})

export const showLoader = () => ({
  type: SHOW_LOADER,
})

export const hideLoader = () => ({
  type: HIDE_LOADER
})

export const deleteFlatById = (flatId, flatmate) => ({
  type: DELETE_FLAT_POST,
  flatId,
  flatmate
})

export const deleteFlatmatePostById = (flatmatePostId, adPostedBy) => ({
  type: DELETE_FLATMATE_POST,
  flatmatePostId,
  adPostedBy
})

export const getUserProfile = (username) => ({
  type: GET_USER_PROFILE,
  username
})