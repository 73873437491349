import React, { Component } from "react";
// import { Link } from "react-router-dom";
import axios from "axios";
import { Statistic } from 'antd';
import { connect } from "react-redux";
import { register, updateUserData, updateUserMultipleData, verifyOtp } from "../actions";
import { StyledPrimaryActionButton, StyledLink, StyledTertiaryBtn, StyledDivider } from "./styledComponents/homeStyledComponents";
import '../sass/_register.scss'
import NavLogo from './svgComponents/FlamilyLogo.jsx'
import SocialLogin from "./SocialLogin";
import Loader from "./Loader";

const { Countdown } = Statistic;
class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formValue: {
                first_name: "",
                last_name:"",
                email: "",
                contactNumber: "",
                password: "",
                otp_0:"",
                otp_1:"",
                otp_2:"",
                otp_3:"",
                otp_4:"",
                otp_5:"",
            },
            formErrorMessage: {
                // userName: "",
                first_name:"",
                last_name:"",
                email: "",
                contactNumber: "",
                password: "",
                otp:""
            },
            formValid: {
                // userName: false,
                first_name:false,
                last_name:false,
                email: false,
                contactNumber: false,
                password: false,
                buttonActive: false,
                otp_0: false,
                otp_1: false,
                otp_2: false,
                otp_3: false,
                otp_4: false,
                otp_5: false,
                verifyOtpButtonActive: false
            },
            successMessage: "",
            errorMessage: "",
            visible: false,
            otpScreenActive: false,
            disableOtp: false, 
            resendOtpActive: false,
            passwordShow: false
        }
    }

    componentDidCMount(){
        const user_registration = JSON.parse(sessionStorage.getItem("user_under_registration"))
        const {otp_sent} = user_registration;
        this.setState({otpScreenActive: otp_sent})
    }
    // onHide = () => {
    //     this.setState({ visible: false })
    // }
    
    submitRegisteration = () => {
        this.setState({ successMessage: "OTP Sent, Please check your Mail", errorMessage: "" })
       const user_registration = JSON.parse(sessionStorage.getItem("user_under_registration"))
        const {sendOTP, verfiyOtpAndCreateAccount, otpForm_visible} = this.props;
        if(!otpForm_visible || this.state.resendOtpActive){
            this.setState({disableOtp: false, resendOtpActive: false})
            sendOTP(this.state.formValue);
        }else{
            const {userData: {email}} = user_registration || {}
            const {otp_0, otp_1, otp_2, otp_3, otp_4, otp_5} = this.state.formValue
            const otp = otp_0 + otp_1 + otp_2 + otp_3 + otp_4 + otp_5
            const otpData = {otp, email}
            verfiyOtpAndCreateAccount(otpData)
        }
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.submitRegisteration()
        // const user_registration = JSON.parse(sessionStorage.getItem("user_under_registration"))
    }

    handleOtpSubmit = (event) =>{
        event.preventDefault()
        this.submitRegisteration()
    }

    handleChange = (event) => {
        const fieldName = event.target.name
        const value = event.target.value
        const { formValue } = this.state
        this.validateField(fieldName, value)
        this.setState({ formValue: { ...formValue, [fieldName]: value } })
    }

    validateField = (fieldName, value) => {

        let error = this.state.formErrorMessage
        let valid = this.state.formValid

        switch (fieldName) {
            case "first_name":
                if (value === "") {
                    error.first_name = "Field required"
                    valid.first_name = false
                }
                else if (!(value.match(/^[A-Za-z]+( ([A-Za-z ]+)*[A-Za-z]+)*$/))) {
                    error.first_name = "Invalid Name, it should contain only letters and spaces."
                    valid.first_name = false
                }
                else {
                    error.first_name = ""
                    valid.first_name = true
                }
                break

            case "last_name":
                if (value === "") {
                    error.last_name = "Field required"
                    valid.last_name = false
                }
                else if (!(value.match(/^[A-Za-z]+( ([A-Za-z ]+)*[A-Za-z]+)*$/))) {
                    error.last_name = "Invalid Name, it should contain only letters and spaces."
                    valid.last_name = false
                }
                else {
                    error.last_name = ""
                    valid.last_name = true
                }
                break

            case "email":
                if (value === "") {
                    error.email = "Field required"
                    valid.email = false
                }
                else if (!(value.match(/^[A-Za-z0-9.]+@[A-Za-z0-9]+(\.com)$/))) {
                    error.email = "Invalid Email Id"
                    valid.email = false
                }
                else {
                    error.email = ""
                    valid.email = true
                }
                break
            case "contactNumber":
                if (value === "") {
                    error.contactNumber = "Field required"
                    valid.contactNumber = false
                }
                else if (!(value.match(/^[6-9][0-9]{9}$/))) {
                    error.contactNumber = "Invalid contact number, it should have 10 digits."
                    valid.contactNumber = false
                }
                else {
                    error.contactNumber = ""
                    valid.contactNumber = true
                }
                break
            case "password":
                var flag = false
                if (value.length >= 7 && value.length <= 20)
                    if (value.match(/^.*[A-Z]+.*$/))
                        if (value.match(/^.*[a-z]+.*$/))
                            if (value.match(/^.*[0-9]+.*$/))
                                if (value.match(/^.*[!@#$%^&*].*$/))
                                    flag = true
                if (value === "") {
                    error.password = "Field required"
                    valid.password = false
                }
                else if (!(flag === true)) {
                    error.password = "Invalid Password, it should contain one upper case, one lower case, one number and one character at least."
                    valid.password = false
                }
                else {
                    error.password = ""
                    valid.password = true
                }
                break
            
            case "otp_0":
                if (value === "") {
                    // error.otp = "Field required"
                    valid.otp_0 = false
                }
                else {
                    // error.otp = ""
                    valid.otp_0 = true
                }
                break;
            case "otp_1":
                if (value === "") {
                    // error.otp = "Field required"
                    valid.otp_1 = false
                }
                else {
                    // error.otp = ""
                    valid.otp_1 = true
                }
                break;
            case "otp_2":
                if (value === "") {
                    // error.otp = "Field required"
                    valid.otp_2 = false
                }
                else {
                    // error.otp = ""
                    valid.otp_2 = true
                }
                break

            case "otp_3":
                if (value === "") {
                    // error.otp = "Field required"
                    valid.otp_3 = false
                }
                else {
                    // error.otp = ""
                    valid.otp_3 = true
                }
                break

            case "otp_4":
                if (value === "") {
                    // error.otp = "Field required"
                    valid.otp_4 = false
                }
                else {
                    // error.otp = ""
                    valid.otp_4 = true
                }
                break

            case "otp_5":
                if (value === "") {
                    // error.otp = "Field required"
                    valid.otp_5 = false
                }
                else {
                    // error.otp = ""
                    valid.otp_5 = true
                }
                break
            default:
                break

        }
        valid.buttonActive = valid.email && valid.first_name && valid.last_name && valid.contactNumber && valid.password
        valid.verifyOtpButtonActive = valid.otp_0 && valid.otp_1 && valid.otp_2 && valid.otp_3 && valid.otp_4 && valid.otp_5;
        this.setState({ formErrorMessage: error, formValid: valid, successMessage: "", errorMessage: "" })
    }

    togglePasswordShow = (event) => {
        const {passwordShow} = this.state;
        event.preventDefault()
        this.setState({passwordShow: !passwordShow})
    }

    logIn = (event) => {
        event.preventDefault()
        const {updateSingleData} = this.props
        updateSingleData('loginForm_visible', true)
    }

    render() {
        // const {otpScreenActive} = this.state;
        // const user_registration = JSON.parse(sessionStorage.getItem("user_under_registration"))
        // const {otp_sent} = user_registration || {};
        const {otpForm_visible, isLoading, registerError, errorMessage} = this.props
        if(isLoading){
            return(
                <Loader/>
            )
        }
        if(otpForm_visible){            
            return (
                <>
                <div className="container">
                    <div className="d-flex justify-content-center">
                        <NavLogo v3={10} v4={30}/>
                    </div>
                    <form className="mx-4" onSubmit={this.handleOtpSubmit}>
                        <div className="my-4">
                            <span className="main-label">
                                Verify email
                            </span>
                        </div>
                        <div className="my-2">
                            <label className="otp-verification">
                                An email with a verification code has been sent to your email
                            </label>
                            <div className="otp-field">
                                <input 
                                    type="text" 
                                    name="otp_0" 
                                    id="otp_0" 
                                    className="mx-1" 
                                    placeholder="_"
                                    onChange={this.handleChange}
                                    maxlength="1"
                                    value={this.state.formValue.otp_0} 
                                    disabled={this.state.disableOtp} />
                                <input 
                                    type="text" 
                                    name="otp_1" 
                                    id="otp_1" 
                                    className="" 
                                    placeholder="_"
                                    maxlength="1"
                                    onChange={this.handleChange} 
                                    value={this.state.formValue.otp_1} 
                                    disabled={this.state.disableOtp} />
                                <input 
                                    type="text" 
                                    name="otp_2" 
                                    id="otp_2" 
                                    className="" 
                                    placeholder="_"
                                    maxlength="1"
                                    onChange={this.handleChange} 
                                    value={this.state.formValue.otp_2} 
                                    disabled={this.state.disableOtp} />
                                <input 
                                    type="text" 
                                    name="otp_3" 
                                    id="otp_3" 
                                    className="" 
                                    placeholder="_"
                                    maxlength="1"
                                    onChange={this.handleChange} 
                                    value={this.state.formValue.otp_3} 
                                    disabled={this.state.disableOtp} />
                                <input 
                                    type="text" 
                                    name="otp_4" 
                                    id="otp_4" 
                                    className="" 
                                    placeholder="_"
                                    maxlength="1"
                                    onChange={this.handleChange} 
                                    value={this.state.formValue.otp_4} 
                                    disabled={this.state.disableOtp} />
                                <input 
                                    type="text" 
                                    name="otp_5" 
                                    id="otp_5" 
                                    className="" 
                                    placeholder="_"
                                    maxlength="1"
                                    onChange={this.handleChange} 
                                    value={this.state.formValue.otp_5} 
                                    disabled={this.state.disableOtp} />
                            </div>
                            <span name="otpError" className="text-danger">{this.state.formErrorMessage.otp}</span>
                        </div>
                        {
                            registerError &&
                            <div className="submit-error">
                                {errorMessage}
                            </div>
                        }
                        <center>
                            <StyledPrimaryActionButton primary 
                            className="btn my-4 col" name="register" type="submit"
                            disabled={!(this.state.formValid.verifyOtpButtonActive)}
                            >Verify email
                            </StyledPrimaryActionButton>
                        </center>
                        {!this.state.resendOtpActive && <center>
                            <Countdown 
                                title="OTP expires in" 
                                value={Date.now() + 60 * 5 * 1000} //1000 indicates micro-seconds
                                format="mm:ss"
                                onFinish={()=>this.setState({disableOtp: true, resendOtpActive: true})}/>
                        </center>
                        }
                        {this.state.resendOtpActive &&
                        <center>
                            <div className="row my-4">
                                <div className="col-12">
                                    <StyledTertiaryBtn
                                        type="submit"
                                        className="px-0 clickable"
                                    >
                                        Resend OTP
                                    </StyledTertiaryBtn>
                                </div>
                            </div>
                        </center>
                        }
                    </form>
                </div>
                </>
            )
        } 
        return (
            <React.Fragment>
                <div className="container">
                    <div className="d-flex justify-content-center">
                        <NavLogo v3={10} v4={30}/>
                    </div>
                    <form className="form align mx-4" onSubmit={this.handleSubmit}>
                        <div className="my-4">
                            <span className="main-label">
                               Create your Account
                            </span>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label className="register-label">First Name</label><span className="text-danger">*</span>
                                <input type="text" name="first_name" id="first_name" className="form-control" placeholder="Enter first name"
                                    onChange={this.handleChange} value={this.state.formValue.first_name}></input>
                                <span name="firstNameError" className="text-danger">{this.state.formErrorMessage.first_name}</span>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="register-label">Last Name</label><span className="text-danger">*</span>
                                <input type="text" name="last_name" id="last_name" className="form-control" placeholder="Enter last name"
                                    onChange={this.handleChange} value={this.state.formValue.last_name}></input>
                                <span name="lastNameError" className="text-danger">{this.state.formErrorMessage.last_name}</span>
                            </div>
                        </div>
                        
                        <div className="form-group">
                            <label className="register-label">Email Id</label><span className="text-danger">*</span>
                            <input type="email" name="email" id="email" className="form-control" placeholder="Enter Email Id"
                                onChange={this.handleChange} value={this.state.formValue.email}></input>
                            <span name="emailIdError" className="text-danger">{this.state.formErrorMessage.email}</span>
                        </div>

                        <div className="form-group">
                            <label className="register-label">Contact Number</label><span className="text-danger">*</span>
                            <input type="number" name="contactNumber" id="contactNumber" className="form-control" placeholder="Enter Contact No"
                                onChange={this.handleChange} value={this.state.formValue.contactNumber}></input>
                            <span name="contactNoError" className="text-danger">{this.state.formErrorMessage.contactNumber}</span>
                        </div>

                        <div className="form-group">
                            <label className="register-label">Password</label><span className="text-danger">*</span>
                            <div className="d-flex"> 
                                <input 
                                    type={`${this.state.passwordShow ? 'text': 'password'}`}
                                    name="password" className="form-control" onChange={this.handleChange}
                                    value={this.state.formValue.password} placeholder="Enter Password">
                                </input>
                                <i 
                                    class={`d-flex align-items-center fa ${this.state.passwordShow ? 'fa-eye' : 'fa-eye-slash'}`} 
                                    id="togglePassword" 
                                    style={{"margin-left": "-30px"}}
                                    onClick={this.togglePasswordShow}
                                ></i>
                            </div>
                            <span name="passwordError" className="text-danger">{this.state.formErrorMessage.password}</span>
                        </div>
                        <span><span className="text-danger">*</span>marked fields are mandatory</span><br />
                        <center>
                            <StyledPrimaryActionButton primary 
                            className="btn my-4 col" name="register" type="submit"
                            disabled={!(this.state.formValid.buttonActive)}
                            >Register
                            </StyledPrimaryActionButton>
                        </center>

                        <div className="row my-4 mx-0 px-0">
                            <div className="col-md-5 col-3 px-0">
                                <StyledDivider color="#dee2e6"/>
                            </div>
                            <div className="col-md-2 col-6 d-flex align-items-center justify-content-center">
                                <span className="mb-0 or">or</span>
                            </div>
                            <div className="col-md-5 col-3 px-0">
                                <StyledDivider color="#dee2e6"/>
                            </div>
                	    </div>
                        <div className="row d-flex justify-content-center">
                        {/* Google Login */}
                            <SocialLogin/>
                        </div>
                        <div className="row my-4">
                            <div className="col-12">
                                Already have an account?
                                <StyledTertiaryBtn
                                    type="submit"
                                    className="px-0 clickable"
                                    onClick={this.logIn}
                                >
                                    Log in
                                </StyledTertiaryBtn>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {userAuth, loader}=state
    return {...userAuth, ...loader}
}

const mapDispatchToProps = dispatch => {
    return {
        sendOTP:(userData)=>dispatch(register(userData)),
        verfiyOtpAndCreateAccount:(otpData)=>dispatch(verifyOtp(otpData)),
        updateData: (fieldsToUpdate) => dispatch(updateUserMultipleData(fieldsToUpdate)),
        // createAccount:(userData)=>dispatch(register(userData)),
        updateSingleData: (field,value) => dispatch(updateUserData(field,value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
// export default Register