import styled from 'styled-components';

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
}

export const device = {
    mobileScreen: `(max-width: ${size.tablet})`,
};

export const StyledImage = styled.img`
width: 100%;
height: 400px;
object-fit: contain;
@media ${device.mobileScreen} {
    height: auto;
}
`