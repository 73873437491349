import React, { useState } from "react";
import FacebookLogin  from "react-facebook-login";
import GoogleLogin from 'react-google-login'
import { connect } from "react-redux";
import { socialLogin } from "../actions";

const SocialLogin = ({makeLogin}) => {

    const googleClientId = "59728751384-ln2u9mjhs859ib0cptsqvjvosqvamkjk.apps.googleusercontent.com";
    const faceBookAppId = "825506348607111"
    // formValue: {
        // first_name: "",
        // last_name:"",
        // email: "",
        // contactNumber: "",
        // password: "",
        // otp:""
    // }
    // const [first_name, setFirstName] = useState()
    // const [last_name, setLastName] = useState()
    // const [email, setEmail] = useState()
    const googleLoginOnSuccess = (response) => {
        const {profileObj, tokenId} = response;
        const user = {
            first_name: profileObj.givenName,
            last_name: profileObj.familyName,
            email: profileObj.email,
            tokenId
        }
        const author = 'google'
        makeLogin(user, author)        
    }

    const googleResponse = (response) => {
        const {profileObj} = response;
    }

    // const responseFacebook = (response) => {
    // }

    return (
        <>
        <GoogleLogin 
            clientId={googleClientId}
            className="w-100 justify-content-center mx-3 gmail-login"
            buttonText="Continue with Google"
            onSuccess={googleLoginOnSuccess}
            onFailure={googleResponse}
            cookiePolicy={'single_host_origin'}>
        </GoogleLogin>
        {/* <FacebookLogin 
            appId={faceBookAppId}
            autoLoad={false}
            fields="name,email,picture"
            // onClick={responseFacebook}
            scope="public_profile,user_friends"
            callback={responseFacebook} 
        /> */}
        </>
        
    )
}

const mapDispatchToProps = dispatch => {
    return {
        makeLogin:(user,author)=>dispatch(socialLogin(user,author)),
        // updateData: (field,value) => dispatch(updateUserData(field,value))
    }
}

export default connect(null, mapDispatchToProps)(SocialLogin);