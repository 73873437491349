import React, { Component, useEffect, useState } from "react";
import { Divider, Layout } from 'antd';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Country, State, City }  from 'country-state-city';
import FemaleUserIcon from "./svgComponents/FemaleUserIcon";
import { ProfilePicContainer, ProfilePicImage } from "./styledComponents/profileStyledComponent";
import UserIcon from "./svgComponents/UserIcon";
import { connect, useDispatch } from "react-redux";
import { StyledLink, StyledPrimaryActionButton} from "./styledComponents/homeStyledComponents";
import { UpdateUserProfile } from "./models/UserProfile";
import { deleteFlatById, deleteFlatmatePostById, getUserProfile, updateUserProfile } from "../actions";
import EditIcon from "./svgComponents/EditIcon";
import CloseIcon from "./svgComponents/CloseIcon";
import SaveImageIcon from "./svgComponents/SaveImageIcon";
import ChangeImageIcon from "./svgComponents/ChangeImageIcon";
import { domain } from "../BackendUrl";
import DeleteImageIcon from "./svgComponents/DeleteImageIcon";
import Slider from "react-slick";
import ArrowPrevious from "./svgComponents/ArrowPrevious";
import ArrowNext from "./svgComponents/ArrowNext";
import { loadSelfAdsCard } from "./SelfAdsCard";
import { loadAllAdsCard } from "./AllAdsCards";
import Loader from "./Loader";
import { Modal } from "react-bootstrap";

function Profile({userAuth, updateUser, fromPostAds, deleteFlatPost, loader, deleteFlatmatePost}){
    const {
        logged_fullName,
        logged_user
    } = userAuth

    const {
        email,
        profile
    } = logged_user

    const dispatch = useDispatch()  
    useEffect(() => {
        if (email) {
            dispatch(getUserProfile(email))
        }
    }, [email])

    const {
        age,
        drinks,
        flat, 
        foodType,
        gender,
        id,
        image,
        mobileNumber,
        nativePlace,
        nativeState,
        nativeStateIsoCode,
        worksAt,
        aboutMe,
        havePets,
        smokes,
        adForSelf
    } = profile || {}
    
    const [ageToUpdate, setAgeToUpdate] = useState(age);
    const [drinksToUpdate, setDrinksToUpdate] = useState(drinks);
    const [smokesToUpdate, setSmokesToUpdate] = useState(smokes);
    const [havePetsToUpdate, setHavePetsToUpdate] = useState(havePets);
    const [flatToUpdate, setFlatToUpdate] = useState(flat);
    const [foodTypeToUpdate, setFoodTypeToUpdate] = useState(foodType);
    const [genderToUpdate, setGenderToUpdate] = useState(gender);
    const [imageToUpdate, setImageToUpdate] = useState(image);
    const [numberToUpdate, setNumberToUpdate] = useState(mobileNumber);
    const [nativePlaceToUpdate, setNativePlaceToUpdate] = useState(nativePlace);
    const [nativeStateToUpdate, setNativeStateToUpdate] = useState(nativeState);
    const [worksAtToUpdate, setWorksAtToUpdate] = useState(worksAt);
    const [nativeStateIsoCodeToUpdate, setStateIsoCodeToUpdate] = useState(nativeStateIsoCode);
    const [citiesList, setCitiesList] = useState();
    const [updateBtnActive, setUpdateBtnActive] = useState(false);
    const [imageChanged, setImageChanged] = useState(false);
    const [aboutMeToUpdate, setAboutMeToUpdate] = useState(aboutMe)

    const [showModal, setShowModal] = useState(false)
    const [cardIdToDelete, setCardIdToDelete] = useState()
    const [profileIdToDelete, setProfileIdToDelete] = useState()
    const [adTypeToDelete, setAdTypeToDelete] = useState()
    
    useEffect(()=>{
        setAgeToUpdate(age)
        setDrinksToUpdate(drinks)
        setSmokesToUpdate(smokes)
        setHavePetsToUpdate(havePets)
        setFlatToUpdate(flat)
        setFoodTypeToUpdate(foodType)
        setGenderToUpdate(gender)
        setImageToUpdate(image)
        setNumberToUpdate(mobileNumber)
        setNativePlaceToUpdate(nativePlace)
        setNativeStateToUpdate(nativeState)
        setStateIsoCodeToUpdate(nativeStateIsoCode)
        setWorksAtToUpdate(worksAt)
        setAboutMeToUpdate(aboutMe)
    },[ age,
        drinks,
        smokes,
        havePets,
        foodType,
        nativeState,
        nativeStateIsoCode,
        gender,
        image,
        mobileNumber,
        nativePlace,
        worksAt,
        flat,
        aboutMe ])

    const handleImageChange = (event) => {
        const fieldName = event.target.name;
        if(fieldName === 'profileImage' && event.target.files.length !== 0){
            const file=event.target.files[0]
            setImageToUpdate(file)
            setImageChanged(true)
            // handleSubmit(event)
        } 
    }

    const handleChange = (event) => {
        const fieldName = event.target.name;
        const value = event.target.value;
        switch(fieldName){
            case 'mobile':
                setNumberToUpdate(value)
                break;
            case 'age':
                setAgeToUpdate(value)
                break;
            case 'gender':
                setGenderToUpdate(value)
                break;
            case 'nativeState':
                const valToUpdate = State.getStateByCodeAndCountry(value, 'IN').name
                setNativeStateToUpdate(valToUpdate)
                setStateIsoCodeToUpdate(value)
                break;
            case 'nativePlace':
                setNativePlaceToUpdate(value)
                break;
            case 'occupation':
                setWorksAtToUpdate(value)
                break;
            case 'foodType':
                setFoodTypeToUpdate(value)
                break;
            // case 'relationship':
            //     setRela
            //     break;
            case 'drinks':
                setDrinksToUpdate(value)
                break;
            case 'smokes':
                setSmokesToUpdate(value)
                break
            case 'havePets':
                setHavePetsToUpdate(value)
                break   
            case 'aboutMe':
                setAboutMeToUpdate(value)
                break 
            default:
                break;
        }
        
    }

    const handleDeleteImage = (event) => {
        event.preventDefault()
        setImageToUpdate(image)
        setImageChanged(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const formValue = {
            ageToUpdate,
            drinksToUpdate,
            smokesToUpdate,
            havePetsToUpdate,
            foodTypeToUpdate,
            genderToUpdate,
            id,
            imageToUpdate,
            numberToUpdate,
            nativePlaceToUpdate,
            nativeStateToUpdate,
            nativeStateIsoCodeToUpdate,
            worksAtToUpdate,
            aboutMeToUpdate
        }
        const profileData = UpdateUserProfile(formValue)
        updateUser(profileData)
        setUpdateBtnActive(false)
        setImageChanged(false)
    }
    
    const loadStates = () => {
        const stateList = []
        State.getStatesOfCountry('IN').map(state => {
            stateList.push(<option value={state.isoCode} id={state.isoCode} key={state.isoCode}>{state.name}</option>)
        })
        return stateList;
    }

    useEffect(()=>{
        const citiesList = []
        City.getCitiesOfState('IN', nativeStateIsoCodeToUpdate).map(city => {
            citiesList.push(
                <option value={city.name} id={city.isoCode} key={city.isoCode}>{city.name}</option>
            )
        })
        setCitiesList(citiesList)
    }, [nativeStateIsoCodeToUpdate])
    
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div className={`${className} d-flex flex-column`} onClick={onClick} > 
            <ArrowNext  />
          </div>
        );
      }
      
    function SamplePrevArrow(props) {
        const {className, style, onClick } = props;
        return (
            <div className={`${className} d-flex flex-column`} onClick={onClick} >
               <ArrowPrevious />
            </div>
        );
    }
    const sliderSettings = {
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    const deletePost = (cardId, profileId, adTypeDelete) => {
        setCardIdToDelete(cardId)
        setProfileIdToDelete(profileId)
        setAdTypeToDelete(adTypeDelete)
        setShowModal(true)
    }

    const closeModal = () => {
        setCardIdToDelete()
        setProfileIdToDelete()
        setAdTypeToDelete()
        setShowModal(!showModal)
    }

    const confirmDelete = () => {
        switch (adTypeToDelete) {
            case 'flatAds':
                deleteFlatPost(cardIdToDelete, profileIdToDelete)
                break;
            case 'selfAds':
                deleteFlatmatePost(cardIdToDelete, profileIdToDelete)
                break;
            default:
                closeModal();
        }
    }

    const {isLoading} = loader
    if (isLoading) {
        return (
            <Loader/>
        )
    }
    return(
        <>
        <div className="container">
            <div className="row my-3">
                <div className={`${imageChanged ? 'col-md-2 col-9' : 'col-md-3 col-12'} d-flex justify-content-center`}>
                    <input 
                        type="file" 
                        name="profileImage" 
                        id="profileImage" 
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={handleImageChange}
                        hidden
                        />
                        <label htmlFor="profileImage">
                        { imageToUpdate ? 
                        // <ProfilePicImage src={URL.createObjectURL(imageToUpdate)} alt={imageToUpdate.name} /> :
                        <ProfilePicImage src={imageChanged ? URL.createObjectURL(imageToUpdate) :`${domain}${imageToUpdate}`} alt={imageToUpdate.name} /> :
                        <ProfilePicContainer className="ml-md-3">
                            <UserIcon fill={'black'} height={'7em'} width={'7em'}/>
                       </ProfilePicContainer> }
                    </label>                    
                </div>

                {imageChanged &&
                <div className="col-md-2 col-3 d-flex flex-column justify-content-center align-items-start">
                    <div className="row clickable" onClick={handleSubmit}>
                        <label>
                            <SaveImageIcon fill={'black'} height={'1.5em'} width={'1.5em'}/>
                            <span className="pl-2">Save</span>
                        </label>
                    </div>
                    <div className="row clickable">
                        <label htmlFor="profileImage">
                            <ChangeImageIcon fill={'black'} height={'1.5em'} width={'1.5em'}/>
                            <span className="pl-2">Change</span>
                        </label>
                    </div>
                    <div className="row clickable" onClick={handleDeleteImage}>
                        <label>
                            <DeleteImageIcon fill={'black'} height={'1.5em'} width={'1.5em'}/>
                            <span className="pl-2">Delete</span>
                        </label>
                    </div>
                </div>
                }

                <div className="col-md-7 col-12 mt-2 mt-md-0 d-flex flex-column justify-content-center">
                    <div className="row justify-content-center d-flex justify-content-md-start">
                        <label style={{ fontSize: "2em" }}>{logged_fullName}</label>
                    </div>
                    <div className="row justify-content-center d-flex justify-content-md-start">
                        <label style={{ fontSize: "1.5em" }}>{email}</label>
                    </div>
                </div>
            </div>
            {/* {imageChanged &&
            <div className="row">
                <div className="col-md-1 col-2 clickable" onClick={handleSubmit}>
                    <SaveImageIcon fill={'black'} height={'3em'} width={'3em'}/>
                    <span>Save</span>
                </div>
                <div className="col-md-1 col-2 clickable">
                    <label htmlFor="profileImage">
                        <ChangeImageIcon fill={'black'} height={'3em'} width={'3em'}/>
                        Change
                    </label>
                </div>
                <div className="col-md-1 col-2 clickable" onClick={handleDeleteImage}>
                    <DeleteImageIcon fill={'black'} height={'3em'} width={'3em'}/>
                    <span>Delete</span>
                </div>
            </div>
            }   */}
            <div className="jumbotron">
                {!updateBtnActive && 
                <>
                    <div className="row">
                        <div className="col-1 offset-10 clickable" onClick={()=>{setUpdateBtnActive(true)}}>
                            <EditIcon fill={'black'} height={'3em'} width={'3em'}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-md-5">
                            <h6>Mobile No:</h6>
                            <h6 className="pl-5">{numberToUpdate}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-md-5">
                            <h6>Age:</h6>
                            <h6 className="pl-5">{ageToUpdate}</h6>
                        </div>
                        <div className="col-sm-10 col-md-5">
                            <h6>Gender:</h6>
                            <h6 className="pl-5">{genderToUpdate==="M"?'Male':'Female'}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-md-5">
                            <h6>Native State:</h6>
                            <h6 className="pl-5">{nativeStateToUpdate}</h6>
                        </div>
                        <div className="col-sm-10 col-md-5">
                            <h6>Native Place:</h6>
                            <h6 className="pl-5">{nativePlaceToUpdate}</h6>
                        </div>
                    </div>
                    <Divider orientation="left" orientationMargin="0"><h5>My Features</h5></Divider>
                    <div className="row">
                        <div className="col-sm-10 col-md-5">
                            <h6>Occupation:</h6>
                            <h6 className="pl-5">{worksAtToUpdate}</h6>
                        </div>
                        <div className="col-sm-10 col-md-5">
                            <h6>Food Preference:</h6>
                            <h6 className="pl-5">{foodTypeToUpdate}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-md-5">
                            <h6>Drinks:</h6>
                            <h6 className="pl-5">{drinksToUpdate ? 'Yes' : 'No'}</h6>
                        </div>
                        <div className="col-sm-10 col-md-5">
                            <h6>Smokes:</h6>
                            <h6 className="pl-5">{smokesToUpdate ? 'Yes' : 'No'}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-md-5">
                            <h6>Have Pets:</h6>
                            <h6 className="pl-5">{havePetsToUpdate ? 'Yes' : 'No'}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h6>About me:</h6>
                            <h6 className="pl-5">{aboutMeToUpdate}</h6>
                        </div>
                    </div>
                </>
                }

                {updateBtnActive && 
                <form className="form align" name="rentalDetails" >
                    <div className="row">
                        <div className="col-1 offset-10 clickable" onClick={()=>{setUpdateBtnActive(false)}}>
                            <CloseIcon fill={'black'} height={'3em'} width={'3em'}/>
                        </div>
                    </div>
                    <div className="row">
                    <div className="form-group col-sm-10 col-md-5">
                        <label>Mobile No:</label>
                        <input type="number" name="mobile" id="mobile" className="form-control"
                        // placeholder="Enter Deposite"
                        value={numberToUpdate}
                        onChange={handleChange}
                        // onBlur={this.updateProgress}
                        >
                        </input>
                    </div>
                    </div>

                    <div className="row">
                    <div className="form-group col-sm-10 col-md-5">
                        <label>Age:</label>
                        <input type="number" name="age" id="age" className="form-control"
                        placeholder="Enter your age"
                        value={ageToUpdate}
                        onChange={handleChange}
                        // onBlur={this.updateProgress}
                        >
                        </input>
                    </div>

                    <div className="form-group col-sm-10 col-md-5">
                        <label>Gender:</label>
                        <select className="form-control" name="gender"
                        value={genderToUpdate} 
                        onChange={handleChange}
                        // onBlur={this.updateProgress}
                        >
                        <option value="">--</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Other</option>
                        </select>
                    </div>
                    </div>

                    <div className="row">
                    <div className="form-group col-sm-10 col-md-5">
                        <label>Native State:</label>
                        <select type="text" name="nativeState" id="nativeState" className="form-control"
                        placeholder="Select state"
                        value={nativeStateIsoCodeToUpdate}
                        onChange={handleChange}
                        // onBlur={this.updateProgress}
                        >
                            <option value="">--select state--</option>
                            {loadStates()}
                        </select>
                    </div>
                    <div className="form-group col-sm-10 col-md-5">
                        <label>Native Place:</label>
                        <select type="text" name="nativePlace" id="nativePlace" className="form-control"
                        placeholder="Select city"
                        value={nativePlaceToUpdate}
                        onChange={handleChange}
                        // onBlur={this.updateProgress}
                        >
                        <option value="">--Select City--</option>
                        {citiesList}
                        </select>
                    </div>
                    </div>
                    <Divider orientation="left" orientationMargin="0"><h5>My Features</h5></Divider>

                    <div className="row">
                    <div className="form-group col-sm-10 col-md-5">
                        <label>Occupation:</label>
                        <select className="form-control" name="occupation"
                        value={worksAtToUpdate} 
                        onChange={handleChange}
                        // onBlur={this.updateProgress}
                        >
                        <option value="">--</option>
                        <option value="student">Student</option>
                        <option value="softwareEngineer">Software Engineer</option>
                        <option value="mechanicalEngineer">Mechanical Engineer</option>
                        <option value="electricalEngineer">Electrical Engineer</option>
                        <option value="doctor">Doctor</option>
                        <option value="other">Other</option>
                        </select>
                    </div>

                    <div className="form-group col-sm-10 col-md-5">
                        <label>Food Preference:</label>
                        <select className="form-control" name="foodType"
                        value={foodTypeToUpdate} 
                        onChange={handleChange}
                        // onBlur={this.updateProgress}
                        >
                        <option value="">--</option>
                        <option value="vegetarian">Vegetarian</option>
                        <option value="nonVegetarian">Non-Vegetarian</option>
                        </select>
                    </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-sm-10 col-md-5">
                            <label>Drinks:</label>
                            <select className="form-control" name="drinks"
                            value={drinksToUpdate} 
                            onChange={handleChange}
                            // onBlur={this.updateProgress}
                            >
                            <option value="">--</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                            </select>
                        </div>
                        <div className="form-group col-sm-10 col-md-5">
                            <label>Smokes:</label>
                            <select className="form-control" name="smokes"
                            value={smokesToUpdate} 
                            onChange={handleChange}
                            // onBlur={this.updateProgress}
                            >
                            <option value="">--</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-sm-10 col-md-5">
                            <label>Have Pets:</label>
                            <select className="form-control" name="havePets"
                            value={havePetsToUpdate} 
                            onChange={handleChange}
                            // onBlur={this.updateProgress}
                            >
                            <option value="">--</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-12">
                            <label>About me:</label>
                            <textarea className="form-control" name="aboutMe"
                            value={aboutMeToUpdate} 
                            onChange={handleChange}
                            // onBlur={this.updateProgress}
                            />
                        </div>
                    </div>

                    <div className="row my-5">
                        <div className="form-group col-sm-10 col-md-5">
                            <StyledPrimaryActionButton primary name="register" type="submit"
                            // disabled={!(this.state.formValid.rentalDetailsButtonActive)}
                            onClick = {handleSubmit}
                            >
                                Update Details
                            </StyledPrimaryActionButton>
                        </div>
                    </div>

                </form>
                }
                {
                !fromPostAds && 
                <div>
                    <Divider orientation="left" orientationMargin="0"><h5>My Posts</h5></Divider>
                    {adForSelf &&
                        <Slider {...sliderSettings}>
                            {adForSelf.map((ads, index) => loadSelfAdsCard(ads, index, 'col-sm-12', id, deletePost))}
                        </Slider>
                    }
                    <Divider orientation="left" orientationMargin="0"><h5>My Flats</h5></Divider>
                    {flat &&
                        <Slider {...sliderSettings}>
                            {flat.map((ads, index) => loadAllAdsCard(ads, index, 'col-sm-12', id, deletePost))}
                        </Slider>
                    }
                </div>
                }
            </div>
            <Modal className='registerSection' 
            show={showModal} 
            onHide={() => closeModal()}
            size="lg">
                <Modal.Body>
                    <div className="p-3">
                        <div className="row b h4 mx-0 mt-2">
                            Are you Sure?
                        </div>
                        <div className="mt-3">
                            Do you really want to Delete this post? This process can not be undone.
                        </div>
                        <div className="d-flex align-items-center mt-4 pt-2">
                            <div className="col-4 offset-4">
                                <StyledPrimaryActionButton
                                    primary
                                    type="submit"
                                    className="col btn"
                                    onClick={() => closeModal()}
                                >
                                    Cancel
                                </StyledPrimaryActionButton>
                            </div>
                            <div className="col-4">
                                <StyledLink
                                    type="submit"
                                    className="col btn"
                                    font1em
                                    onClick={() => confirmDelete()}
                                    >
                                        Delete
                                </StyledLink>
                            </div>
                        </div>
                        
                    </div>
                    
                </Modal.Body>
            </Modal>
        </div>
        </>
    )
}

function mapStateToProps(state) {
    const {userAuth, loader}=state
    return {userAuth, loader}
}

const mapDispatchToProps = dispatch => {
    return {
        updateUser:_=>dispatch(updateUserProfile(_)),
        deleteFlatPost: (flatPostId, profileId) => dispatch(deleteFlatById(flatPostId, profileId)),
        deleteFlatmatePost: (flatPostId, profileId) => dispatch(deleteFlatmatePostById(flatPostId, profileId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);