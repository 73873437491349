import EmptyResponseIcon from "./svgComponents/EmptyResponseIcon"
import '../sass/_emptyResult.scss'
import { StyledButton } from "./styledComponents/homeStyledComponents";

const EmptyResultResponse = () => {
    return (
        <div className="container">
            <div className="row emptyResult">
                <div className="col-12">
                    <div className="d-flex justify-content-center">
                        <EmptyResponseIcon height='25em' width='25em'/>
                    </div>
                    <div className="container">
                        <div className="row d-flex justify-content-center mt-4">
                            <span className="title mt-2">
                                Oops!
                            </span>
                        </div>
                        <div className="row d-flex justify-content-center my-2">
                            <div className="subtitle">
                                We are unable to find any post matching your request, but we are sure we have one for you! 
                            </div>
                            <div className="subtitle mt-3">
                                Please change your search parameters & try again
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mt-2">
                            <StyledButton 
                                className="btn my-4 py-2 px-4 emptyBtn" 
                                primary
                                onClick={()=>window.location.reload(false)}>
                                Ok, Let's try Again
                            </StyledButton>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default EmptyResultResponse;