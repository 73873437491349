import {GET_LIMIT, 
    GET_OFFSET, 
    GET_SELF_AD_LIMIT, 
    GET_SELF_AD_OFFSET, 
    SET_LIMIT, 
    SET_OFFSET, 
    SET_SELF_AD_LIMIT, 
    SET_SELF_AD_OFFSET, 
    SET_COUNT, 
    GET_COUNT, 
    SET_SELF_AD_COUNT, 
    GET_SELF_AD_COUNT} from "../actions";

const paginator = {
    limit: 8,
    offset: 0,
    countForFlatAd: 0,
    limitForSelfAd: 8,
    offsetForSelfAd: 0,
    countForSelfAd: 0
  };

export default function paginationReducer(state = paginator, action) {
    var {type}=action
    switch (type) {
        case GET_LIMIT:
            return {...state}

        case SET_LIMIT:
            const {limit}=action
            return {...state, limit}

        case GET_OFFSET:
            return {...state}

        case SET_OFFSET:
            const {offset} =action
            return {...state, offset}

        case GET_COUNT:
            return {...state}

        case SET_COUNT:
            const {countForFlatAd} = action
            return {...state, countForFlatAd}

        case GET_SELF_AD_LIMIT:
            return {...state}

        case SET_SELF_AD_LIMIT:
            const {limitForSelfAd}=action
            return {...state, limitForSelfAd}

        case GET_SELF_AD_OFFSET:
            return {...state}

        case SET_SELF_AD_OFFSET:
            const {offsetForSelfAd} =action
            return {...state, offsetForSelfAd}

        case GET_SELF_AD_COUNT:
            return {...state}

        case SET_SELF_AD_COUNT:
            const {countForSelfAd} = action
            return {...state, countForSelfAd}
        
        default:
            return {...state,}
    }
}