import React, { Component } from "react";
// import "../custom.css";
import { Navigate } from "react-router-dom";
import { Users } from "./models/User";
import NavLogo from './svgComponents/FlamilyLogo.jsx'
import { StyledDivider, StyledPrimaryActionButton, StyledTertiaryBtn } from "./styledComponents/homeStyledComponents";
import { connect } from "react-redux";
import { logIn, updateUserData } from "../actions";
import SocialLogin from "./SocialLogin";
import '../sass/_logIn.scss'
import Loader from "./Loader";
class Login extends Component {
    state = {
        flag: true,
        successMessage: "",
        // register: false,
        errorMessage: null,
        // loginForm: {
        userName: "",
        password: "",
        // },
        // contactNo: "",
        userNameErrorMessage: "",
        // password: "",
        passwordErrorMessage: "",
        validPassWord: false,
        validUserName: false,
        passwordShow: false
    };

    validateFormBeforeLogin = (user) => {
        let name = user.username;
        let password = user.password;
        let errorMessage = "";
        let formValid = true;
        if (name === '') {
            errorMessage = "Email can't be empty";
            formValid = false;
            this.setState({ userNameErrorMessage: errorMessage });
        }
        if (password === '') {
            errorMessage = "Password can't be empty";
            formValid = false;
            this.setState({ passwordErrorMessage: errorMessage });
        }
        return formValid;
    }

    login = event => {
        event.preventDefault();
        var name = document.getElementById("userName").value;
        var password = document.getElementById("password").value;
        var user = new Users();
        user.username = name;
        user.password = password;
        const isFormValid = this.validateFormBeforeLogin(user);
        if (isFormValid) {
            const {makeLogin} = this.props;
            makeLogin(user)
        }
    };

    cancel = () => {
        // var contactNumber = null;
        // var password = null;
    };

    register = () => {
        const { updateData } = this.props; 
        updateData('registerForm_visible',true)
    };


    //Check whether userName and Password are getting populated or not


    validate = event => {
        var fieldName = event.target.name;
        var value = event.target.value;
        var errorMessage = "";
        switch (fieldName) {
            case "userName":
                if(value===''){
                    errorMessage = "Email can't be empty";
                    this.setState({ userNameErrorMessage: errorMessage });
                }
                else{
                    this.setState({
                        userNameErrorMessage: "",
                        validUserName: true
                    });
                }
                this.setState({
                    userName: value
                });
                break;
            case "password":
                if (value==='') {
                    errorMessage = "Password can't be empty";
                    this.setState({ passwordErrorMessage: errorMessage });
                }
                else{
                    this.setState({
                        passwordErrorMessage: "",
                        validPassWord: true
                    });
                }
                this.setState({
                    password: value
                });
                break;
            default:
                    break
        }
    };

    togglePasswordShow = (event) => {
        const {passwordShow} = this.state;
        event.preventDefault()
        this.setState({passwordShow: !passwordShow})
    }

    render() {
        const {loader, userAuth} = this.props;
        const {loginError, errorMessage} = userAuth;
        const {isLoading} = loader
        if (isLoading) {
            return (
                <Loader/>
            )
        }
        if (this.state.successMessage !== "") {
            return <Navigate to={"/"} />;
        }

        return (
            <>
                <div className="">
                    <div className="d-flex justify-content-center">
                        <NavLogo v3={10} v4={30}/>
                    </div>
                </div>
                <form className="form align loginSection mx-4">
                    <div className="my-4">
                        <span className="log-in pb-1">
                            Log in
                        </span>
                    </div>
                    <div className="form-group">
                        <label className="align login-label">Email</label>
                        <span className="text-danger">*</span>
                        <input type="email" 
                            name="userName" 
                            id="userName" 
                            className="form-control" 
                            placeholder="Enter Your Email" 
                            required
                            onChange={this.validate} 
                            value={this.state.userName}
                        >
                        </input>
                        <span name="userNameError" className="text-danger">{this.state.userNameErrorMessage}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password" className="align login-label">
                            Password
                        </label>
                        <span className="text-danger">*</span>
                        <div className="d-flex">
                            <input
                                id="password"
                                // value={this.state.loginForm.password}
                                required
                                type={`${this.state.passwordShow ? 'text': 'password'}`}
                                name="password"
                                className="form-control"
                                onChange={this.validate}
                                value={this.state.password}
                                placeholder="Enter password"
                            />
                            <i 
                                class={`d-flex align-items-center fa ${this.state.passwordShow ? 'fa-eye' : 'fa-eye-slash'}`} 
                                id="togglePassword" 
                                style={{"margin-left": "-30px"}}
                                onClick={this.togglePasswordShow}
                            ></i>
                        </div>
                        <span name="passwordError" className="text-danger">{this.state.passwordErrorMessage}</span>
                    </div>
                    {
                        loginError &&
                        <div className="submit-error">
                            {errorMessage}
                        </div>
                    }
                    <div className="form-group mt-4">
                        <div className="row">
                            <div className="col">
                                <StyledPrimaryActionButton
                                    primary
                                    type="submit"
                                    className="col btn"
                                    onClick={this.login}
                                >
                                    Login
                                </StyledPrimaryActionButton>
                            </div>
                        </div>
                        <div className="row my-4 mx-0 px-0">
                            <div className="col-md-5 col-3 px-0">
                                <StyledDivider color="#dee2e6"/>
                            </div>
                            <div className="col-md-2 col-6 d-flex align-items-center justify-content-center">
                                <span className="mb-0 or">or</span>
                            </div>
                            <div className="col-md-5 col-3 px-0">
                                <StyledDivider color="#dee2e6"/>
                            </div>
                	    </div>
                        <div className="row d-flex justify-content-center">
                        {/* Google Login */}
                            <SocialLogin/>
                        </div>
                        <div className="row my-4">
                            <div className="col-12">
                                Not a member yet?
                                <StyledTertiaryBtn
                                    type="submit"
                                    className="px-0 clickable"
                                    onClick={this.register}
                                >
                                    Create an account
                                </StyledTertiaryBtn>
                            </div>
                        </div>
                        <div>
                            <span className="text-danger" style={{ "backgroundColor": "White" }}>{this.state.errorMessage}</span>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}

function mapStateToProps(state) {
    const {loader, userAuth}=state
    return {loader, userAuth}
}

const mapDispatchToProps = dispatch => {
    return {
        makeLogin:(user)=>dispatch(logIn(user)),
        updateData: (field,value) => dispatch(updateUserData(field,value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
